import {Fragment, useEffect, useState} from 'react'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../../web-app-core'
import {useParams} from 'react-router-dom'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {
  createClient,
  createMicrophoneAndCameraTracks,
  createScreenVideoTrack,
  ClientConfig,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  ConnectionState,
} from 'agora-rtc-react'
import MediaPlayer from './MediaPlayer'
import {CreateAppModal} from '../../../../_metronic/partials'
import clsx from 'clsx'
// import {useLayout} from '../../../../_metronic/layout/user/core'
import {useLayout} from '../../../../_metronic/layout/core'
import ScreenShareMediaPlayer from './RemoteScreenShareMediaPlayer'
import RemoteScreenShareMediaPlayer from './RemoteScreenShareMediaPlayer'
import LocalScreenShareMediaPlayer from './LocalScreenShareMediaPlayer'
import axios from 'axios'
import {getToken2, startRecording, stopRecording} from '../../../../get-token'
import Swal from 'sweetalert2'
import NewWindow from 'react-new-window'
import { KTIcon } from '../../../../_metronic/helpers'
import { send } from 'process'

// define config for rtc engine
const config: ClientConfig = {
  mode: 'rtc',
  codec: 'vp8',
}

const API_URL = import.meta.env.VITE_APP_API_URL

export default function SocketComponent({
  sessionId,
  userName,
  joinRequestResponse,
  updateUserList,
  removeFromSession,
}: any) {
  const navigate = useNavigate()
  const {id} = useParams()
  const {currentUser, auth} = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()
  // const {currentUser, auth} = useAuth()

  // const [searchParams, setSearchParams] = useSearchParams()

  // const callVideoCallId = searchParams.get('videoCallId') ?? auth?.user?.videoCallId
  // const callUserId = searchParams.get('userId') ?? auth?.user?.id
  // const callChannelId = searchParams.get('channelId') ?? id

  // const [inCall, setInCall] = useState(false)
  // const [inlineCall, setInlineCall] = useState(false)
  const [channelId, setChannelId] = useState(id as string)

  // const {setInCall, channelId, setInlineCall} = props
  const [users, setUsers] = useState<IAgoraRTCRemoteUser[]>([])
  const [start, setStart] = useState<boolean>(false)
  const [approveRequestLoading, setApproveRequestLoading] = useState<any>({})
  const [removeUserLoading, setRemoveUserLoading] = useState<any>({})

  // const {ready: screenShareReady, tracks: screenShareTracks} = useScreenVideoTrack()

  const [loading, setLoading] = useState<boolean>(false)
  const [isGettingSocketConnections, setIsGettingSocketConnections] = useState<boolean>(false)
  const [ws, setWs] = useState<WebSocket | undefined>()
  const [screenShareVideoCallId, setScreenShareVideoCallId] = useState<string | undefined>()
  const [socketConnections, setSocketConnections] = useState<Array<any>>()

  const [connectionTime, setConnectionTime] = useState('')
  const [activeVideoCallId, setActiveVideoCallId] = useState()
  const [activeCallUserId, setActiveCallUserId] = useState()
  const [forceMuteAudio, setForceMuteAudio] = useState<string | undefined>()

  const getRandomCallerId = () => {
    return Math.floor(Math.random() * 1000000000).toString()
  }

  // console.log('auth?.user', auth?.user)
  let callVideoCallId = searchParams.get('videoCallId') ?? auth?.user?.videoCallId
  let callUserId = searchParams.get('userId') ?? auth?.user?.id ?? getRandomCallerId()
  const callChannelId = searchParams.get('channelId') ?? channelId
  const callName = searchParams.get('name') ?? userName
  const callTenantId = searchParams.get('tenantId') ?? auth?.user?.name
  const callInvite = searchParams.get('invite') ?? '0'

  useEffect(() => {
    if (forceMuteAudio) {
      setForceMuteAudio(undefined)
    }
  }, [forceMuteAudio])
  useEffect(() => {
    if (!ws) {
      console.log('ws not set')
    }

    return () => {
      sendWsMessage({
        action: 'user-disconnected',
        data: {
          userId: callUserId,
          channelId: callChannelId,
        },
      })
      
      // setWs((ws) => {
      //   if (ws) {
      //     ws.close()
      //   }
      //   return ws
      // })
    }
  }, [])

  // console.log('callChannelId cid', callChannelId)

  // const GET_CONNECTIONS_URL = `${API_URL}/channel/${id}/socket-connections`
  const GET_CONNECTIONS_URL = `${API_URL}/channel/${callChannelId}/socket-connection`

  const recordingUid = searchParams.get('recordingUid')

  const getSocketConnections = async () => {
    try {
      setIsGettingSocketConnections(true)
      // delay for 1 second
      await new Promise((resolve) => setTimeout(resolve, 1000))

      const response = await axios.get(GET_CONNECTIONS_URL)
      console.log('getSocketConnections response', response.data.data)
      setSocketConnections(response.data.data)
      return response.data.data
    } catch (error) {
      console.log('getSocketConnections error', error)
      setIsGettingSocketConnections(false)
    }
  }

  useEffect(() => {
    getSocketConnections()
  }, [users])

  useEffect(() => {
    if (!socketConnections?.find((a) => a.userId === auth?.user?.id)) {
      connectToWS()
    }
  }, [socketConnections])

  useEffect(() => {
    updateUserList(socketConnections)
  }, [socketConnections])

  useEffect(() => {
    if (connectionTime) {
      console.log('ws reconnection')

      if (start) {
        console.log('useEffect connectionTime', connectionTime)
        setTimeout(function () {
          connectToWS()
        }, 1000)
      }
    }
  }, [connectionTime])

  // send a message to websocket server and wait for response containing an acknowlegement of the message action
  const sendWsMessage = (message: any) => {
    setWs((ws) => {
      console.log('sendWsMessage message', message, ws == null)
      if (ws == null) {
        return
      }

      if (!message?.data?.channelId) {
        message.data.channelId = callChannelId
      }

      ws.onmessage = (event) => {
        const messageObject = JSON.parse(event.data)
        if (messageObject.action === message.action) {
          console.log('sendWsMessage ws onmessage:' + messageObject.action, event)
          console.log('sendWsMessage clearInterval', messageObject)
          clearInterval(sendFunctionInterval)
          setWsEventHandlers(ws)
        }
      }
      const sendFunctionInterval = setInterval(() => {
        console.log('sendWsMessage sendFunctionInterval', message)

        try {
          ws?.send(JSON.stringify(message))
        } catch (error) {
          console.log('sendWsMessage sendFunctionInterval error', error)
          clearInterval(sendFunctionInterval)
        }
      }, 1000)

      return ws
    })
  }

  const processWsMessage = async (messageObject: any) => {
    console.log('processWsMessage data', messageObject)

    switch (messageObject.action) {
      case 'mute-audio':
        console.log('processWsMessage mute-audio', messageObject)
        console.log('processWsMessage mute-audio', callUserId)

        if (messageObject.data.userId === callUserId) {
          console.log('processWsMessage mute-audio mute', callUserId)
          setForceMuteAudio(new Date().getTime().toString())
        }
        break
      case 'mute-audio-all':
        console.log('processWsMessage mute-audio-all', messageObject)
        break
      case 'remove-user':
        console.log('processWsMessage remove-user', messageObject)
        if (messageObject.data.userId === callUserId) {
          console.log('processWsMessage remove-user', messageObject)
          removeFromSession()
        } else {
          setRemoveUserLoading((prev: any) => {
            return {
              ...prev,
              [messageObject.data.userId]: false,
            }
          })
        }
        break
      case 'approve-join-request':
        console.log('processWsMessage remove-user', messageObject)
        if (messageObject.data.userId === callUserId) {
          setSocketConnections((prevSocketConnections: any) => {
            console.log('processWsMessage remove-user', messageObject)
            console.log(
              'processWsMessage callUserId, prevSocketConnections',
              callUserId,
              prevSocketConnections
            )
            const _socketConnection = prevSocketConnections?.find(
              (a: any) => a.userId === callUserId
            )
            joinRequestResponse(messageObject)

            axios
              .patch(`${API_URL}/socket-connection/${_socketConnection.id}`, {
                mode: 'guest',
              })
              .then((apiResponse) => {
                console.log('apiResponse', apiResponse)
              })
              .catch((error) => {
                console.log('apiResponse error', error)
              })
              .finally(() => {
                sendWsMessage({
                  action: 'user-connected',
                  data: {
                    userId: callUserId,
                    channelId: callChannelId,
                  },
                })
                getSocketConnections()
              })
            return prevSocketConnections
          })
        } else {
          setApproveRequestLoading((prev: any) => {
            return {
              ...prev,
              [messageObject.data.userId]: false,
            }
          })
        }
        break
      case 'user-connected':
        console.log('processWsMessage user-connected', messageObject)
        if (messageObject.data.userId !== callUserId) {
          getSocketConnections()
          setApproveRequestLoading((prev: any) => {
            return {
              ...prev,
              [messageObject.data.userId]: false,
            }
          })
        }
        break
      case 'user-disconnected':
        console.log('processWsMessage user-connected', messageObject)
        if (messageObject.data.userId !== callUserId) {
          getSocketConnections()
          setRemoveUserLoading((prev: any) => {
            return {
              ...prev,
              [messageObject.data.userId]: false,
            }
          })
        }
        break

      default:
        break
    }
  }
  const setWsEventHandlers = (ws: WebSocket) => {
    ws.onopen = () => {
      // Connection opened
      console.log('WebSocket connection opened')

      console.log('WebSocket connection opened after send')
      // send user connected message
      sendWsMessage({
        action: 'user-connected',
        data: {
          userId: callUserId,
          channelId: callChannelId,
        },
      })
      //ws.send('Hello, server!'); // Send a message to the server
    }

    ws.onmessage = (e) => {
      // Receive a message from the server
      console.log('ws message data', e.data)
      console.log('ws message data parse', JSON.parse(e.data))
      const messageObject = JSON.parse(e.data)
      if (
        messageObject.action === 'screen-share-started' ||
        messageObject.action === 'screen-share-stopped' ||
        messageObject.action === 'mute-audio' ||
        messageObject.action === 'mute-audio-all' ||
        messageObject.action === 'remove-user' ||
        messageObject.action === 'user-connected' ||
        messageObject.action === 'user-disconnected' ||
        messageObject.action === 'approve-join-request'
      ) {
        processWsMessage(JSON.parse(e.data))
          .then(() => {
            console.log('processWsMessage done')
          })
          .catch((err) => {
            console.log('processWsMessage err', err)
          })
      }
    }
    ws.onerror = (e) => {
      // An error occurred
      console.log('ws error', e)
      ws.close()
    }
    ws.onclose = (e) => {
      // Connection closed
      console.log('ws disconnected', e)
      console.log(e.code, e.reason)

      setConnectionTime(new Date().toString())
      // if(start){

      //   // setTimeout(function () {
      //   //   connectToWS()
      //   // }, 1000)
      // }
    }
  }

  const connectToWS = () => {
    console.log(
      'WebSocket connection',
      ws?.CONNECTING,
      ws && (ws.readyState === ws.OPEN || ws.readyState === ws.CONNECTING),
      ws
    )
    if (ws && (ws.readyState === ws.OPEN || ws.readyState === ws.CONNECTING)) {
      return
    }
    console.log('WebSocket connection about to open')

    try {
      const joinMode = auth?.user?.id ? 'user' : 'join-request'
      const webSocket = new WebSocket(
        `${
          import.meta.env.VITE_APP_WEBSOCKET_API_URL
        }/v1?userId=${callUserId}&channelId=${callChannelId}&videoCallId=activeVideoCallId&name=${callName}&mode=${joinMode}`
      )
      console.log('WebSocket connection about to open 22')
      setWsEventHandlers(webSocket)
      setWs(webSocket)
    } catch (error) {
      console.log('WebSocket connection error', error)
    }
  }

  useEffect(() => {
    if (activeVideoCallId) {
      console.log('useEffect activeVideoCallId', activeVideoCallId)
      connectToWS()
    }
  }, [activeVideoCallId])

  const leaveChannel = async () => {}

  useEffect(() => {
    if (callInvite !== '0' && !searchParams.get('name')) {
      Swal.fire({
        title: 'Please enter your name to join the call.',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: false,
        confirmButtonText: 'Continue',
        showLoaderOnConfirm: true,
        preConfirm: (name) => {
          return name
        },
        allowOutsideClick: false,
      }).then((result) => {
        console.log('result', result)
        if (result.isConfirmed) {
          window.location.replace(`${window.location.href}&name=${result.value}`)
        }
      })
      console.log('tta 1')
      return
    }
  }, [])

  return (
    <div className='d-flex flex-column gap-4 '>
      {socketConnections?.filter((a: any) => a.mode === 'join-request').length ? (
        <Fragment>
          <h3>Waiting Room</h3>
          <div className='d-flex flex-column gap-2'>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center gap-3 w-100 flex-column'>
                {socketConnections
                  ?.filter((a: any) => a.mode === 'join-request')
                  .map((user: any) => (
                    <div className='border-1 border-bottom-dashed border-gray-400 d-flex flex-row justify-content-between justify-items-between pb-2 w-100'>
                      <div className='fs-6 fw-bold text-gray-800'>{user?.name}</div>
                      <div className='fs-7 text-gray-600'>
                        <button
                          disabled={approveRequestLoading[user.userId]}
                          onClick={() => {
                            setApproveRequestLoading((prev: any) => {
                              return {
                                ...prev,
                                [user.userId]: true,
                              }
                            })

                            sendWsMessage({
                              action: 'approve-join-request',
                              data: {
                                userId: user.userId,
                                channelId: callChannelId,
                              },
                            })

                           
                          }}
                          className='btn btn-success btn-sm py-1'
                        >
                          {approveRequestLoading[user.userId] ? (
                            <span
                              className='spinner-border spinner-border-sm'
                              role='status'
                              aria-hidden='true'
                            ></span>
                          ) : (
                            ''
                          )}{' '}
                          Allow
                          {/* {
                        approveRequestLoading[user.userId] ? 'Loading...' : 'Allow'
                      } */}
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </Fragment>
      ) : null}

      <h3>All Users</h3>
      <div className='d-flex flex-column gap-2'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center gap-3 w-100 flex-column'>
            {socketConnections
              ?.filter((a: any) => ['user', 'guest'].includes(a.mode))
              .map((user: any) => (
                <div className='border-1 border-bottom-dashed border-gray-400 d-flex flex-row justify-content-between justify-items-between pb-2 w-100'>
                  <div className='fs-6 fw-bold text-gray-800'>{user?.name}</div>
                  <div className='fs-7 text-gray-600'>
                    <button
                    onClick={() => {
                      setRemoveUserLoading((prev: any) => {
                        return {
                          ...prev,
                          [user.userId]: true,
                        }
                      })
                      sendWsMessage({
                        action: 'remove-user',
                        data: {
                          userId: user.userId,
                          channelId: callChannelId,
                        },
                      })
                    }}
                    className='btn btn-light-danger btn-sm py-1 mt-n1 px-1'>
                      {/* display spinner when loading */}
                      {
                        removeUserLoading[user.userId] ? (
                          <span
                            className='spinner-border spinner-border-sm'
                            role='status'
                            aria-hidden='true'
                          ></span>
                        ) : <KTIcon iconName='cross' iconType='solid' className='p-0 fs-3' />
                      }
                      
                      </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
