/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from "react";
import { ErrorMessage, Field } from "formik";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3"
import Dropzone from "react-dropzone";
import { v4 as uuidv4 } from "uuid";

const client = new S3Client({
  region: 'eu-west-2', // 'eu-west-2
  credentials: {
    accessKeyId: `${import.meta.env.VITE_APP_AWS_ACCESS_KEY}`,
    secretAccessKey: `${import.meta.env.VITE_APP_AWS_SECRET_KEY}`,
  },

});

interface InputComponentProps {
  field: any;
  data: any;
  updateData: (fieldsToUpdate: Partial<any>) => void;
  hasError: boolean;
  key: string;
  errors: any;
  touched: any;
  setError: Function;
}

const FileUploadInputWithLabel = ({
  field,
  data,
  updateData,
  hasError,
  errors,
  touched,
  setError,
}: InputComponentProps) => {
  // console.log('InputTextWithLabel data', data)
  const [inputValue, setInputValue] = useState(data && data[field.name]);
  const [loading, setLoading] = useState(false);
  const onChange = (e: any) => {
    let objectValue = {} as any;
    objectValue[field.name as string] = e.target.value;
    setInputValue(e.target.value);
    updateData(objectValue);
  };

  const onDrop = useCallback((acceptedFiles: any, form: any) => {
    // setError("");
    acceptedFiles.forEach((file: File) => {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > field.limit ?? 5) {
        return setError("Image Size Exceeds 5MB");
      }
      console.log("FileUploadInputWithLabel file", file);
      // get the extension of the file from the file type
      const extension = file.type.split("/")[1];
      const fileNameToUpload = `${uuidv4()}.${extension}`;
      uploadFileToS3Bucket(file, fileNameToUpload, form);

      //   setYourImage(file);
      //   setImage(file);
      // const reader = new FileReader();
      // reader.addEventListener("load", () =>
      //   setImgSrc(reader.result?.toString() || "")
      // );
      // reader.readAsDataURL(file);
      // if (field.type === "Image") {
      //   setShowImageCropperModal(true);
      // } else {
      //   setLoading(true);

      //   uploadFileToS3Bucket(file, uuidv4());

      // }
    });
  }, []);

  const uploadFileToS3Bucket = async (file: any, fileName: string, form: any) => {
    setLoading(true);
    console.log("upload file", file);
    //s3 bucket config
    const s3Config = {
      bucketName: field.bucketName, // "advert-bucket",
      // dirName: 'photos' /* optional */,
      region: field.region, // "eu-west-2",
      accessKeyId: `${import.meta.env.REACT_APP_AWS_ACCESS_KEY}`,
      secretAccessKey: `${import.meta.env.REACT_APP_AWS_SECRET_KEY}`,
    };
    
    try{
      const command = new PutObjectCommand({
        Bucket: field.bucketName,
        Key: fileName,
        Body: file,
      });
      setLoading(true);
      const response = await client.send(command);
      console.log(response);
      const fileUrl = `https://${field.bucketName}.s3-eu-west-2.amazonaws.com/${fileName}`
      console.log(fileUrl);
      setInputValue(fileUrl);
  
      let objectValue = {} as any;
      objectValue[field.name as string] = fileUrl;
      if (field.type === "file-upload") {
        objectValue["fileName"] = fileName;
        objectValue["fileUrl"] = fileUrl;
        objectValue["fileType"] = fileName.split(".").pop();
        objectValue["fileObject"] = {
          name: fileName,
          url: fileUrl,
          type: fileName.split(".").pop(),
        };
  
        form.setFieldValue(field.name, fileUrl);
        form.setFieldValue("fileName", fileName);
        form.setFieldValue("fileUrl", fileUrl);
        form.setFieldValue("fileType", fileName.split(".").pop());
        form.setFieldValue("fileObject" as string, {
          name: file.name,
          url: data.location,
          type: file.type,
        });
      } else {
        form.setFieldValue(field.name, fileUrl);
      }
      form.setFieldValue(field.name as string, fileUrl);
      updateData(objectValue);
      // 1 second delay to show the loading
      await new Promise((r) => setTimeout(r, 1000));
      // form.handleSubmit(form.values)
      
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false);
    }
    
    
  };

  console.log("touched field name", touched[field.name]);

  console.log("inputValue", inputValue);

  // console.log('errors', errors)
  return (
    <div
      className={`col-${field.cols ? field.cols : "12"} mb-7`}
      key={field.name}
    >
      <label
        htmlFor={`input-${field.name}-id`}
        className={`d-flex align-items-center fs-6 fw-bold mb-0 ms-1 ${
          field.validation?.required ? "required" : ""
        }`}
      >
        <span>{field.label}</span>
        {field.toolTip ? (
          <>
            <ReactTooltip
              id={`${field.name}-input-tooltip`}
              place="top"
            >
              {field.toolTip}
            </ReactTooltip>

            <span
              data-tip
              data-for={`${field.name}-input-tooltip`}
              data-padding="5px 7px"
              // title={a.tooltip}
            >
              <i
                className="fas fa-exclamation-circle ms-2 fs-7"
                data-bs-toggle="tooltip"
                title="Specify your unique app name"
              ></i>
            </span>
          </>
        ) : null}
      </label>

      <Field name={field.name}>
        {({ field, form, meta }: any) => (
          <div>
            <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles, form)}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  {inputValue ? (
                    <>
                      <div className="mb-2">
                        <img
                          alt={form.values["fileName"]}
                          className="h-125px rounded border border-dashed "
                          src={form.values["fileUrl"] || inputValue}
                        />
                      </div>

                      <button
                        type="button"
                        className="btn btn-outline btn-outline-primary"
                        {...getRootProps()}
                      >
                        <i className="fa fa-upload"></i>Select File{" "}
                      </button>
                    </>
                  ) : (
                    <div
                      className="notice d-flex bg-light-primary rounded border-primary border border-dashed cursor-pointer py-10 d-flex align-content-center justify-content-center"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div>
                        <p>
                          Drag and drop or{" "}
                          <span className="text-info">Browse</span>
                        </p>
                        <p>
                          Max image size is{" "}
                          <span className="text-info">5MB</span>
                        </p>
                      </div>
                    </div>
                  )}
                </section>
              )}
            </Dropzone>

            {/* 

            <input
              id={`input-${field.name}-id`}
              type='text'
              className='form-control form-control-lg fw-semibold'
              {...field}
              placeholder={field.placeholder}
            /> */}
            {/* <ErrorMessage name={field.name} /> */}

            {touched[field.name] && errors[field.name] && (
              <div className="error error fs-7 fw-semibold ps-1 text-danger">
                {errors[field.name]}
              </div>
            )}
          </div>
        )}
      </Field>
      {/* <input
        type="text"
        className="form-control form-control-lg fw-semibold"
        name="appname"
        placeholder=""
        value={data && data[field.name]}
        onChange={(e) =>
          onChange(e)
        }
      /> */}
      {!inputValue && hasError && (
        <div className="fv-plugins-message-container">
          <div
            data-field="appname"
            data-validator="notEmpty"
            className="fv-help-block"
          >
            {field.label} is required
          </div>
        </div>
      )}
    </div>
  );
};

export { FileUploadInputWithLabel };
