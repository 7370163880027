import {Link} from 'react-router-dom'
import moment from 'moment'
import {GetStatusCssClassColor} from './GetStatusCssClassColor'

const getElementToReturn = (tableObject: any, item: any, fieldValue: any) => {
  const type = tableObject.type
  let elementToReturn
  // Return "-" when there is no element

  if (!fieldValue) {
    return <span className='fw-bold'>{fieldValue === 0 ? fieldValue : '-'}</span>
  }

  switch (type) {
    case 'status':
      elementToReturn = (
        <span className={`badge badge-light-${GetStatusCssClassColor(fieldValue)}`}>
          {fieldValue}
        </span>
      )
      break

    case 'tag':
      if (typeof fieldValue === 'object') {
        elementToReturn = (
          <>
            {fieldValue?.map((field: any) => (
              <span className='badge badge-light-primary me-2'>{field}</span>
            ))}
          </>
        )
      } else {
        elementToReturn = <span className='badge badge-light-primary me-2'>{fieldValue}</span>
      }
      break

    case 'date':
      elementToReturn = <span>{moment(fieldValue).format('MMM D, YYYY')}</span>
      break

    case 'datetime':
      elementToReturn = <span>{moment(fieldValue).format('MMM D, YYYY h:mm a')}</span>
      break

    case 'link':
      elementToReturn = (
        <Link to={`${tableObject.path}/${item.id}`} className='fw-bold text-hover-primary mb-1'>
          {fieldValue}
        </Link>
      )
      break

    case 'currency':
      elementToReturn = (
        <span>
          {tableObject?.symbol}{' '}
          {typeof fieldValue === 'number' ? fieldValue.toFixed(2) : fieldValue}
        </span>
      )
      break

    default:
      elementToReturn = <span> {typeof fieldValue !== 'object' ? fieldValue : '-'}</span>
      break
  }
  return elementToReturn
}

export const GetFormattedFieldItem = (item: any, tableObject: any) => {
  let fieldValue

  if (tableObject.name.includes('[]')) {
    const nestedObjects = tableObject.name.split('[].')

    fieldValue = item[nestedObjects[0]]

    return (
      <>
        {fieldValue?.map((item: any) => {
          return getElementToReturn(tableObject, item, item[nestedObjects[1]])
        })}
      </>
    )
  } else if (tableObject.name.includes('.')) {
    const nestedObjects = tableObject.name.split('.')

    if (nestedObjects.length === 2) {
      fieldValue = item[nestedObjects[0]]
      const value = fieldValue ? fieldValue[nestedObjects[1]] : ''
      return getElementToReturn(tableObject, item, value)
    }
  } else {
    fieldValue = item[tableObject.name]
    return getElementToReturn(tableObject, item, fieldValue)
  }

  return getElementToReturn(tableObject, item, fieldValue)
}
