import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'
import {DetailHeader} from '../../../web-app-core'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {CalendarEventDetails} from './tabs/CalendarEventDetails'

const API_URL = import.meta.env.VITE_APP_API_URL

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CalendarEventsPage = () => {
  const {id} = useParams()
  const formObject = {
    endpoint: `${API_URL}/calendar-event/${id}`,
    dataFields: [],
    tabs: [
      {
        title: 'Overview',
        path: `/calendar/${id}`,
      },
    ],
  }
  return (
    <Routes>
      <Route
        element={
          <>
            <DetailHeader formObject={formObject} id={id as string} />
            <Outlet />
          </>
        }
      >
        {/* <Route element={<Navigate to=':id' />} /> */}
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Overview</PageTitle>
              <CalendarEventDetails />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default CalendarEventsPage
