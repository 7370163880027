import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'
import {DetailHeader, useAuth} from '../../../web-app-core'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Trainings from './Evaluations'
import EvaluationQuestions from './EvaluationQuestions'
import AppFormWizard from '../../../web-app-core/components/organisms/AppFormWizard'
import EvaluationResponse from './EvaluationResponse'
import EvaluationUsers from './EvaluationUsers'
import EvaluationRespondents from './EvaluationRespondents'
import RespondentUserEvaluationUsers from './RespondentUserEvaluationUsers'
import EvaluationUserRespondentUsers from './EvaluationUserRespondentUsers'
import {components} from 'react-select'
import DynamicScreen from '../../../web-app-core/components/DynamicScreen'

const API_URL = import.meta.env.VITE_APP_API_URL

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const EvaluationsPage = () => {
  const {id} = useParams()
  const {currentUser} = useAuth()
  const companyId = currentUser?.companyId

  const screenData = {
    screen: {
      className: '',
      title: 'Evaluation Configuration',
      iconName: 'clipboard',
      detailsEndpoint: `${API_URL}/evaluation/${id}`,
      components: [
        {
          type: 'view',
          className: '',
          style: {
            flexDirection: 'row',
          },
          components: [
            {
              type: 'ItemDetailVertical',
              className: 'me-2 rounded-right-0',
              data: {
                level: 3,
                path: `/`,
                endpoint: `${API_URL}/evaluation/${id}`,
                dataFields: [
                  {name: 'title', label: 'Title'},
                  {name: 'description', label: 'Description'},
                  {name: 'dateCreated', label: 'Date Created'},
                ],
                tabs: [],
              },
            },
            {
              type: 'Tabs',
              items: [
                {
                  title: 'Questions',
                  components: [
                    {
                      type: 'view',

                      components: [
                        {
                          type: 'ItemManagementTable',
                          className: 'w-100 card px-5',
                          data: {
                            listEndpoint: `${API_URL}/evaluation/${id}/question`,
                            listTitle: 'Checklist Items',
                            listFields: [
                              {
                                name: 'title',
                                header: 'Title',
                                type: 'link',
                                path: '/action-plan-checklist-item',
                              },
                              {
                                name: 'progressPercentage',
                                header: 'Progress (%)',
                              },
                              {
                                name: 'assignedUser.firstName',
                                header: 'Assigned',
                              },
                              {
                                name: 'status',
                                header: 'Status',
                                type: 'status',
                              },
                            ],
                            addEndpoint: `${API_URL}/action-plan/${id}/checklist-item`,
                            addTitle: 'Add Checklist Item',
                            addFields: [
                              {
                                name: 'title',
                                label: 'Title',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  required: true,
                                  type: 'string',
                                  minCharacters: 2,
                                },
                                type: 'text',
                              },
                              {
                                name: 'description',
                                label: 'Description',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  type: 'string',
                                  minCharacters: 2,
                                },
                                type: 'textarea',
                              },
                              {
                                name: 'progressPercentage',
                                label: 'Progress (%)',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  type: 'number',
                                  minCharacters: 2,
                                },
                                type: 'text',
                              },
                            ],
                            updateEndpoint: `${API_URL}/action-plan-checklist-item`,
                            updateTitle: 'Edit Checklist Item',
                            updateFields: [
                              {
                                name: 'title',
                                label: 'Title',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  required: true,
                                  type: 'string',
                                  minCharacters: 2,
                                },
                                type: 'text',
                              },
                              {
                                name: 'description',
                                label: 'Description',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  type: 'string',
                                  minCharacters: 2,
                                },
                                type: 'textarea',
                              },
                              {
                                name: 'progressPercentage',
                                label: 'Progress (%)',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  type: 'number',
                                  minCharacters: 2,
                                },
                                type: 'text',
                              },

                              {
                                name: 'status',
                                label: 'Status',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  required: true,
                                  type: 'string',
                                },
                                type: 'dropdown',
                                options: [
                                  {id: 'Not Started', title: 'Not Started'},
                                  {id: 'Ongoing', title: 'Ongoing'},
                                  {id: 'Completed', title: 'Completed'},
                                ],
                              },
                            ],
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  title: 'Assessed Directors',
                  components: [
                    {
                      type: 'view',

                      components: [
                        {
                          type: 'ItemManagementTable',
                          className: 'w-100 card px-5',
                          data: {
                            listEndpoint: `${API_URL}/evaluation/${id}/user`,
                            listTitle: 'Evaluation User',

                            listFields: [
                              {
                                name: 'user.firstName',
                                header: 'First Name',
                                type: 'link',
                                path: `/admin/evaluations/${id}/respondent-users`,
                              },
                              {
                                name: 'user.lastName',
                                header: 'Last Name',
                              },
                            ],
                            addEndpoint: `${API_URL}/evaluation/${id}/user`,
                            addTitle: 'Add User',
                            addFields: [
                              {
                                name: 'evaluationUserId',
                                label: 'Board Member',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  required: true,
                                  type: 'string',
                                },
                                type: 'dropdown',
                                optionsEndpoint: `${API_URL}/company/${companyId}/user?limit=1000`,
                              },
                            ],
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  title: 'Respondents',
                  components: [
                    {
                      type: 'view',

                      components: [
                        {
                          type: 'ItemManagementTable',
                          className: 'w-100 card px-5',
                          data: {
                            listEndpoint: `${API_URL}/evaluation/${id}/user`,
                            listTitle: 'Evaluation User',

                            listFields: [
                              {
                                name: 'user.firstName',
                                header: 'First Name',
                                type: 'link',
                                path: `/admin/evaluations/${id}/respondent-users`,
                              },
                              {
                                name: 'user.lastName',
                                header: 'Last Name',
                              },
                            ],
                            addEndpoint: `${API_URL}/evaluation/${id}/user`,
                            addTitle: 'Add User',
                            addFields: [
                              {
                                name: 'evaluationUserId',
                                label: 'Board Member',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  required: true,
                                  type: 'string',
                                },
                                type: 'dropdown',
                                optionsEndpoint: `${API_URL}/company/${companyId}/user?limit=1000`,
                              },
                            ],
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
              components: [
                {
                  type: 'view',

                  components: [
                    {
                      type: 'ItemManagementTable',
                      className: 'w-100 card px-5',
                      data: {
                        listEndpoint: `${API_URL}/evaluation/${id}/question`,
                        listTitle: 'Evaluation Questions',
                        listFields: [
                          {
                            name: 'title',
                            header: 'Title',
                            type: 'link',
                            path: '/action-plan-checklist-item',
                          },
                          {
                            name: 'progressPercentage',
                            header: 'Progress (%)',
                          },
                          {
                            name: 'assignedUser.firstName',
                            header: 'Assigned',
                          },
                          {
                            name: 'status',
                            header: 'Status',
                            type: 'status',
                          },
                        ],
                        addEndpoint: `${API_URL}/action-plan/${id}/checklist-item`,
                        addTitle: 'Add Checklist Item',
                        addFields: [
                          {
                            name: 'title',
                            label: 'Title',
                            toolTip: '',
                            placeholder: '',
                            validation: {
                              required: true,
                              type: 'string',
                              minCharacters: 2,
                            },
                            type: 'text',
                          },
                          {
                            name: 'description',
                            label: 'Description',
                            toolTip: '',
                            placeholder: '',
                            validation: {
                              type: 'string',
                              minCharacters: 2,
                            },
                            type: 'textarea',
                          },
                          {
                            name: 'progressPercentage',
                            label: 'Progress (%)',
                            toolTip: '',
                            placeholder: '',
                            validation: {
                              type: 'number',
                              minCharacters: 2,
                            },
                            type: 'text',
                          },
                        ],
                        updateEndpoint: `${API_URL}/action-plan-checklist-item`,
                        updateTitle: 'Edit Checklist Item',
                        updateFields: [
                          {
                            name: 'title',
                            label: 'Title',
                            toolTip: '',
                            placeholder: '',
                            validation: {
                              required: true,
                              type: 'string',
                              minCharacters: 2,
                            },
                            type: 'text',
                          },
                          {
                            name: 'description',
                            label: 'Description',
                            toolTip: '',
                            placeholder: '',
                            validation: {
                              type: 'string',
                              minCharacters: 2,
                            },
                            type: 'textarea',
                          },
                          {
                            name: 'progressPercentage',
                            label: 'Progress (%)',
                            toolTip: '',
                            placeholder: '',
                            validation: {
                              type: 'number',
                              minCharacters: 2,
                            },
                            type: 'text',
                          },

                          {
                            name: 'status',
                            label: 'Status',
                            toolTip: '',
                            placeholder: '',
                            validation: {
                              required: true,
                              type: 'string',
                            },
                            type: 'dropdown',
                            options: [
                              {id: 'Not Started', title: 'Not Started'},
                              {id: 'Ongoing', title: 'Ongoing'},
                              {id: 'Completed', title: 'Completed'},
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
                {
                  type: 'view',

                  components: [
                    {
                      type: 'ItemManagementTable',
                      className: 'w-100 card px-5',
                      data: {
                        listEndpoint: `${API_URL}/evaluation/${id}/user`,
                        listTitle: 'Assessed Directors',

                        listFields: [
                          {
                            name: 'user.firstName',
                            header: 'First Name',
                            type: 'link',
                            path: `/admin/evaluations/${id}/respondent-users`,
                          },
                          {
                            name: 'user.lastName',
                            header: 'Last Name',
                          },
                        ],
                        addEndpoint: `${API_URL}/evaluation/${id}/user`,
                        addTitle: 'Add User',
                        addFields: [
                          {
                            name: 'evaluationUserId',
                            label: 'Board Member',
                            toolTip: '',
                            placeholder: '',
                            validation: {
                              required: true,
                              type: 'string',
                            },
                            type: 'dropdown',
                            optionsEndpoint: `${API_URL}/company/${companyId}/user?limit=1000`,
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  }

  const formObject = {
    endpoint: `${API_URL}/evaluation/${id}`,
    dataFields: [
      {
        name: 'dateCreated',
        label: 'Date Created',
        type: 'date',
      },
    ],
    tabs: [
      {
        title: 'Questions',
        path: `/admin/evaluations/${id}`,
      },
      {
        title: 'Users',
        path: `/admin/evaluations/${id}/users`,
      },
      {
        title: 'Respondents',
        path: `/admin/evaluations/${id}/respondents`,
      },
    ],
  }

  return <DynamicScreen data={screenData} />
  // return (

  //   <Routes>
  //     <Route
  //       element={
  //         <>
  //           <DetailHeader formObject={formObject} id={id as string} />
  //           <Outlet />
  //         </>
  //       }
  //     >
  //       <Route
  //         index
  //         element={
  //           <>
  //             <PageTitle breadcrumbs={profileBreadCrumbs}>Evaluation Questions</PageTitle>
  //             <EvaluationQuestions />
  //           </>
  //         }
  //       />

  //       {/* <Route
  //         path='questions'
  //         element={
  //           <>
  //             <PageTitle breadcrumbs={profileBreadCrumbs}>Evaluation Questions</PageTitle>
  //             <EvaluationQuestions />
  //           </>
  //         }
  //       /> */}

  //       <Route
  //         path={`users`}
  //         element={
  //           <>
  //             <PageTitle breadcrumbs={profileBreadCrumbs}>Agenda</PageTitle>
  //             <EvaluationUsers />
  //           </>
  //         }
  //       />

  //       <Route
  //         path={`respondents`}
  //         element={
  //           <>
  //             <PageTitle breadcrumbs={profileBreadCrumbs}>Agenda</PageTitle>
  //             <EvaluationRespondents />
  //           </>
  //         }
  //       />

  //       <Route
  //         path={`evaluation-users`}
  //         element={
  //           <>
  //             <PageTitle breadcrumbs={profileBreadCrumbs}>Agenda</PageTitle>
  //             <RespondentUserEvaluationUsers />
  //           </>
  //         }
  //       />

  //       <Route
  //         path={`respondent-users/:evaluationUserId`}
  //         element={
  //           <>
  //             <PageTitle breadcrumbs={profileBreadCrumbs}>Agenda</PageTitle>
  //             <EvaluationUserRespondentUsers />
  //           </>
  //         }
  //       />

  //       {/*
  //       <Route element={<Navigate to='/crafted/pages/profile/overview' />} /> */}
  //     </Route>
  //   </Routes>
  // )
}

export default EvaluationsPage
