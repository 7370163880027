import React, {useEffect, useState} from 'react'
import axios from 'axios'
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import {Link, useLocation, useSearchParams} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import {KTIcon, KTSVG} from '../../../_metronic/helpers'

const GroupListCard = ({formObject, item, loading}: any) => {
    const location = useLocation()
    // const isActive = location.pathname.endsWith(formObject.activePath)
  return (
    <>
      {/*begin::Content*/}
      <div className='card card-flush'>
        {/*begin::Card header*/}
        <div className='card-header pt-7' id='kt_chat_contacts_header'>
          {/*begin::Card title*/}
          <div className='card-title'>
            <h2>{formObject.title}</h2>
          </div>
          {/*end::Card title*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className='card-body pt-5'>
          {/*begin::Contact groups*/}
          <div className='d-flex flex-column gap-5'>
            {/*begin::Contact group*/}
            {formObject.items.map((item: any) => {
              return (
                <div className={`d-flex flex-stack ${item.href && location.pathname.endsWith(item.href) ? 'px-2 mx-n2 py-1 rounded bg-light-active': ''}`}>
                  <Link to={item.href} className={`fs-6 fw-bold text-gray-800  ${item.className}`}>
                    {item.title}
                  </Link>
                  <div className='badge badge-light-primary'>{item.count}</div>
                </div>
              )
            })}

            {/*begin::Contact group*/}
          </div>
          {/*end::Contact groups*/}
          {/*begin::Separator*/}
          {/* <div className='separator my-7' /> */}
          {/*begin::Separator*/}
          {/*begin::Add new contact*/}
          {/* <a
            href='/metronic8/demo1/apps/contacts/add-contact.html'
            className='btn btn-primary w-100'
          >
            <KTIcon iconName='plus' className='fs-4' iconType='solid' />{' '}
            {formObject.bottomActionButtonText}
          </a> */}
          {/*end::Add new contact*/}
        </div>
        {/*end::Card body*/}
      </div>

      {/*end::Content*/}
    </>
  )
}

export default GroupListCard
