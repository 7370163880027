export const GetStatusCssClassColor = (status: string) => {
  let cssClass
  switch (status) {
    case 'Active':
    case 'success':
    case 'Completed':
      cssClass = 'success'
      break

    case 'Confirmed':
      cssClass = 'primary'
      break

    case 'Disabled':
    case 'Canceled':
      cssClass = 'danger'
      break

    case 'Declined':
      cssClass = 'danger'
      break

    case 'Pending':
    case 'In Progress':
    case 'PendingApproval':
      cssClass = 'warning'
      break

    case 'Not Started':
      cssClass = 'dark'
      break

    default:
      cssClass = 'dark'
      break
  }
  return cssClass
}
