import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, useAuth} from '../../../web-app-core'
import DynamicScreen from '../../../web-app-core/components/DynamicScreen'

const API_URL = import.meta.env.VITE_APP_API_URL

export default function Notifications() {
  const {auth} = useAuth()

  const screenData = {
    screen: {
      className: 'card',
      iconName: 'notification-circle',
      title: 'Notifications',
      components: [
        {
          type: 'ItemManagementTable',
          data: {
            listEndpoint: `${API_URL}/user/${auth?.user?.id}/notification`,
            listTitle: 'Notifications',
            listFields: [
              {
                name: 'title',
                header: 'Title',
                type: 'link',
                path: '/notifications',
              },
            ],
            addEndpoint: '',
            addTitle: '',
            addFields: [],
            updateEndpoint: '',
            updateTitle: '',
            updateFields: [],
          },
        },
      ],
    },
  }

  return <DynamicScreen data={screenData} />
}
