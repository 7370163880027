import {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../web-app-core'
import DynamicScreen from '../../../web-app-core/components/DynamicScreen'

const API_URL = import.meta.env.VITE_APP_API_URL

export default function Meetings() {
  const navigate = useNavigate()

  const {pathname} = useLocation()
  const {auth} = useAuth()
  console.log('currentTenant', auth?.currentTenant?.id)

  const currentTenant = auth?.currentTenant?.id

  console.log('pathname', pathname)
  const [listEndpoint, setListEndpoint] = useState(`${API_URL}/company/${currentTenant}/meeting`)

  // get last part of pathname


  useEffect(() => {
    const pathParts = pathname.split('/')
    const lastPath = pathParts[pathParts.length - 1]
    switch (lastPath) {
      case 'upcoming':
        setListEndpoint(`${API_URL}/company/${currentTenant}/meeting-future`)
        break;
      case 'past':
        setListEndpoint(`${API_URL}/company/${currentTenant}/meeting-past`)
        break;
    
      case 'canceled':
        setListEndpoint(`${API_URL}/company/${currentTenant}/meeting-cancelled`)
        break;
    
      default:
        setListEndpoint(`${API_URL}/company/${currentTenant}/meeting`)
        break;
    }
  }
  , [pathname])



  const screenData = {
    screen: {
      className: '',
      title: 'Meetings',
      iconName: 'calendar',
      actionButtonText: 'Add Meeting',
      actionButton: {
        buttonText: 'Add Meeting',
        addTitle: 'Add Meeting',
        addEndpoint: `${API_URL}/company/${currentTenant}/meeting`,
        addFields: [
          {
            name: 'title',
            label: 'Title',
            toolTip: '',
            placeholder: '',
            validation: {
              required: true,
              type: 'string',
              minCharacters: 2,
            },
            type: 'text',
          },
          {
            name: 'description',
            label: 'Description',
            toolTip: '',
            placeholder: '',
            validation: {
              type: 'string',
              minCharacters: 2,
            },
            type: 'textarea',
          },
          {
            name: 'startTime',
            label: 'Start',
            toolTip: '',
            placeholder: '',
            validation: {
              required: true,
              type: 'string',
            },
            type: 'datetimepicker',
            cols: '5',
          },
          {
            name: 'endTime',
            label: 'End',
            toolTip: '',
            placeholder: '',
            validation: {
              required: true,
              type: 'string',
            },
            type: 'datetimepicker',
            cols: '5',
          },
        ]
    },
      components: [
        {
          type: 'view',
          className: '',
          style: {
            flexDirection: 'row',
          },
          components: [
            {
              type: 'GroupListCard',
              className: 'me-5 card col-3',
              bottomActionButtonText: 'View All',
              data: {
                title: 'Filter',
                bottomActionButtonText: 'Add Meeting',
                bottomActionButtonHref: '/meetings/new',
                items: [
                  {
                    title: 'All Meetings',
                    count: 0,
                    href: '/meetings',
                    className: 'text-hover-primary',
                  },
                  {
                    title: 'Upcoming',
                    count: 0,
                    href: '/meetings/upcoming',
                    className: 'text-hover-primary',
                  },
                  {
                    title: 'Past',
                    count: 0,
                    href: '/meetings/past',
                    className: 'text-hover-dark',
                  },
                  {
                    title: 'Cancelled',
                    count: 0,
                    href: '/meetings/canceled',
                    className: 'text-hover-primary text-danger',
                  },
                ]
              },
            },
            {
              type: 'ItemListWithStatus',
              className: 'w-100 card px-5 py-3',
              renderItem: {
                type: 'div',
                className: 'd-flex flex-stack position-relative my-3 w-100 align-items-start',
                components: [
                  {
                    type: 'view',
                    colorMapping: {
                      'Not Started': 'danger',
                      Ongoing: 'warning',
                      Completed: 'success',
                    },
                    className: 'position-absolute h-100 w-4px bg-{status} rounded top-0 start-0',
                  },
                  {
                    type: 'view',
                    className: 'fw-semibold ms-5 text-gray-700',
                    components: [
                      {
                        className: 'fs-3 fw-bolder text-gray-800 text-hover-primary',
                        type: 'text',
                        text: '{title}',
                      },
                      {
                        className: 'fs-5',
                        type: 'text',
                        text: '{description}',
                      },

                      {
                        className: 'fs-5 text-gray-700 ',
                        type: 'date',
                        format: 'ddd MMM D, YYYY',
                        text: '{dateCreated}',
                      },
                    ],
                  },
                  {
                    type: 'view',
                    className: 'ms-auto flex-row align-self-center',
                    components: [
                      {
                        type: 'link',
                        className:
                          'btn btn-bg-light btn-active-color-primary btn-sm align-self-center',
                        // path: '/action-plans/{id}',
                        action: {
                          handler: 'AddUpdateModal',
                          layoutData: {
                            updateEndpoint: `${API_URL}/meeting`,
                            updateTitle: 'Edit Meeting',
                            updateFields: [
                              {
                                name: 'title',
                                label: 'Title',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  required: true,
                                  type: 'string',
                                  minCharacters: 2,
                                },
                                type: 'text',
                              },
                              {
                                name: 'description',
                                label: 'Description',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  type: 'string',
                                  minCharacters: 2,
                                },
                                type: 'textarea',
                              },
                              {
                                name: 'startTime',
                                label: 'Start',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  required: true,
                                  type: 'string',
                                },
                                type: 'datetimepicker',
                                cols: '5',
                              },
                              {
                                name: 'endTime',
                                label: 'End',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  required: true,
                                  type: 'string',
                                },
                                type: 'datetimepicker',
                                cols: '5',
                              },
                            ],
                          },
                        },
                        text: 'Edit',
                      },
                      {
                        type: 'link',
                        className:
                          'btn btn-bg-light btn-active-color-primary btn-sm align-self-center ms-2',
                        path: '/meetings/{id}',
                        text: 'View',
                      },
                    ],
                  },
                ],
              },
              data: {
                listEndpoint: listEndpoint,
                listTitle: 'Meetings',
                listItemPath: '/meetings',
                listFields: [
                  {
                    name: 'title',
                    header: 'Title',
                    type: 'link',
                    path: '/meetings',
                  },
                  {
                    name: 'startTime',
                    header: 'Start',
                    type: 'datetime',
                  },
                  {
                    name: 'dateCreated',
                    header: 'Date Created',
                    type: 'date',
                  },
                  {
                    name: 'status',
                    header: 'Status',
                    type: 'status',
                  },
                ],
                addEndpoint: `${API_URL}/company/${currentTenant}/meeting`,
                addTitle: 'Add Meeting',
                addFields: [
                  {
                    name: 'title',
                    label: 'Title',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                      minCharacters: 2,
                    },
                    type: 'text',
                  },
                  {
                    name: 'description',
                    label: 'Description',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      type: 'string',
                      minCharacters: 2,
                    },
                    type: 'textarea',
                  },
                  {
                    name: 'startTime',
                    label: 'Start',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                    },
                    type: 'datetimepicker',
                    cols: '5',
                  },
                  {
                    name: 'endTime',
                    label: 'End',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                    },
                    type: 'datetimepicker',
                    cols: '5',
                  },
                ],
                updateEndpoint: `${API_URL}/meeting`,
                updateTitle: 'Edit Meeting',
                updateFields: [
                  {
                    name: 'title',
                    label: 'Title',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                      minCharacters: 2,
                    },
                    type: 'text',
                  },
                  {
                    name: 'description',
                    label: 'Description',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      type: 'string',
                      minCharacters: 2,
                    },
                    type: 'textarea',
                  },
                  {
                    name: 'startTime',
                    label: 'Start',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                    },
                    type: 'datetimepicker',
                    cols: '5',
                  },
                  {
                    name: 'endTime',
                    label: 'End',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                    },
                    type: 'datetimepicker',
                    cols: '5',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  }

  useEffect(() => {
    console.log('currentTenant', currentTenant)
    if (!auth?.currentTenant?.id) {
      console.log('no current tenant')
      navigate('/companies')
    }
  }, [auth])

  return <div key={listEndpoint}>
    <DynamicScreen data={screenData} />
  </div> 
}
