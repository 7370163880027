import {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../web-app-core'
import DynamicScreen from '../../../web-app-core/components/DynamicScreen'

const API_URL = import.meta.env.VITE_APP_API_URL

export default function Compliance() {
  const {pathname} = useLocation()
  console.log('pathname', pathname)

  const {auth} = useAuth()
  console.log('currentTenant', auth?.currentTenant?.id)

  const currentTenant = auth?.currentTenant?.id

  console.log('pathname', pathname)
  const [listEndpoint, setListEndpoint] = useState(
    `${API_URL}/company/${currentTenant}/compliance`
  )

  // get last part of pathname

  useEffect(() => {
    const pathParts = pathname.split('/')
    const lastPath = pathParts[pathParts.length - 1]
    switch (lastPath) {
      case 'not-started':
        setListEndpoint(`${API_URL}/company/${currentTenant}/compliance/other?status=NotStarted`)
        break
      case 'ongoing':
        setListEndpoint(`${API_URL}/company/${currentTenant}/compliance/other?status=Ongoing`)
        break

      case 'completed':
        setListEndpoint(`${API_URL}/company/${currentTenant}/compliance/other?status=Completed`)
        break

      default:
        setListEndpoint(`${API_URL}/company/${currentTenant}/compliance/other`)
        break
    }
  }, [pathname])

  const screenData = {
    screen: {
      className: 'card',
      components: [
        {
          type: 'ItemManagementTable',
          data: {
            listEndpoint: `${API_URL}/company/${currentTenant}/compliance/jjh`,
            listTitle: 'Compliance',
            listFields: [
              {
                name: 'title',
                header: 'Title',
                type: 'link',
                path: '/compliance',
              },
              // {
              //   name: 'description',
              //   header: 'Description',
              // },
              {
                name: 'startTime',
                header: 'Start Date',
                type: 'datetime',
              },
              {
                name: 'endTime',
                header: 'Completion Date',
                type: 'datetime',
              },
              {
                name: 'status',
                header: 'Status',
                type: 'status',
              },
            ],
            addEndpoint: `${API_URL}/company/${currentTenant}/compliance`,
            addTitle: 'Add Compliance',
            addFields: [
              {
                name: 'title',
                label: 'Title',
                toolTip: '',
                placeholder: '',
                validation: {
                  required: true,
                  type: 'string',
                  minCharacters: 2,
                },
                type: 'text',
              },
              {
                name: 'description',
                label: 'Description',
                toolTip: '',
                placeholder: '',
                validation: {
                  type: 'string',
                },
                type: 'textarea',
              },
              {
                name: 'startTime',
                label: 'Start Date',
                toolTip: '',
                placeholder: '',
                validation: {
                  required: true,
                  type: 'string',
                },
                type: 'datetimepicker',
                cols: '5',
              },
              {
                name: 'endTime',
                label: 'Completion Date',
                toolTip: '',
                placeholder: '',
                validation: {
                  required: true,
                  type: 'string',
                },
                type: 'datetimepicker',
                cols: '5',
              },
            ],
            updateEndpoint: `${API_URL}/compliance`,
            updateTitle: 'Edit Compliance',
            updateFields: [
              {
                name: 'title',
                label: 'Title',
                toolTip: '',
                placeholder: '',
                validation: {
                  required: true,
                  type: 'string',
                  minCharacters: 2,
                },
                type: 'text',
              },
              {
                name: 'description',
                label: 'Description',
                toolTip: '',
                placeholder: '',
                validation: {
                  type: 'string',
                },
                type: 'textarea',
              },
              {
                name: 'startTime',
                label: 'Start Date',
                toolTip: '',
                placeholder: '',
                validation: {
                  required: true,
                  type: 'string',
                },
                type: 'datetimepicker',
                cols: '5',
              },
              {
                name: 'endTime',
                label: 'Completion Date',
                toolTip: '',
                placeholder: '',
                validation: {
                  required: true,
                  type: 'string',
                },
                type: 'datetimepicker',
                cols: '5',
              },
              {
                name: 'status',
                label: 'Status',
                toolTip: '',
                placeholder: '',
                validation: {
                  required: true,
                  type: 'string',
                },
                type: 'dropdown',
                options: [
                  {id: 'Not Started', title: 'Not Started'},
                  {id: 'Ongoing', title: 'Ongoing'},
                  {id: 'Completed', title: 'Completed'},
                ],
              },
            ],
          },
        },
      ],
    },
  }

  const screenData2 = {
    screen: {
      className: '',
      title: 'Compliance',
      iconName: 'calendar',
      actionButtonText: 'Add Compliance',
      actionButton: {
        buttonText: 'Add Compliance',
        addEndpoint: `${API_URL}/company/${currentTenant}/compliance`,
        addTitle: 'Add Compliance',
        addFields: [
          {
            name: 'title',
            label: 'Title',
            toolTip: '',
            placeholder: '',
            validation: {
              required: true,
              type: 'string',
              minCharacters: 2,
            },
            type: 'text',
          },
          {
            name: 'description',
            label: 'Description',
            toolTip: '',
            placeholder: '',
            validation: {
              type: 'string',
            },
            type: 'textarea',
          },
          {
            name: 'startTime',
            label: 'Start Date',
            toolTip: '',
            placeholder: '',
            validation: {
              required: true,
              type: 'string',
            },
            type: 'datetimepicker',
            cols: '5',
          },
          {
            name: 'endTime',
            label: 'Completion Date',
            toolTip: '',
            placeholder: '',
            validation: {
              required: true,
              type: 'string',
            },
            type: 'datetimepicker',
            cols: '5',
          },
        ],
      },
      components: [
        {
          type: 'div',
          className: 'row',
          style: {
            flexDirection: 'row',
          },
          components: [
            {
              type: 'GroupListCard',
              className: ' col-12 col-sm-3 mb-5',
              bottomActionButtonText: 'View All',
              data: {
                title: 'Filter',
                bottomActionButtonText: 'Add Compliance',
                bottomActionButtonHref: '/compliance/new',
                items: [
                  {
                    title: 'All Compliances',
                    count: 0,
                    href: '/compliance',
                    className: 'text-hover-primary',
                  },
                  {
                    title: 'Not Started',
                    count: 0,
                    href: '/compliance/not-started',
                    className: 'text-hover-primary',
                  },
                  {
                    title: 'Ongoing',
                    count: 0,
                    href: '/compliance/ongoing',
                    className: 'text-hover-dark',
                  },
                  {
                    title: 'Cancelled',
                    count: 0,
                    href: '/compliance/completed',
                    className: 'text-hover-primary text-danger',
                  },
                ],
              },
            },
            {
              type: 'ItemListWithStatus',
              className: 'flex-grow-1 card px-5 py-3 col-12 col-sm-9',
              renderItem: {
                type: 'div',
                className: 'd-flex flex-stack position-relative my-3 w-100 align-items-start',
                components: [
                  {
                    type: 'view',
                    colorMapping: {
                      'Not Started': 'dark',
                      NotStarted: 'dark',
                      Ongoing: 'warning',
                      Completed: 'success',
                    },
                    className: 'position-absolute h-100 w-4px bg-{status} rounded top-0 start-0',
                  },
                  {
                    type: 'view',
                    className: 'fw-semibold ms-5 text-gray-700',
                    components: [
                      {
                        className: 'fs-3 fw-bolder text-gray-800 text-hover-primary',
                        type: 'text',
                        text: '{title}',
                      },
                      {
                        className: 'fs-5',
                        type: 'text',
                        text: '{description}',
                      },

                      {
                        className: 'fs-5 text-gray-700 ',
                        type: 'date',
                        format: 'ddd MMM D, YYYY',
                        text: '{dateCreated}',
                      },
                    ],
                  },
                  {
                    type: 'view',
                    className: 'ms-auto flex-row align-self-center',
                    components: [
                      {
                        type: 'link',
                        className:
                          'btn btn-bg-light btn-active-color-primary btn-sm align-self-center',
                        // path: '/compliance/{id}',
                        action: {
                          handler: 'AddUpdateModal',
                          layoutData: {
                            updateEndpoint: `${API_URL}/compliance`,
                            updateTitle: 'Edit Compliance',
                            updateFields: [
                              {
                                name: 'title',
                                label: 'Title',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  required: true,
                                  type: 'string',
                                  minCharacters: 2,
                                },
                                type: 'text',
                              },
                              {
                                name: 'description',
                                label: 'Description',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  type: 'string',
                                  minCharacters: 2,
                                },
                                type: 'textarea',
                              },
                              {
                                name: 'startTime',
                                label: 'Start',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  required: true,
                                  type: 'string',
                                },
                                type: 'datetimepicker',
                                cols: '5',
                              },
                              {
                                name: 'endTime',
                                label: 'End',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  required: true,
                                  type: 'string',
                                },
                                type: 'datetimepicker',
                                cols: '5',
                              },
                              {
                                name: 'status',
                                label: 'Status',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  required: true,
                                  type: 'string',
                                },
                                type: 'dropdown',
                                options: [
                                  {id: 'Not Started', title: 'Not Started'},
                                  {id: 'Ongoing', title: 'Ongoing'},
                                  {id: 'Completed', title: 'Completed'},
                                ],
                              },
                            ],
                          },
                        },
                        text: 'Edit',
                      },
                      {
                        type: 'link',
                        className:
                          'btn btn-bg-light btn-active-color-primary btn-sm align-self-center ms-2',
                        path: '/compliance/{id}',
                        text: 'View',
                      },
                    ],
                  },
                ],
              },
              data: {
                listEndpoint: listEndpoint,
                listTitle: 'Compliances',
                listItemPath: '/compliance',
                listFields: [
                  {
                    name: 'title',
                    header: 'Title',
                    type: 'link',
                    path: '/compliance',
                  },
                  {
                    name: 'startTime',
                    header: 'Start',
                    type: 'datetime',
                  },
                  {
                    name: 'dateCreated',
                    header: 'Date Created',
                    type: 'date',
                  },
                  {
                    name: 'status',
                    header: 'Status',
                    type: 'status',
                  },
                ],
                addEndpoint: `${API_URL}/company/${currentTenant}/compliance/other`,
                addTitle: 'Add Compliance',
                addFields: [
                  {
                    name: 'title',
                    label: 'Title',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                      minCharacters: 2,
                    },
                    type: 'text',
                  },
                  {
                    name: 'description',
                    label: 'Description',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      type: 'string',
                      minCharacters: 2,
                    },
                    type: 'textarea',
                  },
                  {
                    name: 'startTime',
                    label: 'Start',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                    },
                    type: 'datetimepicker',
                    cols: '5',
                  },
                  {
                    name: 'endTime',
                    label: 'End',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                    },
                    type: 'datetimepicker',
                    cols: '5',
                  },
                ],
                updateEndpoint: `${API_URL}/compliance`,
                updateTitle: 'Edit Compliance',
                updateFields: [
                  {
                    name: 'title',
                    label: 'Title',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                      minCharacters: 2,
                    },
                    type: 'text',
                  },
                  {
                    name: 'description',
                    label: 'Description',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      type: 'string',
                      minCharacters: 2,
                    },
                    type: 'textarea',
                  },
                  {
                    name: 'startTime',
                    label: 'Start',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                    },
                    type: 'datetimepicker',
                    cols: '5',
                  },
                  {
                    name: 'endTime',
                    label: 'End',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                    },
                    type: 'datetimepicker',
                    cols: '5',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  }


  const formObject = {
    listEndpoint: `${API_URL}/company/${currentTenant}/compliance`,
    listTitle: 'Compliance',
    listFields: [
      {
        name: 'title',
        header: 'Title',
        type: 'link',
        path: '/compliance',
      },
      // {
      //   name: 'description',
      //   header: 'Description',
      // },
      {
        name: 'startTime',
        header: 'Start Date',
        type: 'datetime',
      },
      {
        name: 'endTime',
        header: 'Completion Date',
        type: 'datetime',
      },
      {
        name: 'status',
        header: 'Status',
        type: 'status',
      },
    ],
    addEndpoint: `${API_URL}/company/${currentTenant}/compliance`,
    addTitle: 'Add Compliance',
    addFields: [
      {
        name: 'title',
        label: 'Title',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
          minCharacters: 2,
        },
        type: 'text',
      },
      {
        name: 'description',
        label: 'Description',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
        },
        type: 'textarea',
      },
      {
        name: 'startTime',
        label: 'Start Date',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'datetimepicker',
        cols: '5',
      },
      {
        name: 'endTime',
        label: 'Completion Date',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'datetimepicker',
        cols: '5',
      },
    ],
    updateEndpoint: `${API_URL}/compliance`,
    updateTitle: 'Edit Compliance',
    updateFields: [
      {
        name: 'title',
        label: 'Title',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
          minCharacters: 2,
        },
        type: 'text',
      },
      {
        name: 'description',
        label: 'Description',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
        },
        type: 'textarea',
      },
      {
        name: 'startTime',
        label: 'Start Date',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'datetimepicker',
        cols: '5',
      },
      {
        name: 'endTime',
        label: 'Completion Date',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'datetimepicker',
        cols: '5',
      },
      {
        name: 'status',
        label: 'Status',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'dropdown',
        options: [
          {id: 'Not Started', title: 'Not Started'},
          {id: 'Ongoing', title: 'Ongoing'},
          {id: 'Completed', title: 'Completed'},
        ],
      },
    ],
  }

  return <DynamicScreen key={listEndpoint} data={screenData2} />
}
