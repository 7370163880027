/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-refresh/only-export-components */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren} from '../../helpers'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: string) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
  pageIconName?: string
  setPageIconName: (_iconName: string) => void
  actionButton?: any
  setActionButton: (_actionButton: any) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
  setPageIconName: (_iconName: string) => {},
  setActionButton: (_actionButton: any) => {},
})

const PageDataProvider: FC<WithChildren> = ({children}) => {
  const [pageIconName, setPageIconName] = useState<string>('')
  const [pageTitle, setPageTitle] = useState<string>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [actionButton, setActionButton] = useState<any>(null)
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    pageIconName, 
    setPageIconName,
    actionButton,
    setActionButton,
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  description?: string
  breadcrumbs?: Array<PageLink>
  iconName?: string
  actionButton?: any
}

const PageTitle: FC<Props & WithChildren> = ({children, description, breadcrumbs, iconName, actionButton}) => {
  const {setPageTitle, setPageDescription, setPageBreadcrumbs, setPageIconName, setActionButton} = usePageData()
  useEffect(() => {
    if (children) {
      setPageTitle(children.toString())
    }
    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (iconName) {
      console.log('iconName', iconName)
      setPageIconName(iconName)
    }
    return () => {
      setPageIconName('')
    }
  }, [iconName])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  useEffect(() => {
    if (actionButton) {
      console.log('actionButton', actionButton)
      setActionButton(actionButton)
    }
    return () => {
      setActionButton('')
    }
  }, [actionButton])

  return <></>
}

const PageIconName: FC<Props & WithChildren> = ({children, description, breadcrumbs, iconName, actionButton}) => {
  const {setPageTitle, setPageDescription, setPageBreadcrumbs, setPageIconName, setActionButton} = usePageData()
 

  useEffect(() => {
    if (iconName) {
      console.log('iconName', iconName)
      setPageIconName(iconName)
    }
    return () => {
      setPageIconName('')
    }
  }, [iconName])


  return <></>
}

const PageDescription: FC<WithChildren> = ({children}) => {
  const {setPageDescription} = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

export {PageDescription, PageTitle, PageIconName, PageDataProvider, usePageData}
