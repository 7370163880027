/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {useAuth} from '../../context/Auth'
import {GetFormattedFieldItem, GetStatusCssClassColor} from '../../helpers'
import AddUpdateItemModal from './AddUpdateItemModal'

interface ItemDetailCardProps {
  formObject: any
}

const ItemDetailCard: React.FC<ItemDetailCardProps> = ({formObject}) => {
  const location = useLocation()
  const {currentUser} = useAuth()
  const [item, setItem] = useState<any>({})
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  console.log(currentUser)
  const getObject = async () => {
    const response = await axios.get(`${formObject.endpoint}`)
    setItem(response.data.data)
  }

  useEffect(() => {
    console.log('location', location)
    getObject()
  }, [location])

  const handleRefresh = () => {
    getObject()
  }

  const handleEditClose = (): void => {
    setShowEditModal(false)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        {/*begin::Card header*/}
        <div className='card-header cursor-pointer'>
          {/*begin::Card title*/}
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Profile Details</h3>
          </div>
          {/*end::Card title*/}
          {/*begin::Action*/}
          {
            (formObject.fields && item) ? (
              <button
                className='btn btn-sm btn-primary align-self-center'
                onClick={() => setShowEditModal(true)}
              >
                {formObject.editButtonText ?? 'Edit'}
              </button>
            ) : null
          }
          {/*end::Action*/}
        </div>
        {/*begin::Card header*/}
        {/*begin::Card body*/}
        <div className='card-body p-9'>
          {
            /*begin::Row*/
            formObject.fields.map((field: any, index: number) => {
              return (
                <div className='row mb-7' key={index}>
                  {/*begin::Label*/}
                  <label className='col-lg-4 fw-semibold text-muted'>{field.label}</label>
                  {/*end::Label*/}
                  {/*begin::Col*/}
                  <div className='col-lg-8'>
                    <span className='fw-bold fs-6 text-gray-800'>
                      {GetFormattedFieldItem(item, field)}
                    </span>
                  </div>
                  {/*end::Col*/}
                </div>
              )
            })
          }

          {formObject.warning && (
            <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed  p-6'>
              {/*begin::Icon*/}
              <i className='ki-duotone ki-information fs-2tx text-warning me-4'>
                <span className='path1' />
                <span className='path2' />
                <span className='path3' />
              </i>{' '}
              {/*end::Icon*/}
              {/*begin::Wrapper*/}
              <div className='d-flex flex-stack flex-grow-1 '>
                {/*begin::Content*/}
                <div className=' fw-semibold'>
                  <h4 className='text-gray-900 fw-bold'>We need your attention!</h4>
                  <div className='fs-6 text-gray-700 '>
                    {formObject.warning?.text}{' '}
                    <a className='fw-bold' href={formObject.warning?.linkUrl}>
                      {formObject.warning?.linkText}
                    </a>
                    .
                  </div>
                </div>
                {/*end::Content*/}
              </div>
              {/*end::Wrapper*/}
            </div>
          )}
        </div>
        {/*end::Card body*/}
      </div>
      {(formObject.fields && item?.id) ? (
        <AddUpdateItemModal
          formObject={{updateFields: formObject.fields, updateEndpoint: formObject.updateEndpoint}}
          inputData={{...item}}
          show={showEditModal}
          handleClose={() => handleEditClose()}
          handleSaved={() => {
            handleEditClose()
            handleRefresh()
          }}
        />
      ) : null}
    </>
  )
}

export default ItemDetailCard
