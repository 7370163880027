import React, {useState, useEffect} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import axios from 'axios'
import {AddUpdateItemModal} from '../../../../web-app-core'
import moment from 'moment'

const API_URL = import.meta.env.VITE_APP_API_URL
const GET_REQUEST = `${API_URL}/company-user`

function getRequest(id: string) {
  return axios.get<any>(`${GET_REQUEST}/${id}`)
}

export function BoardMemberDetails() {
  const {id} = useParams()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [member, setMember] = useState({} as any)

  const loadData = () => {
    setLoading(true)
    getRequest(id as string).then(({data}) => {
      console.log('response data', data)
      setMember(data?.data)
    })
  }
  useEffect(() => {
    loadData()
  }, [])

  const formObject: any = {
    title: 'Session',
    description: 'Some description of section',
    addTitle: '',
    addEndpoint: `${GET_REQUEST}`,
    addFields: [],
    updateTitle: '',
    updateEndpoint: ``,
    updateFields: [],
    listTitle: '',
    listEndpoint: `${GET_REQUEST}`,
    listFields: [],
  }

  return (
    <>
      {/* biodata section */}
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Board Member Overview</h3>
          </div>

          {/* <button
            data-testid='update-modal-btn'
            className='btn btn-primary align-self-center'
            data-bs-toggle='modal'
            data-bs-target='#kt_update_profile'
            onClick={() => {
              setShowModal(true)
            }}
          >
            Update
          </button> */}
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>Email Address</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6 text-gray-800'>{member?.user?.emailAddress}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>Board Role</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-semibold text-gray-800 fs-6'>{member?.boardRole}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>User Role</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-semibold text-gray-800 fs-6'>{member?.userRole}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>Phone Number</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-semibold text-gray-800 fs-6'>{member?.user?.phoneNumber}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'> Date Added</label>

            <div className='col-lg-8'>
              <span className='fw-semibold text-gray-800 fs-6'>
                {moment(member?.dateCreated).format('MMM D, YYYY h:mm a')}
              </span>
            </div>
          </div>
        </div>
      </div>
      {member?.id && (
        <AddUpdateItemModal
          formObject={formObject}
          show={showModal}
          handleClose={() => setShowModal(false)}
          handleSaved={() => {
            setShowModal(false)
            loadData()
          }}
          inputData={member}
        />
      )}
    </>
  )
}
