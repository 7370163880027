import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {ManageItem} from '../../../../../web-app-core'

const API_URL = import.meta.env.VITE_APP_API_URL

export default function MeetingRecordings() {
  const navigate = useNavigate()

  const {pathname} = useLocation()
  const {id} = useParams()
  console.log('pathname', pathname)

  const formObject = {
    listEndpoint: `${API_URL}/meeting/${id}/recording`,
    listTitle: 'Recordings',
    listFields: [
      {
        name: 'name',
        header: 'Name',
        // type: 'link',
        // path: pathname + '/download',
      },
      {
        name: 'fileUrl',
        header: 'File',
        type: 'external-link2',
        title: 'Download', 
      },
      {
        name: 'dateCreated',
        header: 'Date',
        type: 'date',
      },
    ],
  }

  return <ManageItem formObject={formObject} />
}
