/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {ErrorMessage, Field} from 'formik'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import {KTIcon} from '../../../_metronic/helpers'
import axios from 'axios'
import Skeleton from 'react-loading-skeleton'

interface InputComponentProps {
  field: any
  data: any
  updateData: (fieldsToUpdate: Partial<any>) => void
  hasError: boolean
  key: string
  errors: any
  touched: any
}

const RadioList = ({field, data, updateData, hasError, errors, touched}: InputComponentProps) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [items, setItems] = useState<Array<any>>([])
  const getList = () => {
    return axios.get<any>(`${field.optionsEndpoint}`)
  }

  // check if optionsEndpoint is available
  useEffect(() => {
    if (field.optionsEndpoint) {
      setLoading(true)
      getList().then(({data: response}) => {
        console.log('data', response)
        setItems(response.data)

        setLoading(false)
      })
    } else {
      setItems(field.options)
      setLoading(false)
    }
  }, [])

  // console.log('InputTextWithLabel data', data)
  const [inputValue, setInputValue] = useState(data && data[field.name])
  const onChange = (e: any) => {
    let objectValue = {} as any
    objectValue[field.name as string] = e.target.value
    setInputValue(e.target.value)
    updateData(objectValue)
  }
  console.log('touched field name', touched[field.name])
  // console.log('errors', errors)
  return (
    <div className={`col-${field.cols ? field.cols : '12'} mb-7 mx-1`} key={field.name}>
      {/*begin::Form Group */}
      <div className='fv-row'>
        {/* begin::Label */}
        <label className='d-flex align-items-center fs-7 fw-bold mb-4'>
          <span className='required'>{field.label}</span>

          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Select your app category'
          ></i>
        </label>
        {/* end::Label */}
        <div className='me-5'>
          {loading ? (
            <Skeleton className='h-50px mb-3' count={3} />
          ) : (
            <>
              {items.map((option: any) => (
                <label key={option.label} className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
                  <span className='d-flex align-items-center me-2'>
                    <span className='symbol symbol-50px me-6'>
                      <span className={`symbol-label bg-light-${option.color ?? 'primary'}`}>
                        <KTIcon
                          iconName={option.icon}
                          className={`fs-1 text-${option.color ?? 'primary'}`}
                        />
                      </span>
                    </span>

                    <span className='d-flex flex-column'>
                      <span className='fw-bolder fs-6'>{option.label}</span>
                      <span className='fs-7 text-muted'>{option.description}</span>
                    </span>
                  </span>

                  <span className='form-check form-check-custom form-check-solid'>
                    <Field name={field.name}>
                      {({field, form, meta}: any) => (
                        <input
                          className='form-check-input'
                          {...field}
                          value={option.value}
                          checked={data[field.name] === option.value}
                          type='radio'
                          onChange={(e: any) => {
                            const newVal = e.target.value
                            form.setFieldValue(field.name, newVal)
                            updateData({[field.name]: newVal})
                          }}  
                        />
                      )}
                    </Field>
                  </span>
                </label>
              ))}
            </>
          )}
        </div>
      </div>
      {/*end::Form Group */}
      {!inputValue && hasError && (
        <div className='fv-plugins-message-container'>
          <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
            {field.label} is required
          </div>
        </div>
      )}
    </div>
  )
}

export {RadioList}
