import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, TableList} from '../../../web-app-core'
// import DynamicScreen from '../../../web-app-core/DynamicScreen'
import DynamicScreen from '../../../web-app-core/components/DynamicScreen'

const API_URL = import.meta.env.VITE_APP_API_URL

export default function Companies() {
  const navigate = useNavigate()

  const {pathname} = useLocation()
  console.log('pathname', pathname)
  const companyId = ''

  const screenData = {
    screen: {
      className: ' mx-auto pt-20 my-20 w-750px',
      components: [
        {
          type: 'view',
          className: 'card  ',
          components: [
            {
              type: 'ItemManagementTable',
              data: {
                listEndpoint: `${API_URL}/user/company`,
                listTitle: 'Please select company to continue',
                listFields: [
                  {
                    name: 'company.name',
                    header: 'COMPANY',
                    type: 'link',
                    path: '/select-company',
                  },

                  {
                    name: 'boardRole',
                    header: 'BOARD ROLE',
                  },
                  {
                    name: 'userRole',
                    header: 'USER ROLE',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  }

  return (
    <>
      <DynamicScreen data={screenData} />
    </>
  )
}
