/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {useAuth} from '../../context/Auth'
import {GetFormattedFieldItem, GetStatusCssClassColor} from '../../helpers'
import {KTIcon} from '../../../_metronic/helpers'

interface DetailHeaderProps {
  formObject: any
}

const DetailHeaderVertical: React.FC<DetailHeaderProps> = ({formObject}) => {
  const location = useLocation()
  const {currentUser} = useAuth()
  const [item, setItem] = useState<any>({})
  console.log(currentUser)
  const getObject = async () => {
    const response = await axios.get(`${formObject.endpoint}`)
    setItem(response.data.data)
  }
  const [showDetails, setShowDetails] = useState(true)
  useEffect(() => {
    console.log('location', location.pathname)
    getObject()
  }, [])

  return (
    <div className='flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10'>
      {/*begin::Card*/}
      <div className='card mb-5 mb-xl-8'>
        {/*begin::Card body*/}
        <div className='card-body'>
          {/*begin::Summary*/}
          {/*begin::User Info*/}
          <div className='d-flex flex-center flex-column py-5'>
            {/*begin::Avatar*/}
            {/* <div className='symbol symbol-100px symbol-circle mb-7'>
              <img src='/metronic8/demo1/assets/media/avatars/300-6.jpg' alt='image' />
            </div> */}
            {/*end::Avatar*/}
            {/*begin::Name*/}
            <a href='#' className='fs-3 text-gray-800 text-hover-primary fw-bold mb-3'>
              {item.title}
            </a>
            <div className='text-muted fw-bold fs-6 pe-2 mb-2 text-center'>{item?.description}</div>
            {/*end::Name*/}
            {/*begin::Position*/}
            <div className='mb-9'>
              {/*begin::Badge*/}
              <div className='badge badge-lg badge-light-primary d-inline'>{item.status}</div>
              {/*begin::Badge*/}
            </div>
            {/*end::Position*/}
            {/*begin::Info*/}
            {/*begin::Info heading*/}
            <div className='fw-bold mb-3'>
              Task Status
              <span
                className='ms-2'
                ddata-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Number of support tickets assigned, closed and pending this week.'
              >
                <i className='ki-duotone ki-information fs-7'>
                  <span className='path1' />
                  <span className='path2' />
                  <span className='path3' />
                </i>{' '}
              </span>
            </div>
            {/*end::Info heading*/}
            <div className='d-flex flex-wrap flex-center'>
              {/*begin::Stats*/}
              <div className='border border-gray-300 border-dashed text-center rounded py-3 px-3 mb-3 min-w-85px'>
                <div className='fs-4 fw-bold text-gray-700 '>
                  <span className='w-75px'>{item.total_ProductTask ?? 5}</span>
                 {' '}
                </div>
                <div className='fw-semibold text-muted'>Total</div>
              </div>
              {/*end::Stats*/}
              {/*begin::Stats*/}
              <div className='border border-gray-300 border-dashed roundedF py-3 px-3 mx-2 mb-3'>
                <div className='fs-4 fw-bold text-gray-700 text-center'>
                  <span className='w-50px'>{item.total_Completed ?? 0}</span>
                 {' '}
                </div>
                <div className='fw-semibold text-muted'>Completed</div>
              </div>
              {/*end::Stats*/}
              {/*begin::Stats*/}
              <div className='border border-gray-300 border-dashed rounded py-3 px-3 mb-3'>
                <div className='fs-4 fw-bold text-gray-700 text-center'>
                  <span className='w-50px'>{item.total_InProgress ?? 0}</span>
                  
                </div>
                <div className='fw-semibold text-muted'>In Progress</div>
              </div>
              {/*end::Stats*/}
            </div>
            {/*end::Info*/}
          </div>
          {/*end::User Info*/} {/*end::Summary*/}
          {/*begin::Details toggle*/}
          <div className='d-flex flex-stack fs-4 py-3'>
            <div
              className={`fw-bold rotate collapsible ${showDetails ? 'active' : 'collapsed'}`}
              data-bs-toggle='collapse'
              role='button'
              aria-expanded='true'
              aria-controls='kt_user_view_details'
              onClick={() => setShowDetails(!showDetails)}
            >
              Details
              <span className='ms-2 rotate-180'>
                <i className={`ki-duotone ki-down fs-3`} />{' '}
              </span>
            </div>
            <span
              data-bs-toggle='tooltip'
              data-bs-trigger='hover'
              data-bs-original-title='Edit customer details'
              data-kt-initialized={1}
            >
              <a
                href='#'
                className='btn btn-sm btn-light-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_update_details'
              >
                Edit
              </a>
            </span>
          </div>
          {/*end::Details toggle*/}
          <div className='separator' />
          {/*begin::Details content*/}
          <div
            id='kt_user_view_details'
            className={`collapse ${showDetails ? 'show' : ''}`}
            style={{}}
          >
            <div className='pb-5 fs-6'>
              {formObject.dataFields.map((field: any) => {
                return (
                  <div className='d-flex flex-wrap py-2'>
                    <div className='fw-bold w-100'>{field.label}</div>
                    <span className='text-gray-600'>{GetFormattedFieldItem(item, field)}</span>
                  </div>
                )
              })}
            </div>
          </div>
          {/*end::Details content*/}
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Card*/}
    </div>
  )
}

export default DetailHeaderVertical
