import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem title='Meetings' to='meetings' icon='calendar' fontIcon='bi-archive' />
      <SidebarMenuItem
        title='Action Plans'
        to='action-plans'
        icon='clipboard'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem title='Training' to='trainings' icon='bookmark-2' fontIcon='bi-layers' />
      <SidebarMenuItem title='Polls' to='polls' icon='chart-pie-3' fontIcon='bi-layers' />
  
      <SidebarMenuItem
        title='Evaluation'
        to='evaluations'
        icon='badge'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem title='Compliance' to='compliance' icon='clipboard' fontIcon='bi-layers' />
      <SidebarMenuItem title='Members' to='board-members' icon='people' fontIcon='bi-layers' />
      <SidebarMenuItem title='Companies' to='companies' icon='menu' fontIcon='bi-layers' />
      <SidebarMenuItem title='Approvals' to='approvals' icon='check-square' fontIcon='bi-layers' />
      <SidebarMenuItem title='Calendar' to='calendar' icon='calendar-add' fontIcon='bi-layers' />
      <SidebarMenuItem
        title='Document Folders'
        to='document-folders'
        icon='document'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        title='Notifications'
        to='notifications'
        icon='notification-circle'
        fontIcon='bi-layers'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Admin</span>
        </div>
      </div>
      <SidebarMenuItem
        title='Evaluation Config'
        to='admin/evaluations'
        icon='abstract-27'
        fontIcon='bi-layers'
      />

    </>
  )
}

export {SidebarMenuMain}
