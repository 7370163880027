import {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../web-app-core'
import DynamicScreen from '../../../web-app-core/components/DynamicScreen'

const API_URL = import.meta.env.VITE_APP_API_URL

export default function ApprovalResponses() {
  const {pathname} = useLocation()
  console.log('pathname', pathname)

  const {auth} = useAuth()
  console.log('currentTenant', auth?.currentTenant?.id)

  const currentTenant = auth?.currentTenant?.id


  console.log('pathname', pathname)
  const [listEndpoint, setListEndpoint] = useState(
    `${API_URL}/user/approval-request-user-response/Pending`
  )

  // get last part of pathname

  useEffect(() => {
    const pathParts = pathname.split('/')
    const lastPath = pathParts[pathParts.length - 1]
    switch (lastPath) {
      case 'pending':
        setListEndpoint(`${API_URL}/user/user/approval-request-user-response/Pending`)
        break
      case 'ongoing':
        setListEndpoint(`${API_URL}/user/user/approval-request-user-response/Pending`)
        break

      case 'completed':
        setListEndpoint(`${API_URL}/user/user/approval-request-user-response/Pending`)
        break

      default:
        setListEndpoint(`${API_URL}/company/${currentTenant}/action-plan`)
        break
    }
  }, [pathname])


  const screenData = {
    screen: {
      className: 'card',
      title: 'Approval Responses',
      iconName: 'clipboard',
      components: [

        {
          type: 'ItemManagementTable',
          data: {
            listEndpoint: `${API_URL}/user/approval-request-user-response/Pending`,
            listTitle: 'Approval Requests',
            listFields: [
              {
                name: 'title',
                header: 'Title',
                type: 'link',
                path: '/approvals',
              },
              {
                name: 'status',
                header: 'Status',
                type: 'status',
              },
              {
                name: 'dateCreated',
                header: 'Date Created',
                type: 'date',
              },
            ],
            addEndpoint: `${API_URL}/company/${currentTenant}/approval-request`,
            addTitle: 'Request Approval',
            addFields: [
              {
                name: 'title',
                label: 'Title',
                toolTip: '',
                placeholder: '',
                validation: {
                  required: true,
                  type: 'string',
                  minCharacters: 2,
                },
                type: 'text',
              },
              {
                name: 'description',
                label: 'Description',
                toolTip: '',
                placeholder: '',
                validation: {
                  type: 'string',
                },
                type: 'textarea',
              },
            ],
            updateEndpoint: `${API_URL}/approval-request`,
            updateTitle: 'Update Request',
            updateFields: [
              {
                name: 'title',
                label: 'Title',
                toolTip: '',
                placeholder: '',
                validation: {
                  required: true,
                  type: 'string',
                  minCharacters: 2,
                },
                type: 'text',
              },
              {
                name: 'description',
                label: 'Description',
                toolTip: '',
                placeholder: '',
                validation: {
                  type: 'string',
                },
                type: 'textarea',
              },
            ],
          },
        },
      ],
    },
  }

  const screenData2 = {
    screen: {
      className: '',
      title: 'Approval Requests',
      iconName: 'clipboard',
     
      components: [
        {
          type: 'div',
          className: 'row',
          style: {
            flexDirection: 'row',
          },
          components: [
            {
              type: 'GroupListCard',
              className: ' col-12 col-sm-3 mb-5',
              bottomActionButtonText: 'View All',
              data: {
                title: 'Filter',
                bottomActionButtonText: 'Add Approval Request',
                bottomActionButtonHref: '/approval-responses/new',
                items: [
                  {
                    title: 'All Approval Requests',
                    count: 0,
                    href: '/approval-responses',
                    className: 'text-hover-primary',
                  },
                  {
                    title: 'Pending',
                    count: 0,
                    href: '/approval-responses/pending',
                    className: 'text-hover-primary',
                  },
                  {
                    title: 'Cancelled',
                    count: 0,
                    href: '/approval-responses/completed',
                    className: 'text-hover-primary text-danger',
                  },
                ],
              },
            },
            {
              type: 'ItemListWithStatus',
              className: 'flex-grow-1 card px-5 py-3 col-12 col-sm-9',
              renderItem: {
                type: 'div',
                className: 'd-flex flex-stack position-relative my-3 w-100 align-items-start',
                components: [
                  {
                    type: 'view',
                    colorMapping: {
                      'Not Started': 'dark',
                      NotStarted: 'dark',
                      Pending: 'warning',
                      Completed: 'success',
                    },
                    className: 'position-absolute h-100 w-4px bg-{status} rounded top-0 start-0',
                  },
                  {
                    type: 'view',
                    className: 'fw-semibold ms-5 text-gray-700',
                    components: [
                      {
                        className: 'fs-3 fw-bolder text-gray-800 text-hover-primary',
                        type: 'text',
                        text: '{approvalRequest.title}',
                      },
                      {
                        className: 'fs-5',
                        type: 'text',
                        text: '{approvalRequest.description}',
                      },

                      {
                        className: 'fs-5 text-gray-700 ',
                        type: 'date',
                        format: 'ddd MMM D, YYYY',
                        text: '{dateCreated}',
                      },
                    ],
                  },
                  {
                    type: 'view',
                    className: 'ms-auto flex-row align-self-center',
                    components: [
                      {
                        type: 'link',
                        className:
                          'btn btn-bg-light btn-active-color-primary btn-sm align-self-center',
                        // path: '/approval-responses/{id}',
                        action: {
                          handler: 'AddUpdateModal',
                          layoutData: {
                            updateEndpoint: `${API_URL}/action-plan`,
                            updateTitle: 'Edit Approval Request',
                            updateFields: [
                              {
                                name: 'title',
                                label: 'Title',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  required: true,
                                  type: 'string',
                                  minCharacters: 2,
                                },
                                type: 'text',
                              },
                              {
                                name: 'description',
                                label: 'Description',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  type: 'string',
                                  minCharacters: 2,
                                },
                                type: 'textarea',
                              },
                              {
                                name: 'startTime',
                                label: 'Start',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  required: true,
                                  type: 'string',
                                },
                                type: 'datetimepicker',
                                cols: '5',
                              },
                              {
                                name: 'endTime',
                                label: 'End',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  required: true,
                                  type: 'string',
                                },
                                type: 'datetimepicker',
                                cols: '5',
                              },
                              {
                                name: 'status',
                                label: 'Status',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  required: true,
                                  type: 'string',
                                },
                                type: 'dropdown',
                                options: [
                                  {id: 'Not Started', title: 'Not Started'},
                                  {id: 'Ongoing', title: 'Ongoing'},
                                  {id: 'Completed', title: 'Completed'},
                                ],
                              },
                            ],
                          },
                        },
                        text: 'Edit',
                      },
                      {
                        type: 'link',
                        className:
                          'btn btn-bg-light btn-active-color-primary btn-sm align-self-center ms-2',
                        path: '/approval-responses/response/{approvalRequestId}',
                        text: 'View',
                      },
                    ],
                  },
                ],
              },
              data: {
                listEndpoint: listEndpoint,
                listTitle: 'Approval Requests',
                listItemPath: '/approval-responses',
                listFields: [
                  {
                    name: 'title',
                    header: 'Title',
                    type: 'link',
                    path: '/approval-responses',
                  },
                  {
                    name: 'startTime',
                    header: 'Start',
                    type: 'datetime',
                  },
                  {
                    name: 'dateCreated',
                    header: 'Date Created',
                    type: 'date',
                  },
                  {
                    name: 'status',
                    header: 'Status',
                    type: 'status',
                  },
                ],
                addEndpoint: `${API_URL}/company/${currentTenant}/action-plan`,
                addTitle: 'Add Approval Request',
                addFields: [
                  {
                    name: 'title',
                    label: 'Title',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                      minCharacters: 2,
                    },
                    type: 'text',
                  },
                  {
                    name: 'description',
                    label: 'Description',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      type: 'string',
                      minCharacters: 2,
                    },
                    type: 'textarea',
                  },
                  {
                    name: 'startTime',
                    label: 'Start',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                    },
                    type: 'datetimepicker',
                    cols: '5',
                  },
                  {
                    name: 'endTime',
                    label: 'End',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                    },
                    type: 'datetimepicker',
                    cols: '5',
                  },
                ],
                updateEndpoint: `${API_URL}/action-plan`,
                updateTitle: 'Edit Approval Request',
                updateFields: [
                  {
                    name: 'title',
                    label: 'Title',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                      minCharacters: 2,
                    },
                    type: 'text',
                  },
                  {
                    name: 'description',
                    label: 'Description',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      type: 'string',
                      minCharacters: 2,
                    },
                    type: 'textarea',
                  },
                  {
                    name: 'startTime',
                    label: 'Start',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                    },
                    type: 'datetimepicker',
                    cols: '5',
                  },
                  {
                    name: 'endTime',
                    label: 'End',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                    },
                    type: 'datetimepicker',
                    cols: '5',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  }


  return <DynamicScreen data={screenData2} />
}
