import React, {Fragment, useEffect, useState} from 'react'
import axios from 'axios'
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import {Link} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import {KTIcon, KTSVG} from '../../../_metronic/helpers'
import moment from 'moment'
import { useDynamicScreenContext } from '../../context/DynamicScreenContext'

const ItemListWithStatus = ({formObject, item, loading, renderItem}: any) => {
  const [items, setItems] = useState<any>([])
  const {refreshDataKey, setRefreshDataKey, refreshKey, setRefreshKey} = useDynamicScreenContext()
  const [localRefreshKey, setLocalRefreshKey] = useState<number>(0)
  const [loadingItems, setLoadingItems] = useState<boolean>(false)

  const getData = async () => {
    try {
      setLoadingItems(true)
      const response = await axios.get(formObject.listEndpoint)
      setItems(response.data.data)
      console.log('response', response)
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingItems(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])


  useEffect(() => {
    console.log('ItemListWithStatus refreshDataKey detailsEndpoint', items.length, formObject.listEndpoint)
    // console.log('refreshDataKey (refreshDataKey[data.id] > refreshKey), detailsEndpoint, data', (refreshDataKey && refreshDataKey[data?.id] > refreshKey), detailsEndpoint, data)
    if (items && formObject.listEndpoint && (refreshKey > localRefreshKey)) {
      console.log('ItemListWithStatus refreshDataKey true', refreshDataKey)

      getData()
      setLocalRefreshKey(new Date().getTime())
    }
  }, [refreshKey])

  return (
    loadingItems ? (
      <SkeletonTheme height={64} baseColor='#e0e0e0' borderRadius={7} highlightColor='#f5f5f5'>
        <Skeleton count={3} className='my-3' />
      </SkeletonTheme>
    ) : (
      <Fragment>
      {/*begin::Content*/}
      {items.map((item: any) => {
          return (
            
            <Fragment key={item.id}>
           {renderItem(item)}
            
              <hr className='text-gray-400' />
            </Fragment> 
            //  <div className='d-flex flex-stack position-relative my-6'>
            //     {/*begin::Bar*/}
            //     <div className='position-absolute h-100 w-4px bg-success rounded top-0 start-0' />
            //     {/*end::Bar*/}
            //     {/*begin::Info*/}
            //     <div className='fw-semibold ms-5 text-gray-700'>
            //       {/*begin::Time*/}
            //       <div className='fs-5'>{moment(item.startTime).format('ddd MMM D, YYYY')}</div>
            //       {/*end::Time*/}
            //       {/*begin::Title*/}
            //       <Link
            //         to={`${formObject.listItemPath}/${item.id}`}
            //         className='fs-4 fw-bolder text-gray-800 text-hover-primary mb-2'
            //       >
            //         {item.title}
            //       </Link>
            //       {/*end::Title*/}
            //       {/*begin::User*/}
            //       <div className='text-gray-700 fs-5'>
            //         {moment(item.startTime).format('hh:mm')} -{' '}
            //         {moment(item.endTime).format('hh:mm')}
            //         <span>{moment(item.endTime).format('a')}</span>
            //       </div>
            //       {/*end::User*/}
            //     </div>
            //     {/*end::Info*/}
            //     {/*begin::Action*/}
            //     <Link to={item.id} className='btn btn-bg-light btn-active-color-primary btn-sm'>
            //       View
            //     </Link>
            //     {/*end::Action*/}
            //   </div>
          )
        })}

      {/*end::Content*/}
      </Fragment>
    )
  )
}

export default ItemListWithStatus
