import * as Yup from 'yup'

export const getValidationSchema = (fields: Array<any>) => {
  let yupObject = {} as any
  let yupValidation = {} as any
  fields?.map((field: any) => {
    if (field.validation) {
      switch (field.validation['type']) {
        case 'number':
          yupObject[field.name] = Yup.number().typeError(`${field.label} must be a number`)
          break

        case 'string':
          yupObject[field.name] = Yup.string()
          break

        case 'array':
          yupObject[field.name] = Yup.array()
          break

        default:
          if (!field.validation['type']) {
            yupObject[field.name] = Yup.string()
          }
          break
      }

      Object.keys(field.validation).map((item) => {
        switch (item) {
          case 'required':
            yupObject[field.name] = yupObject[field.name].required(
              field.validation['message'] ?? `${field.label} is a required field`
            )
            break
          case 'email':
            yupObject[field.name] = yupObject[field.name].email(field.validation['message'])
            break
          case 'min':
            yupObject[field.name] = yupObject[field.name].min(
              field.validation[item],
              field.validation['message']
            )
            break
          case 'matches':
            yupObject[field.name] = yupObject[field.name].matches(
              field.validation[item],
              field.validation['message']
            )
            break
          case 'oneOf':
            yupObject[field.name] = yupObject[field.name].oneOf(
              [Yup.ref(field.validation[item])],
              field.validation['message']
            )
            break
          default:
            break
        }
      })
    }
  })

  console.log('yupObject', yupObject)
  return Yup.object().shape(yupObject)
}
