import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../web-app-core'
import DynamicScreen from '../../../web-app-core/components/DynamicScreen'

const API_URL = import.meta.env.VITE_APP_API_URL

export default function EvaluationRespondents() {
  const {pathname} = useLocation()
  console.log('pathname', pathname)

  const {auth} = useAuth()
  console.log('currentTenant', auth?.currentTenant?.id)

  const currentTenant = auth?.currentTenant?.id

  const screenData = {
    screen: {
      className: 'card',
      components: [
        
        {
          type: 'ItemManagementTable',
          data: {
            listEndpoint: `${API_URL}/user/${auth?.user?.id}/respondent-user`,
            listTitle: 'Evaluation',
            listFields: [
              {
                name: 'evaluation.title',
                header: 'Title',
                type: 'link',
                path: 'evaluation-users',
              },
              {
                name: 'evaluation.type',
                header: 'Type',
              },
              {
                name: 'status',
                header: 'Status',
                type: 'status',
              },
              {
                name: 'dateCreated',
                header: 'Date Created',
                type: 'date',
              },
              // {
              //   name: 'status',
              //   header: 'Status',
              //   type: 'status',
              // },
            ],
          },
        },
      ],
    },
  }

  const screenData2 = {
    screen: {
      className: '',
      title: 'Board Evaluation',
      iconName: 'badge',
      actionButtonText: 'Add Evaluation',
      actionButton: {
        buttonText: 'Add Evaluation',
        addTitle: 'Add Evaluation',
        addEndpoint: `${API_URL}/company/${currentTenant}/evaluation`,
        addFields: [
          {
            name: 'title',
            label: 'Title',
            toolTip: '',
            placeholder: '',
            validation: {
              required: true,
              type: 'string',
              minCharacters: 2,
            },
            type: 'text',
          },
          {
            name: 'description',
            label: 'Description',
            toolTip: '',
            placeholder: '',
            validation: {
              type: 'string',
              minCharacters: 2,
            },
            type: 'textarea',
          },
          {
            name: 'startTime',
            label: 'Start',
            toolTip: '',
            placeholder: '',
            validation: {
              required: true,
              type: 'string',
            },
            type: 'datetimepicker',
            cols: '5',
          },
          {
            name: 'endTime',
            label: 'End',
            toolTip: '',
            placeholder: '',
            validation: {
              required: true,
              type: 'string',
            },
            type: 'datetimepicker',
            cols: '5',
          },
        ],
      },
      components: [
        {
          type: 'div',
          className: 'row',
          style: {
            flexDirection: 'row',
          },
          components: [
            {
              type: 'GroupListCard',
              className: ' col-12 col-sm-3 mb-5',
              bottomActionButtonText: 'View All',
              data: {
                title: 'Filter',
                bottomActionButtonText: 'Add Evaluation',
                bottomActionButtonHref: '/evaluations/new',
                items: [
                  {
                    title: 'All Evaluations',
                    count: 0,
                    href: '/evaluations',
                    className: 'text-hover-primary',
                  },
                  {
                    title: 'Not Started',
                    count: 0,
                    href: '/evaluations/not-started',
                    className: 'text-hover-primary',
                  },
                  {
                    title: 'Ongoing',
                    count: 0,
                    href: '/evaluations/ongoing',
                    className: 'text-hover-dark',
                  },
                  {
                    title: 'Cancelled',
                    count: 0,
                    href: '/evaluations/completed',
                    className: 'text-hover-primary text-danger',
                  },
                ],
              },
            },
            {
              type: 'ItemListWithStatus',
              className: 'flex-grow-1 card px-5 py-3 col-12 col-sm-9',
              renderItem: {
                type: 'div',
                className: 'd-flex flex-stack position-relative my-3 w-100 align-items-start',
                components: [
                  {
                    type: 'view',
                    colorMapping: {
                      'NotStarted': 'danger',
                      Ongoing: 'warning',
                      Completed: 'success',
                    },
                    className: 'position-absolute h-100 w-4px bg-{status} rounded top-0 start-0',
                  },
                  {
                    type: 'view',
                    className: 'fw-semibold ms-5 text-gray-700',
                    components: [
                      {
                        className: 'fs-3 fw-bolder text-gray-800 text-hover-primary',
                        type: 'text',
                        text: '{evaluation.title}',
                      },
                      {
                        className: 'fs-5',
                        type: 'text',
                        text: '{evaluation.description}',
                      },

                      {
                        className: 'fs-5 text-gray-700 ',
                        type: 'date',
                        format: 'ddd MMM D, YYYY',
                        text: '{dateCreated}',
                      },
                    ],
                  },
                  {
                    type: 'view',
                    className: 'ms-auto flex-row align-self-center',
                    components: [
                      {
                        type: 'link',
                        className:
                          'btn btn-bg-light btn-active-color-primary btn-sm align-self-center ms-2',
                        path: '/evaluations/{id}',
                        text: 'View',
                      },
                    ],
                  },
                ],
              },
              data: {
                listEndpoint: `${API_URL}/user/${auth?.user?.id}/respondent-user`,
                listTitle: 'Evaluations',
                listItemPath: '/evaluations',
                listFields: [
                  {
                    name: 'title',
                    header: 'Title',
                    type: 'link',
                    path: '/evaluations',
                  },
                  {
                    name: 'startTime',
                    header: 'Start',
                    type: 'datetime',
                  },
                  {
                    name: 'dateCreated',
                    header: 'Date Created',
                    type: 'date',
                  },
                  {
                    name: 'status',
                    header: 'Status',
                    type: 'status',
                  },
                ],
                addEndpoint: `${API_URL}/company/${currentTenant}/evaluation`,
                addTitle: 'Add Evaluation',
                addFields: [
                  {
                    name: 'title',
                    label: 'Title',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                      minCharacters: 2,
                    },
                    type: 'text',
                  },
                  {
                    name: 'description',
                    label: 'Description',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      type: 'string',
                      minCharacters: 2,
                    },
                    type: 'textarea',
                  },
                  {
                    name: 'startTime',
                    label: 'Start',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                    },
                    type: 'datetimepicker',
                    cols: '5',
                  },
                  {
                    name: 'endTime',
                    label: 'End',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                    },
                    type: 'datetimepicker',
                    cols: '5',
                  },
                ],
                updateEndpoint: `${API_URL}/evaluation`,
                updateTitle: 'Edit Evaluation',
                updateFields: [
                  {
                    name: 'title',
                    label: 'Title',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                      minCharacters: 2,
                    },
                    type: 'text',
                  },
                  {
                    name: 'description',
                    label: 'Description',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      type: 'string',
                      minCharacters: 2,
                    },
                    type: 'textarea',
                  },
                  {
                    name: 'startTime',
                    label: 'Start',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                    },
                    type: 'datetimepicker',
                    cols: '5',
                  },
                  {
                    name: 'endTime',
                    label: 'End',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                    },
                    type: 'datetimepicker',
                    cols: '5',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  }


  return <DynamicScreen data={screenData2} />
}
