import React, {useEffect, useState} from 'react'
import axios from 'axios'
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import {Link} from 'react-router-dom'
import ItemCard from './ItemCard'

const CardList = ({formObject}: any) => {
  const [items, setitems] = useState<Array<any>>([{}, {}, {}, {}])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    axios
      .get(formObject.getEndpoint)
      .then(function (response) {
        // handle success
        setitems(response.data.data)
        console.log(response.data.data)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <>
      <div className='row g-6 g-xl-9'>
        {items.map((item, index) => {
          let colorCode = ''
          switch (item.status) {
            case 'OnboardingStarted':
              colorCode = 'badge-light-success'
              break
            case 'Active':
              colorCode = 'badge-light-success'
              break
            case 'PendingVerification':
              colorCode = 'badge-light-primary'
              break
            default:
              colorCode = 'badge-light-danger'
              break
          }
          return <ItemCard key={index} field={formObject.field} loading={loading} item={item} />
        })}
      </div>
    </>
  )
}

export default CardList
