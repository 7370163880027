import {useEffect} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../../../web-app-core'

const API_URL = import.meta.env.VITE_APP_API_URL

export default function MeetingDocuments() {
  const navigate = useNavigate()

  const {pathname} = useLocation()
  const {id} = useParams()
  console.log('pathname', pathname)

  const formObject = {
    listEndpoint: `${API_URL}/document/parent/${id}`,
    listTitle: 'Documents',
    listFields: [
      {
        name: 'fileName',
        header: 'File Name',
        type: 'link',
        path: `/meetings/${id}/documents`,
      },
      {
        name: 'description',
        header: 'Description',
      },
    ],
    addEndpoint: `${API_URL}/document/parent/${id}`,
    addTitle: 'Add Document',
    addFields: [
      {
        name: 'fileName',
        type: 'na',
        validation: {
          type: 'string',
        },
      },
      {
        name: 'fileType',
        type: 'na',
        validation: {
          type: 'string',
        },
      },
      {
        name: 'fileObject',
        type: 'na',
        validation: {
          type: 'object',
        },
      },
      {
        name: 'fileUrl',
        label: 'Select file',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
        },
        type: 'file-upload',
        region: 'eu-west-2',
        bucketName: 'dcsl-econnect-recordings',
      },
    ],
    updateEndpoint: `${API_URL}/meeting-note-item`,
    updateTitle: 'Edit Notes',
    updateFields: [
      {
        name: 'fileName',
        label: 'File Name',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
          minCharacters: 2,
        },
        type: 'text',
      },
    ],
  }

  return <ManageItem formObject={formObject} />
}
