import {useEffect, useState} from 'react'
import {createSearchParams, useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../../web-app-core'
import {useParams} from 'react-router-dom'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {
  AgoraVideoPlayer,
  createClient,
  createMicrophoneAndCameraTracks,
  createScreenVideoTrack,
  ClientConfig,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  ILocalTrack,
  ILocalVideoTrack,
  IRemoteVideoTrack,
  ILocalAudioTrack,
  ConnectionState,
} from 'agora-rtc-react'
import MediaPlayer from './MediaPlayer'
import {CreateAppModal} from '../../../../_metronic/partials'
import clsx from 'clsx'
// import {useLayout} from '../../../../_metronic/layout/user/core'
import {useLayout} from '../../../../_metronic/layout/core'
import ScreenShareMediaPlayer from './RemoteScreenShareMediaPlayer'
import RemoteScreenShareMediaPlayer from './RemoteScreenShareMediaPlayer'
import LocalScreenShareMediaPlayer from './LocalScreenShareMediaPlayer'
import axios from 'axios'
import {getToken2, startRecording, stopRecording} from '../../../../get-token'
import Swal from 'sweetalert2'
import NewWindow from 'react-new-window'
import uitoolkit from '@zoom/videosdk-ui-toolkit'
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css'
import MeetingVideoCall from './MeetingVideoCall'

// define config for rtc engine
const config: ClientConfig = {
  mode: 'rtc',
  codec: 'vp8',
}

const useClient = createClient(config)
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks()
const useScreenVideoTrack = createScreenVideoTrack({
  encoderConfig: '1080p_1',
  optimizationMode: 'detail',
})

const appId: string = '441c950a328d4cae9c951d799541a9ab' //ENTER APP ID HERE
const token: string | null = null

const API_URL = import.meta.env.VITE_APP_API_URL

export default function MeetingCall() {
  const navigate = useNavigate()
  const {id} = useParams()
  const {currentUser, auth} = useAuth()

  const [searchParams, setSearchParams] = useSearchParams()

  const callVideoCallId = searchParams.get('videoCallId') ?? auth?.user?.videoCallId
  const callUserId = searchParams.get('userId') ?? auth?.user?.id
  const callChannelId = searchParams.get('channelId') ?? id

  const [inCall, setInCall] = useState(false)
  const [inlineCall, setInlineCall] = useState(false)
  const [channelId, setChannelId] = useState(id as string)
  const getRandomCallerId = () => {
    return Math.floor(Math.random() * 1000000000).toString()
  }
  const location = useLocation();
  useEffect(() => {
    if (!searchParams.get('userId') && !auth?.user?.id) {
      // window.location.href = `${window.location.href}&userId=${getRandomCallerId()}`
      console.log('location', location)
      navigate({
        pathname: location.pathname,
        search: `${location.search}&${createSearchParams({
        userId: getRandomCallerId(),
    })}`
    })
      // navigate(`${window.location.href}&userId=${getRandomCallerId()}`)
    }
  }, [])

  useEffect(() => {
    if (!channelId) {
      setInlineCall(true)
    }
  }, [])

  const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * Math.floor(max))
  }

  return (
    callUserId && (
    <>
      {!inCall && (callUserId?.includes('123456') || inlineCall) ? (
        <>
          <MeetingVideoCall
                      channelId={channelId}
                    />
        </>
      ) : (
        <>
          <div className='card py-5'>
            <div className='card-body '>
              <h1 className='fw-bolder text-gray-900 mb-5'>Join meeting call</h1>

              <div className='mb-11'>
                <a
                  className='btn btn-primary'
                  href={`/meet?channelId=${callChannelId}&userId=${auth?.user?.id}&videoCallId=${auth?.user?.videoCallId}`}
                  target='_blank'
                  // onClick={(e) => {
                  //   // setInCall(!inCall)
                  //  // setInlineCall(!inlineCall)
                  //   navigate(`/meet?channelId=${callChannelId}&userId=${auth?.user?.id}&videoCallId=${auth?.user?.videoCallId}`)

                  // }}
                >
                  {`${inCall ? 'Leave' : 'Join'}`} Call
                </a>
                {inCall && (
                  <NewWindow>
                    <MeetingVideoCall
                      channelId={channelId}
                    />
                  </NewWindow>
                )}
              </div>

              <div className='card card-bordered'>
                <div className='card-body'>
                  <div className='mb-11'>
                    <h5>
                      Meeting Link:{' '}
                      {`${window.location.protocol}//${window.location.host}/meet?channelId=${callChannelId}`}
                    </h5>
                    <CopyToClipboard
                      onCopy={() => {
                        Swal.fire('Link Copied!', '', 'success')
                      }}
                      text={`${window.location.protocol}//${window.location.host}/meet?channelId=${callChannelId}`}
                    >
                      <button className='btn btn-light-dark btn-sm'>Copy Link</button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>

              <div className='mb-0'>
                <img
                  src='/metronic8/demo1/assets/media/auth/please-verify-your-email.png'
                  className='mw-100 mh-300px theme-light-show'
                  alt=''
                ></img>
                <img
                  src='/metronic8/demo1/assets/media/auth/please-verify-your-email-dark.png'
                  className='mw-100 mh-300px theme-dark-show'
                  alt=''
                ></img>
              </div>
            </div>
          </div>
        </>
      )}

      {/* <div className='h-125px'>
        <button
          className='btn btn-primary'
          onClick={(e) => {
            setInCall(!inCall)
          }}
        >
          {`${inCall ? 'Leave' : 'Join'}`} Call
        </button>
        {inCall && (
          <NewWindow>
            <VideoCall setInCall={setInCall} channelId={channelId} />
          </NewWindow>
        )}
      </div> */}
    </>)
  )
}

const VideoCall = (props: {
  setInCall: React.Dispatch<React.SetStateAction<boolean>>
  setInlineCall: React.Dispatch<React.SetStateAction<boolean>>
  channelId: string
}) => {
  const {setInCall, channelId, setInlineCall} = props
  const [users, setUsers] = useState<IAgoraRTCRemoteUser[]>([])
  const [callUsers, setCallUsers] = useState<any[]>([])
  const [start, setStart] = useState<boolean>(false)
  const [videoCallId, setVideoCallId] = useState<number>()
  const [remoteScreenShareUser, setRemoteScreenShareUser] = useState<IAgoraRTCRemoteUser>()
  const client = useClient()
  const {ready, tracks} = useMicrophoneAndCameraTracks()
  // const {ready: screenShareReady, tracks: screenShareTracks} = useScreenVideoTrack()
  const {currentUser, auth} = useAuth()
  const {id} = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const {classes} = useLayout()
  const [startScreenShare, setStartScreenShare] = useState<boolean>(false)
  const [isGettingSocketConnections, setIsGettingSocketConnections] = useState<boolean>(false)
  const [alreadyScreenSharing, setAlreadyScreenSharing] = useState<boolean>(false)
  const [trackState, setTrackState] = useState({video: true, audio: true})
  const [ws, setWs] = useState<WebSocket | undefined>()
  const [screenShareVideoCallId, setScreenShareVideoCallId] = useState<string | undefined>()
  const [socketConnections, setSocketConnections] = useState<Array<any>>()

  const [resourceId, setResourceId] = useState('')
  const [sid, setSid] = useState('')
  const [stopRecordingLoading, setStopRecordingLoading] = useState(false)
  const [downloadUrl, setDownloadUrl] = useState('')
  const [connectionTime, setConnectionTime] = useState('')
  const [recordingVideoCallId, setRecordingVideoCallId] = useState('123')
  const [activeVideoCallId, setActiveVideoCallId] = useState()
  const [activeCallUserId, setActiveCallUserId] = useState()
  const [forceMuteAudio, setForceMuteAudio] = useState<string | undefined>()

  console.log('auth?.user', auth?.user)
  let callVideoCallId = searchParams.get('videoCallId') ?? auth?.user?.videoCallId
  let callUserId = searchParams.get('userId') ?? auth?.user?.id
  const callChannelId = searchParams.get('channelId') ?? channelId
  const callName = searchParams.get('name') ?? auth?.user?.name
  const callTenantId = searchParams.get('tenantId') ?? auth?.user?.name
  const callInvite = searchParams.get('invite') ?? '0'

  useEffect(() => {
    if (callInvite !== '0') {
      callVideoCallId = Math.floor(Math.random() * 100000).toString()
      callUserId = Math.floor(Math.random() * 100000).toString()
    }
    console.log('tta 2')
    if (!callVideoCallId.includes('123456')) {
      callVideoCallId = `${callVideoCallId}2`
    }

    console.log('uid val param top', callVideoCallId)
    setActiveVideoCallId(callVideoCallId)
    setActiveCallUserId(callUserId)
  }, [])

  useEffect(() => {
    if (forceMuteAudio) {
      mute('audio', true)
      setForceMuteAudio(undefined)
    }
  }, [forceMuteAudio])

  console.log('callChannelId cid', callChannelId)

  // const GET_CONNECTIONS_URL = `${API_URL}/channel/${id}/socket-connections`
  const GET_CONNECTIONS_URL = `${API_URL}/channel/${callChannelId}/socket-connection`

  const recordingUid = searchParams.get('recordingUid')

  client.on('connection-state-change', (connection: ConnectionState) => {
    console.log('state connection', connection)
    if (recordingUid && connection == 'CONNECTED') {
      console.log('state connection 001', connection)
      try {
        client.unpublish()
      } catch (error: any) {
        console.log('state connection 002', error)
        Swal.fire({
          text: `Call Error: ${error?.message}`,
          icon: 'error',
          confirmButtonText: 'Okay',
        }).then(() => {})
      }
    }
  })

  const startMeetingRecording = async () => {
    setLoading(true)
    const uid = '123456' + (Math.floor(Math.random() * 100) + 1).toString()
    setRecordingVideoCallId(uid)
    const testResp = await getToken2(uid)
    // console.log("resourceid", testResp.data.resourceId);
    setResourceId(testResp.data.resourceId)

    const secondResp = await startRecording(
      testResp.data.resourceId,
      callChannelId as string,
      callTenantId as string,
      uid as string
    )
    console.log('startMeetingRecording secondResp', secondResp)
    setSid(secondResp.data.sid)
    // delay for 1 second
    await new Promise((resolve) => setTimeout(resolve, 12000))
    // call update socketConnectio
    const connections: Array<any> = await getSocketConnections()
    await new Promise((resolve) => setTimeout(resolve, 500))
    console.log('socketConnections', connections)
    const _socketConnection = connections?.find((a) => a.userId.includes('123456'))
    console.log('_socketConnection record', _socketConnection)
    if (_socketConnection) {
      try {
        const apiResponse = await axios.patch(
          `${API_URL}/socket-connection/${_socketConnection.id}`,
          {
            sid: secondResp.data.sid,
            resourceId: secondResp.data.resourceId,
          }
        )
        console.log('apiResponse', apiResponse)
      } catch (error) {
        console.log('apiResponse error', error)
      }
    }

    setLoading(false)
  }

  const saveMeetingRecordingFileUrl = async (fileUrl: string) => {
    return await axios.post(`${API_URL}/meeting/${callChannelId}/recording`, {
      name: 'Recording',
      fileUrl: fileUrl,
    })
  }

  const stopMeetingRecording = async () => {
    setStopRecordingLoading(true)
    setLoading(true)
    const allSocketConnections = await getSocketConnections()
    const videoRecordingUser = allSocketConnections?.find((a: any) => a.resourceId && a.sid)
    console.log('videoRecordingUser', videoRecordingUser)
    //  const apiResp = await stopRecording(resourceId, sid, recordingVideoCallId)
    const apiResp = await stopRecording(
      videoRecordingUser.resourceId,
      videoRecordingUser.sid,
      videoRecordingUser.videoCallId
    )
    console.log('stopMeetingRecording', apiResp)
    setSid('')
    await getSocketConnections()
    setStopRecordingLoading(false)
    if (apiResp?.data?.serverResponse?.extensionServiceState?.length) {
      var payloadResp = apiResp.data.serverResponse.extensionServiceState.find(
        (a: any) => a.serviceName === 'web_recorder_service'
      ).payload.fileList
      console.log('payloadResp', payloadResp)

      if (payloadResp && payloadResp.length !== 0) {
        const fileName = payloadResp.find((a: any) => a.filename.includes('mp4')).filename
        console.log('file name', fileName)

        console.log(
          'file name - full url',
          `https://dcsl-econnect-recordings.s3.eu-west-2.amazonaws.com/meetings/recordings/${fileName}`
        )
        setDownloadUrl(
          `https://dcsl-econnect-recordings.s3.eu-west-2.amazonaws.com/meetings/recordings/${fileName}`
        )
        try {
          const response = await saveMeetingRecordingFileUrl(
            `https://dcsl-econnect-recordings.s3.eu-west-2.amazonaws.com/meetings/recordings/${fileName}`
          )
          console.log('save vid response', response)
        } catch (error) {
          console.log('save vid error', error)
        }
      } else {
        Swal.fire({
          text: `Error saving recording`,
          icon: 'error',
          confirmButtonText: 'Okay',
        }).then(() => {})
      }
    }

    // save this to

    setResourceId('resource')
    setLoading(false)
  }

  useEffect(() => {
    console.log('useEffect users, screenShareVideoCallId', users, screenShareVideoCallId)

    console.log('useEffect screenShareVideoCallId,users', screenShareVideoCallId, users)

    if (screenShareVideoCallId) {
      var screenShareUser = users.find((user) => user?.uid === parseInt(screenShareVideoCallId))
      if (screenShareUser) {
        setRemoteScreenShareUser(screenShareUser)
      }
    } else if (remoteScreenShareUser) {
      console.log('useEffect updating user list', remoteScreenShareUser)

      setRemoteScreenShareUser(undefined)
    }

    console.log('useEffect screenShareUser', screenShareUser)
    console.log('useEffect remoteScreenShareUser', remoteScreenShareUser)
    // setStartScreenShare(!!remoteScreenShareUser)
    // setScreenShareVideoCallId(!remoteScreenShareUser? undefined : screenShareVideoCallId)
  }, [users, screenShareVideoCallId])

  const getSocketConnections = async () => {
    try {
      setIsGettingSocketConnections(true)
      // delay for 1 second
      await new Promise((resolve) => setTimeout(resolve, 1000))

      const response = await axios.get(GET_CONNECTIONS_URL)
      console.log('getSocketConnections response', response.data.data)
      setSocketConnections(response.data.data)
      const screenShareUser = response.data.data?.find(
        (connection: any) => connection.status === 'SharingScreen'
      )
      console.log('getSocketConnections screenShareUser', screenShareUser)

      if (screenShareUser && screenShareUser.videoCallId !== auth?.user?.videoCallId) {
        console.log('getSocketConnections setScreenShareVideoCallId', screenShareUser)

        setScreenShareVideoCallId(screenShareUser.videoCallId)
        setAlreadyScreenSharing(true)
      }
      setIsGettingSocketConnections(false)
      return response.data.data
    } catch (error) {
      console.log('getSocketConnections error', error)
      setIsGettingSocketConnections(false)
    }
  }

  useEffect(() => {
    getSocketConnections()
  }, [users])

  useEffect(() => {
    if (!socketConnections?.find((a) => a.userId === auth?.user?.id)) {
      connectToWS()
    }
  }, [socketConnections])

  useEffect(() => {
    if (connectionTime) {
      console.log('ws reconnection')

      if (start) {
        console.log('useEffect connectionTime', connectionTime)
        setTimeout(function () {
          connectToWS()
        }, 1000)
      }
    }
  }, [connectionTime])

  // send a message to websocket server and wait for response containing an acknowlegement of the message action
  const sendWsMessage = (message: any) => {
    console.log('sendWsMessage message', message)
    if (ws == null) {
      return
    }

    if (!message?.data?.channelId) {
      message.data.channelId = callChannelId
    }

    ws.onmessage = (event) => {
      const messageObject = JSON.parse(event.data)
      if (messageObject.action === message.action) {
        console.log('sendWsMessage ws onmessage:' + messageObject.action, event)
        console.log('sendWsMessage clearInterval', messageObject)
        clearInterval(sendFunctionInterval)
        setWsEventHandlers(ws)
      }
    }
    const sendFunctionInterval = setInterval(() => {
      console.log('sendWsMessage sendFunctionInterval', message)

      try {
        ws?.send(JSON.stringify(message))
      } catch (error) {
        console.log('sendWsMessage sendFunctionInterval error', error)
        clearInterval(sendFunctionInterval)
      }
    }, 1000)
  }

  const processWsMessage = async (messageObject: any) => {
    console.log('processWsMessage data', messageObject)

    switch (messageObject.action) {
      case 'screen-share-started':
        console.log('processWsMessage data', messageObject)
        setScreenShareVideoCallId(messageObject.data.videoCallId)
        break
      case 'screen-share-stopped':
        console.log('processWsMessage screen-share-stopped', messageObject)
        console.log('processWsMessage remoteScreenShareUser', remoteScreenShareUser)
        // setUsers((prevUsers) => {
        //   return [...prevUsers.filter((a) => a?.uid !== screenShareVideoCallId), remoteScreenShareUser as IAgoraRTCRemoteUser]
        // })
        setRemoteScreenShareUser(undefined)

        setScreenShareVideoCallId(undefined)

        break
      case 'mute-audio':
        console.log('processWsMessage mute-audio', messageObject)
        console.log('processWsMessage mute-audio', callUserId)

        if (messageObject.data.userId === callUserId) {
          console.log('processWsMessage mute-audio mute', callUserId)
          setForceMuteAudio(new Date().getTime().toString())
        }
        break
      case 'mute-audio-all':
        console.log('processWsMessage mute-audio-all', messageObject)
        mute('audio')
        break
      case 'remove-user':
        console.log('processWsMessage remove-user', messageObject)
        if (messageObject.data.userId === callUserId) {
          leaveChannel().then(() => {
            setInCall(false)
            setInlineCall(false)
          })
        }
        break

      default:
        break
    }
  }

  // useEffect(() => {
  //   if (test.action === 'screen-share-started') {
  //     console.log('ws message 2', e.data.action)
  //     setStartScreenShare(true)
  //     var screenShareUser = users.find((user) => user.uid === e.data.videoCallId)
  //     setRemoteScreenShareUser(screenShareUser)
  //   } else if (e.data.action === 'screen-share-stopped') {
  //     setStartScreenShare(true)
  //     setRemoteScreenShareUser(undefined)
  //   }
  // }, [wsMessage])
  //let ws: WebSocket | undefined = undefined

  const setWsEventHandlers = (ws: WebSocket) => {
    ws.onopen = () => {
      // Connection opened
      console.log('WebSocket connection opened')

      console.log('WebSocket connection opened after send')
      //ws.send('Hello, server!'); // Send a message to the server
    }

    ws.onmessage = (e) => {
      // Receive a message from the server
      console.log('ws message data', e.data)
      console.log('ws message data parse', JSON.parse(e.data))
      const messageObject = JSON.parse(e.data)
      if (
        messageObject.action === 'screen-share-started' ||
        messageObject.action === 'screen-share-stopped' ||
        messageObject.action === 'mute-audio' ||
        messageObject.action === 'mute-audio-all' ||
        messageObject.action === 'remove-user'
      ) {
        processWsMessage(JSON.parse(e.data))
          .then(() => {
            console.log('processWsMessage done')
          })
          .catch((err) => {
            console.log('processWsMessage err', err)
          })
      }
    }
    ws.onerror = (e) => {
      // An error occurred
      console.log('ws error', e)
      ws.close()
    }
    ws.onclose = (e) => {
      // Connection closed
      console.log('ws disconnected', e)
      console.log(e.code, e.reason)

      setConnectionTime(new Date().toString())
      // if(start){

      //   // setTimeout(function () {
      //   //   connectToWS()
      //   // }, 1000)
      // }
    }
  }

  const connectToWS = () => {
    console.log('WebSocket connection', activeVideoCallId)
    if (
      (ws && (ws.readyState === ws.OPEN || ws.readyState === ws.CONNECTING)) ||
      !activeVideoCallId
    ) {
      return
    }

    const webSocket = new WebSocket(
      `${
        import.meta.env.REACT_APP_WEBSOCKET_API_URL
      }/v1?userId=${activeCallUserId}&channelId=${callChannelId}&videoCallId=${activeVideoCallId}&name=${callName}&mode=call`
    )
    setWsEventHandlers(webSocket)
    setWs(webSocket)
  }

  useEffect(() => {
    if (activeVideoCallId) {
      console.log('useEffect activeVideoCallId', activeVideoCallId)
      connectToWS()
    }
  }, [activeVideoCallId])

  useEffect(() => {
    // function to initialise the SDK
    let init = async (name: string) => {
      console.log('init', name)
      client.on('user-published', async (user, mediaType) => {
        console.log('subscribe success 0', user, mediaType)

        await client.subscribe(user, mediaType)
        client.enableAudioVolumeIndicator()
        console.log('subscribe success 1', user, mediaType)
        if (user?.uid === '123456') return

        if (mediaType === 'video') {
          if (users.find((User) => User.uid === user.uid)) {
            return
          }

          const socketUser = socketConnections?.find((a) => Number(a.videoCallId) === user.uid)
          console.log('subscribe success socketUser', socketUser)

          if (socketUser) {
            socketUser.videoOn = true
            setSocketConnections((prevSocketConnections) => {
              if (prevSocketConnections) {
                return [
                  ...prevSocketConnections.filter((a) => a.videoCallId !== user.uid),
                  socketUser,
                ]
              }
            })
          } else {
            if (!isGettingSocketConnections) {
              await new Promise((resolve) => setTimeout(resolve, 500))
              await getSocketConnections()
            }
          }
        }
        if (mediaType === 'audio') {
          console.log('socketUser audio On', user.uid)

          user.audioTrack?.play()
        }

        setUsers((prevUsers) => {
          return [...prevUsers.filter((a) => a.uid !== user.uid), user]
        })

        setCallUsers((prevUsers) => {
          console.log('setCallUsers prevUsers,callUsers', prevUsers, callUsers)
          const newCallUser: any = prevUsers.find((callUser) => callUser?.uid === user.uid) ?? {
            uid: user.uid,
          }
          console.log('setCallUsers newCallUser 0', newCallUser)
          if (mediaType === 'video') {
            newCallUser.videoOn = true
            setUsers((prevUsers) => {
              return [...prevUsers.filter((a) => a.uid !== user.uid)]
            })
            // delay for 1 second
            setTimeout(() => {
              setUsers((prevUsers) => {
                return [...prevUsers.filter((a) => a.uid !== user.uid), user]
              })
            }, 5)
            // if(newCallUser.audioOn) {

            // } else {
            //   setUsers((prevUsers) => {
            //     return [...prevUsers.filter((a) => a.uid !== user.uid), user]
            //   })
            // }
          } else if (mediaType === 'audio') newCallUser.audioOn = true
          console.log('newCallUser', newCallUser)
          return [...prevUsers.filter((a) => a.uid !== user.uid), newCallUser]
        })
      })

      client.on('user-unpublished', (user, type) => {
        console.log('unpublished', user, type)

        const callUser = callUsers.find((User) => User.uid === user.uid)
        console.log('unpublished callUsers', callUsers)
        console.log('unpublished callUser', callUser)

        if (type === 'audio') {
          user.audioTrack?.stop()
          setCallUsers((prevUsers) => {
            const callUser = prevUsers.find((User) => User.uid === user.uid)
            if (!callUser?.videoOn) return prevUsers.filter((User) => User.uid !== user.uid)
            else
              return [...prevUsers.filter((a) => a.uid !== user.uid), {...callUser, audioOn: false}]
          })
          setUsers((prevUsers) => {
            const callUser = prevUsers.find((User) => User.uid === user.uid)
            if (!callUser?.hasVideo) return prevUsers.filter((User) => User.uid !== user.uid)
            else return [...prevUsers.filter((a) => a.uid !== user.uid), user]
          })
        }
        if (type === 'video') {
          console.log('unpublished video', user, type, callUser)
          setCallUsers((prevUsers) => {
            const callUser = prevUsers.find((User) => User.uid === user.uid)
            console.log('unpublished video callUser', callUser)
            if (!callUser?.audioOn) return prevUsers.filter((User) => User.uid !== user.uid)
            else
              return [...prevUsers.filter((a) => a.uid !== user.uid), {...callUser, videoOn: false}]
          })
          setUsers((prevUsers) => {
            const callUser = prevUsers.find((User) => User.uid === user.uid)
            if (!callUser?.hasAudio) return prevUsers.filter((User) => User.uid !== user.uid)
            else return prevUsers
          })
          // setUsers((prevUsers) => {
          //   return prevUsers.filter((User) => User.uid !== user.uid)
          // })
          const socketUser = socketConnections?.find((a) => Number(a.videoCallId) === user.uid)
          if (socketUser) {
            socketUser.videoOn = false
            setSocketConnections((prevSocketConnections) => {
              if (prevSocketConnections) {
                return [
                  ...prevSocketConnections.filter((a) => a.videoCallId !== user.uid),
                  socketUser,
                ]
              }
            })
          }
        }
      })

      client.on('user-left', async (user) => {
        console.log('leaving', user)
        // sendWsMessage({
        //   action: 'user-left-call',
        //   data: {
        //     channelId: callChannelId,
        //     userId: socketConnections?.find((a) => Number(a.videoCallId) === user.uid)?.userId,
        //     videoCallId: user.uid,
        //   },
        // }) // Send a message to the server
        // delay for 1 second
        await new Promise((resolve) => setTimeout(resolve, 2000))
        await getSocketConnections()

        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid)
        })
      })

      client.on('user-joined', async (user) => {
        const socketUser = socketConnections?.find((a) => a.videoCallId === user.uid)

        if (!socketUser && !isGettingSocketConnections) {
          await new Promise((resolve) => setTimeout(resolve, 1000))
          await getSocketConnections()
        }
        // client.enableAudioVolumeIndicator()
        await client.join(appId, name, token, Number(activeVideoCallId))
        // alert('user-joined')
      })

      console.log('uid val before join', activeVideoCallId)
      console.log('uid val before join type', typeof activeVideoCallId)
      const userUid = await client.join(appId, name, token, Number(activeVideoCallId))
      console.log('uid val after join', userUid)

      setVideoCallId(userUid as number)

      // if (tracks) await client.publish([tracks[0], screenShareTracks as ILocalTrack,]);
      if (callUserId.includes('123456')) {
      } else if (tracks) await client.publish([tracks[0], tracks[1]])
      // await mute('video')
      await mute('video')
      await mute('audio')
      await getSocketConnections()

      setStart(true)
    }

    if (ready && tracks && activeVideoCallId) {
      console.log('init ready')
      init(callChannelId)
    }

    return () => {
      leaveChannel()
    }
  }, [callChannelId, client, ready, tracks, activeVideoCallId])

  const mute = async (type: 'audio' | 'video', onlyOff: boolean = false) => {
    if (tracks === null) return

    if (type === 'audio') {
      if (onlyOff) {
        await tracks[0].setEnabled(false)
        setTrackState((ps) => {
          return {...ps, audio: false}
        })
      } else {
        await tracks[0].setEnabled(!trackState.audio)
        setTrackState((ps) => {
          return {...ps, audio: !ps.audio}
        })
      }
    } else if (type === 'video') {
      await tracks[1].setEnabled(!trackState.video)

      // if(!trackState.video){

      //   if (client.connectionState === 'CONNECTED') {
      //     await client.publish(tracks)
      //   } else {
      //     //client.join(appId, callChannelId, token, Number(callVideoCallId))
      //   }
      //   // await client.publish(
      //   //   [tracks[0], tracks[1]].filter((track) => track.enabled)
      //   // )
      // }

      setTrackState((ps) => {
        return {...ps, video: !ps.video}
      })
    }
  }

  const leaveChannel = async () => {
    if (tracks === null) return

    await client.leave()
    client.removeAllListeners()
    tracks[0].close()
    tracks[1].close()
    setStart(false)
    setInCall(false)
    ws?.close()
  }

  useEffect(() => {
    if (callInvite !== '0' && !searchParams.get('name')) {
      Swal.fire({
        title: 'Please enter your name to join the call.',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: false,
        confirmButtonText: 'Continue',
        showLoaderOnConfirm: true,
        preConfirm: (name) => {
          return name
        },
        allowOutsideClick: false,
      }).then((result) => {
        console.log('result', result)
        if (result.isConfirmed) {
          window.location.replace(`${window.location.href}&name=${result.value}`)
        }
      })
      console.log('tta 1')
      return
    }
  }, [])

  return (
    <div className='App card card-body'>
      <>
        {callUserId !== '---' && (
          <div className=' py-6 mb-0 bg-white' id='kt_toolbar'>
            {/* begin::Container */}
            <div
              id='kt_toolbar_container'
              className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack container')}
            >
              <h3>
                Call Meeting{' '}
                {client.remoteUsers && client.remoteUsers?.length > 0
                  ? `(${
                      client.remoteUsers.filter((s) => !s.uid.toString().includes('123456'))
                        ?.length + 1
                    } Users)`
                  : ''}
              </h3>
              {start && tracks && (
                <>
                  {/* begin::Actions */}
                  <div className='d-flex align-items-center py-1'>
                    <button
                      onClick={() =>
                        leaveChannel().then(() => {
                          setInCall(false)
                          setInlineCall(false)
                          // window.open('about:blank', '_self')
                          // window.close()
                        })
                      }
                      className='btn btn-danger btn-sm fs-7 me-3 py-2'
                    >
                      Leave Call
                    </button>
                    {/* <button
                  onClick={() =>
                    {
                      setInlineCall(false)
                      setInCall(true)

                    }
                  }
                  className='btn btn-default btn-sm fs-7 me-3 py-2'
                >
                  Pop
                </button> */}
                    {/* 
            <button className='border-danger btn btn-outline btn-outline-danger btn-sm fs-7 me-3 py-2'>
              <i className='fa fa-record-vinyl fs-4 me-1'></i>
              Record Call
            </button> */}
                    {socketConnections?.find((a) => a.videoCallId.includes('123456')) ? (
                      <>
                        {auth?.user?.userRole === 'Admin' && (
                          <button
                            className='border-default btn btn-outline btn-outline-default btn-sm fs-7 me-3 py-2'
                            onClick={async () => {
                              if (!stopRecordingLoading) {
                                await stopMeetingRecording()
                                setStopRecordingLoading(true)
                                sendWsMessage({
                                  action: 'stop-recording',
                                  data: {
                                    channelId: callChannelId,
                                    userId: callUserId,
                                    videoCallId: callVideoCallId,
                                  },
                                }) // Send a message to the server
                                await new Promise((resolve) => setTimeout(resolve, 3000))
                                await getSocketConnections()
                                setStopRecordingLoading(true)
                                setLoading(false)
                              }
                            }}
                          >
                            {stopRecordingLoading ? (
                              <div className='me-2 spinner-border spinner-border-sm' role='status'>
                                <span className='sr-only'>Loading...</span>
                              </div>
                            ) : null}
                            Stop Recording
                          </button>
                        )}

                        <button
                          className='border-danger btn btn-outline btn-outline-danger btn-sm fs-7 me-3 py-2'
                          disabled={true}
                          onClick={async () => {}}
                        >
                          <i className={`fa fa-record-vinyl fs-4 me-1 fa-fade`}></i>
                          {'Recording in Progress'}
                        </button>
                        {/* <button
                          className='border-default btn btn-outline btn-outline-default btn-sm fs-7 me-3 py-2'
                          disabled={false}
                          onClick={async () => {
                            sendWsMessage({
                              action: 'remove-user',
                              data: {
                                channelId: callChannelId,
                                userId: '12345665',
                                videoCallId: '12345665',
                                },
                              })
                          }}
                        >
                          <i className={`fa fa-record-vinyl fs-4 me-1 fa-fade`}></i>
                          {'Remove User'}
                        </button> */}
                      </>
                    ) : auth?.user?.userRole === 'Admin' ? (
                      <button
                        className='border-default btn btn-outline btn-outline-default btn-sm fs-7 me-3 py-2'
                        disabled={
                          loading ||
                          socketConnections?.find((a) => a.videoCallId.includes('123456'))
                        }
                        onClick={async () => {
                          await startMeetingRecording()
                        }}
                      >
                        <i className={`fa fa-record-vinyl fs-4 me-1`}></i>
                        {'Start Recording'}
                        {loading && <i className='fa fa-spinner fa-spin'></i>}
                      </button>
                    ) : (
                      <></>
                    )}

                    {downloadUrl ? (
                      <button
                        className='border-danger btn btn-outline btn-outline-danger btn-sm fs-7 me-3 py-2'
                        onClick={async () => {
                          window.open(downloadUrl, '_blank')
                        }}
                      >
                        Download Video
                      </button>
                    ) : null}
                    <button
                      onClick={() => mute('audio')}
                      className='btn btn-white btn-icon btn-outline btn-sm fs-7 me-3 py-2'
                    >
                      <i
                        className={`fa fa-microphone${trackState.audio ? '' : '-slash'} fs-4 me-1`}
                      ></i>
                    </button>
                    <button
                      onClick={() => mute('video')}
                      className='btn btn-white btn-icon btn-outline btn-sm fs-7 me-3 py-2'
                    >
                      <i className={`fa fa-video${trackState.video ? '' : '-slash'} fs-4 me-1`}></i>
                    </button>
                    {ws && (
                      <button
                        onClick={async () => {
                          setLoading(true)
                          if (startScreenShare) {
                            sendWsMessage({
                              action: 'screen-share-stopped',
                              data: {
                                channelId: callChannelId,
                                userId: callUserId,
                                videoCallId: callVideoCallId,
                              },
                            }) // Send a message to the server
                            // delay for 1 second
                            await new Promise((resolve) => setTimeout(resolve, 3000))

                            console.log('screenShare onClick stop screen share')
                            console.log('screenShare onClick tracks', tracks)
                            if (tracks) {
                              await client.unpublish()
                              const videoEnabled = tracks[1].enabled
                              const audioEnabled = tracks[0].enabled
                              console.log(
                                'screenShare onClick videoEnabled,audioEnabled',
                                videoEnabled,
                                audioEnabled
                              )
                              // await mute('video', false)
                              // await mute('audio', false)
                              await tracks[0].setEnabled(true)
                              await tracks[1].setEnabled(true)
                              const trackList = [tracks[0], tracks[1]]
                              // .filter(
                              //   (track) => track.enabled
                              // )
                              if (trackList.length > 0) {
                                await client.publish(trackList)
                                // delay for 1 second
                                //   await new Promise((resolve) => setTimeout(resolve, 3000))
                                await tracks[0].setEnabled(audioEnabled)
                                await tracks[1].setEnabled(videoEnabled)
                                // await mute('video', true)
                                // await mute('audio', true)
                              }
                            }
                          } else {
                            console.log('screenShare onClick start screen share')
                          }
                          setStartScreenShare(!startScreenShare)
                          setLoading(false)
                        }}
                        className='btn btn-primary btn-sm fs-7 me-3 py-2'
                        disabled={loading && !screenShareVideoCallId}
                      >
                        {`${startScreenShare ? 'Stop Sharing Screen' : 'Share Screen'}`}
                        {loading && <i className='fa fa-spinner fa-spin'></i>}
                      </button>
                    )}
                  </div>
                  {/* end::Actions */}
                </>
              )}
            </div>
            {/* end::Container */}
          </div>
        )}

        <CreateAppModal
          show={showCreateAppModal}
          handleClose={() => setShowCreateAppModal(false)}
        />
      </>
      {start && tracks && (
        <>
          <Videos
            ws={ws as WebSocket}
            remoteScreenShareUser={remoteScreenShareUser}
            startScreenShare={startScreenShare}
            client={client}
            users={users}
            tracks={tracks}
            videoCallId={videoCallId as number}
            socketConnections={socketConnections as Array<any>}
            screenShareVideoCallId={screenShareVideoCallId}
            sendWsMessage={sendWsMessage}
            callUsers={callUsers}
          />
        </>
      )}
      {!start && !tracks && (
        <>
          <div
            className='mx-auto spinner-border spinner-border-sm w-35px h-35px text-center'
            role='status'
          >
            <span className='sr-only'>Loading...</span>
          </div>
          <h2 className='pb-20 pt-10 text-center'>Loading call...</h2>
        </>
      )}
    </div>
  )
}

const Videos = (props: {
  users: IAgoraRTCRemoteUser[]
  tracks: [IMicrophoneAudioTrack, ICameraVideoTrack]
  client: any
  remoteScreenShareUser: IAgoraRTCRemoteUser | undefined
  startScreenShare: boolean
  ws: WebSocket
  videoCallId: number
  socketConnections: Array<any>
  screenShareVideoCallId: string | undefined
  sendWsMessage: Function
  callUsers: Array<any>
}) => {
  const {
    users,
    tracks,
    client,
    remoteScreenShareUser,
    startScreenShare,
    videoCallId,
    socketConnections,
    screenShareVideoCallId,
    callUsers,
  } = props
  const [remoteUsers, setRemoteUsers] = useState(users)
  const [localCallUsers, setLocalCallUsers] = useState(callUsers)
  const [screenShareSocketUser, setScreenShareSocketUser] = useState<any | undefined>()
  const [socketConnections2, setSocketConnections2] = useState(socketConnections)
  const {auth} = useAuth()

  const onlyUnique = (value: any, index: number, array: Array<any>) => {
    return array.findIndex((a) => a.videoCallId == value.videoCallId) === index
  }

  useEffect(() => {
    setLocalCallUsers(JSON.parse(JSON.stringify(callUsers)))
    console.log('Videos callUsers changed', callUsers)
  }, [JSON.stringify(callUsers)])

  useEffect(() => {
    setRemoteUsers(users)
  }, [users])

  useEffect(() => {
    console.log('Videos users changed', users, callUsers)
    setLocalCallUsers(JSON.parse(JSON.stringify(callUsers)))
    // commented to prevent flickering
    // setRemoteUsers([])
    // setSocketConnections2([])
    setSocketConnections2([...socketConnections])
    setScreenShareSocketUser(socketConnections.find((a) => a.status === 'SharingScreen'))

    // // 1 second delay
    // setTimeout(() => {
    //   setRemoteUsers([...users])
    //   setSocketConnections2([...socketConnections])
    //   const _screenShareSocketUser = socketConnections.find((a) => a.status === 'SharingScreen')
    //   setScreenShareSocketUser(_screenShareSocketUser)
    // }, 5)
  }, [users, tracks, JSON.stringify(socketConnections), screenShareVideoCallId])

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          {((screenShareSocketUser && Number(screenShareSocketUser?.videoCallId) !== videoCallId) ||
            (screenShareVideoCallId && !startScreenShare && remoteScreenShareUser?.hasVideo)) &&
            screenShareVideoCallId && (
              <RemoteScreenShareMediaPlayer
                user={
                  socketConnections2.find((a) => a.status === 'SharingScreen') ??
                  socketConnections?.find((a) => a.videoCallId == remoteScreenShareUser?.uid)
                }
                client={client}
                trackType='local'
                videoTrack={
                  remoteUsers.find(
                    (a) =>
                      a.uid ===
                      Number(
                        screenShareVideoCallId ??
                          socketConnections2.find((a) => a.status === 'SharingScreen')?.videoCallId
                      )
                  )?.videoTrack
                }
                audioTrack={remoteScreenShareUser?.audioTrack}
                screenShare={true}
                ws={props.ws}
                videoCallId={videoCallId}
                agoraRemoteUser={remoteScreenShareUser}
              ></RemoteScreenShareMediaPlayer>
            )}
          {startScreenShare && (
            <LocalScreenShareMediaPlayer
              user={auth?.user}
              localAudioTrack={tracks[0]}
              localVideoTrack={tracks.length > 1 ? tracks[1] : undefined}
              client={client}
              trackType='local'
              screenShare={true}
              ws={props.ws}
              videoCallId={videoCallId}
            ></LocalScreenShareMediaPlayer>
          )}
        </div>
      </div>
      <div className=' row' id='videos'>
        {!videoCallId?.toString()?.includes('123456') && (
          <MediaPlayer
            user={{name: 'Me', videoCallId: videoCallId}}
            client={client}
            trackType='local'
            videoTrack={true ? tracks[1] : tracks[1]}
            audioTrack={undefined}
            screenShare={startScreenShare}
            userCount={socketConnections2.length}
            sendWsMessage={props.sendWsMessage}
            userTrackState={undefined}
            videoOn={true}
            callUser={{videoOn: true, audioOn: true, uid: videoCallId}}
          ></MediaPlayer>
        )}

        {/* <AgoraVideoPlayer className='vid rounded overflow-hidden bg-danger col' videoTrack={tracks[1]} style={{height: '95%', width: '95%'}} /> */}
        {remoteUsers &&
          socketConnections2.length > 0 &&
          remoteUsers
            //.filter(onlyUnique)
            .filter((a) => Number(a.uid) !== Number(screenShareVideoCallId))
            // .filter((a) => !screenShareVideoCallId || a.status !== 'SharingScreen')
            .filter((a) => Number(a.uid) !== videoCallId)
            .filter((a) => !a.uid.toString().includes('123456'))
            .filter((a) => localCallUsers.find((r: any) => Number(r.uid) === Number(a.uid)))
            .map((_remoteUser) => {
              console.log('Videos user', _remoteUser)

              // const _remoteUser = remoteUsers.find((a:any)=>a.uid === Number(user.uid))
              const user = socketConnections2.find((a) => Number(a.videoCallId) === _remoteUser.uid)
              const _localUser = localCallUsers.find((a: any) => a.uid === Number(_remoteUser?.uid))
              console.log('Videos _remoteUser', _remoteUser)
              return (
                <MediaPlayer
                  user={user}
                  client={client}
                  agoraRemoteUser={_remoteUser}
                  trackType='remote'
                  videoTrack={_remoteUser?.videoTrack}
                  audioTrack={_remoteUser?.audioTrack}
                  screenShare={startScreenShare}
                  userCount={socketConnections2.length}
                  sendWsMessage={props.sendWsMessage}
                  userTrackState={'active'}
                  audioOn={_remoteUser?.hasAudio}
                  videoOn={_localUser?.videoOn}
                  callUser={_localUser}
                  authUser={auth?.user}
                  //   screenshareSocketUser={screenShareSocketUser}
                ></MediaPlayer>
                // <div className=' col'>
                // <span>{user.hasAudio.toString()}hjh</span>
                // <AgoraVideoPlayer className='vid' videoTrack={user.videoTrack} style={{height: '95%', width: '95%'}} key={user.uid} />

                // </div>
              )
            })}
        {/* <>
            <h1>R:{remoteUsers?.length} - L:{localCallUsers.length}</h1>
            </> */}
        {socketConnections2 &&
          socketConnections2.length > 0 &&
          socketConnections2
            .filter(onlyUnique)
            .filter((a) => Number(a.videoCallId) !== Number(screenShareVideoCallId))
            // .filter((a) => !screenShareVideoCallId || a.status !== 'SharingScreen')
            .filter((a) => Number(a.videoCallId) !== videoCallId)
            .filter((a) => !a.videoCallId.includes('123456'))
            .filter(
              (a) =>
                !localCallUsers.find((r: any) => Number(r.uid) === Number(a.videoCallId)) ||
                !remoteUsers?.find((r: any) => Number(r.uid) === Number(a.videoCallId))
            )
            .map((user) => {
              console.log('Videos user', user)

              const _remoteUser = remoteUsers.find((a: any) => a.uid === Number(user.videoCallId))
              console.log('Videos _remoteUser', _remoteUser)
              return (
                <MediaPlayer
                  user={user}
                  client={client}
                  agoraRemoteUser={_remoteUser}
                  trackType='remote'
                  videoTrack={_remoteUser?.videoTrack}
                  audioTrack={_remoteUser?.audioTrack}
                  screenShare={startScreenShare}
                  userCount={socketConnections2.length}
                  sendWsMessage={props.sendWsMessage}
                  userTrackState={'passive'}
                  audioOn={false}
                  videoOn={false}
                  authUser={auth?.user}
                ></MediaPlayer>

                // <div className=' col'>
                // <span>{user.hasAudio.toString()}hjh</span>
                // <AgoraVideoPlayer className='vid' videoTrack={user.videoTrack} style={{height: '95%', width: '95%'}} key={user.uid} />

                // </div>
              )
            })}
      </div>
    </div>
  )
}
