import { createContext, useContext } from "react";

let initAuthContextPropsState = {} as any;
// Create a context for the dynamic screen data
const DynamicScreenContext = createContext<any>(null);

const useDynamicScreenContext = () => {
  return useContext(DynamicScreenContext);
};

export { DynamicScreenContext, useDynamicScreenContext };
