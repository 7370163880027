/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate, Outlet} from 'react-router-dom'
import {ErrorsPage} from '../../modules/errors/ErrorsPage'
import {GeneralPages} from '../../modules/general/GeneralPages'
import {UserPrivateRoutes} from './UserPrivateRoutes'
import {AdminApp} from '../../AdminApp'
import {AuthPage, Logout, useAuth} from '../../../web-app-core'
import MeetingCall from '../../modules/meetings/components/MeetingCall'
import SelectCompany from '../../modules/companies/SelectCompany'
import Companies from '../../modules/companies/Companies'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
import {API_URL} from '../../core/_requests'

const UserAppRoutes: FC = () => {
  const {currentUser, auth} = useAuth()
  console.log('AdminAppRoutes auth', auth)
  console.log('AdminAppRoutes currentUser', currentUser)

  const userType = 'user'

  const loginFormObject = {
    addTitle: 'Sign In',
    description: 'All-in-one board management platform.',
    helpText: {
      title: 'Forgot password ?',
      redirectUrl: `/${userType}/auth/forgot-password`,
    },
    addEndpoint: `${API_URL}/${userType}/account/login`,
    formFields: [
      {
        name: 'emailAddress',
        label: 'Email Address',
        placeholder: 'Email Address',
        validation: {
          required: true,
          email: true,
          message: 'Wrong email format',
        },
        type: 'text',
      },
      {
        name: 'password',
        label: 'Password',
        placeholder: 'Password',
        validation: {
          required: true,
          min: 3,
          message: 'Minimum 3 characters',
        },
        type: 'password',
      },
    ],
    userStatus: 'AccountCreated',
    dashboardUrl: `/${userType}/meetings`,
    errorUrl: `/${userType}/auth/registration/confirm`,
    forgotPasswordUrl: `/${userType}/auth/forgot-password`,
    userExistUrl: `/${userType}/auth/registration/confirm`,
  }

  return (
    <Routes>
      <Route element={<AdminApp />}>
        <Route path='error/*' element={<ErrorsPage />} />
        <Route path='logout' element={<Logout />} />
        <Route path='meet' element={<MeetingCall />} />
        <Route path='user/*' element={<UserPrivateRoutes />} />
        {auth ? (
          <>
            {/* Talent Pages */}
            <Route path='companies' element={<Companies />} />

            <Route path='*' element={<UserPrivateRoutes />} />
            <Route path='select-company/:id*' element={<SelectCompany />} />
            <Route index element={<Navigate to='companies' />} />
          </>
        ) : (
          <>
            {/* Talent User Not Authenticated */}
            <Route
              path='user/auth/*'
              element={
                <AuthPage
                  userType='user'
                  loginFormObject={loginFormObject}
                  bgUrl='media/auth/board_room_image.jpg'
                />
              }
            />
            <Route path='*' element={<Navigate to='user/auth' />} />
          </>
        )}
      </Route>
    </Routes>
  )
}

export {UserAppRoutes}
