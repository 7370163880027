// Main component to render the screen based on JSON data

import {useEffect, useState} from 'react'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import AddUpdateItemModal from './AddUpdateItemModal'

const ButtonWithAddUpdateModal = ({layoutData, handleSaved}: any) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <button className='btn btn-primary btn-sm' onClick={() => setShowModal(true)}>
        {layoutData.buttonText}
      </button>
      {showModal && (
        <AddUpdateItemModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          handleSaved={() => handleSaved()}
          formObject={layoutData}
        />
      )}
    </>
  )
}

export default ButtonWithAddUpdateModal
