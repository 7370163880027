/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes, Link} from 'react-router-dom'

import {EmailVerification, ForgotPassword, NewPassword, SignUp, Login} from '../'
import AcceptInvite from '../components/organisms/AcceptInvite'
import {toAbsoluteUrl} from '../helpers'

const AuthLayout = ({bgUrl}: any) => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-root' id='kt_app_root'>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        {/* begin::Content */}
        {/* begin::Aside */}
        <div className='d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10'>
          {/* begin::Wrapper */}
          <div className='d-flex justify-content-center flex-column-fluid flex-column w-100 mw-450px'>
            {/* begin::Wrapper */}
            <Outlet />
            {/* end::Wrapper */}
            <div className='d-flex justify-content-center gap-5 fs-base fw-semibold '>
              <Link to='#'>Terms</Link>
              <Link to='#'>Privacy Policy</Link>
              <Link to='#'>Contact Us</Link>
            </div>
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Aside */}
        {/* begin::Body */}
        <div
          className='d-none d-lg-flex flex-lg-row-fluid w-50 bgi-size-cover bgi-position-y-center bgi-position-x-start bgi-no-repeat'
          style={{
            backgroundImage: `url(${toAbsoluteUrl(bgUrl ?? '/media/auth/bg4.jpg')})`,
          }}
        ></div>
        {/* end::Body */}
        {/* end::Content */}
      </div>
    </div>
  )
}

interface Props {
  userType: string
  bgUrl?: string
  signUpFormObject?: any
  loginFormObject?: any
}

const AuthPage = ({userType, bgUrl, signUpFormObject, loginFormObject}: Props) => {
  return (
    <Routes>
      <Route element={<AuthLayout bgUrl={bgUrl} />}>
        <Route path='login' element={<Login userType={userType} formObject={loginFormObject} />} />
        <Route path='invite/:id' element={<AcceptInvite userType={userType} />} />
        <Route
          path='registration'
          element={<SignUp userType={userType} formObject={signUpFormObject} />}
        />
        <Route path='forgot-password' element={<ForgotPassword userType={userType} />} />
        <Route path='registration/:userId/confirm' element={<EmailVerification userType={userType} />} />
        <Route path='new-password' element={<NewPassword userType={userType} />} />
        <Route index element={<Login userType={userType} formObject={loginFormObject} />} />
      </Route>
    </Routes>
  )
}

export default AuthPage
