import {useEffect} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../../../web-app-core'

const API_URL = import.meta.env.VITE_APP_API_URL

export default function MeetingNotes() {
  const navigate = useNavigate()

  const {pathname} = useLocation()
  const {id} = useParams()
  console.log('pathname', pathname)

  const formObject = {
    listEndpoint: `${API_URL}/meeting/${id}/note-item`,
    listTitle: 'Notes',
    listFields: [
      {
        name: 'title',
        header: 'Title',
        type: 'link',
        path: '/meeting-note-item',
      },
      {
        name: 'description',
        header: 'Description',
      },
    ],
    addEndpoint: `${API_URL}/meeting/${id}/note-item`,
    addTitle: 'Add Note',
    addFields: [
      {
        name: 'title',
        label: 'Title',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
          minCharacters: 2,
        },
        type: 'text',
      },
      {
        name: 'description',
        label: 'Description',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
          minCharacters: 2,
        },
        type: 'textarea',
      },
    ],
    updateEndpoint: `${API_URL}/meeting-note-item`,
    updateTitle: 'Edit Notes',
    updateFields: [
      {
        name: 'title',
        label: 'Title',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
          minCharacters: 2,
        },
        type: 'text',
      },
      {
        name: 'description',
        label: 'Description',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
          minCharacters: 2,
        },
        type: 'textarea',
      },
    ],
  }

  return <ManageItem formObject={formObject} />
}
