/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {ErrorMessage, Field} from 'formik'
import {Tooltip as ReactTooltip, Tooltip} from 'react-tooltip'
import {KTIcon} from '../../../_metronic/helpers'
import axios from 'axios'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'

interface InputComponentProps {
  field: any
  data: any
  updateData: (fieldsToUpdate: Partial<any>) => void
  hasError: boolean
  key: string
  errors: any
  touched: any
}

const CheckboxList = ({
  field,
  data,
  updateData,
  hasError,
  errors,
  touched,
}: InputComponentProps) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [items, setItems] = useState<Array<any>>([])
  const getList = () => {
    return axios.get<any>(`${field.optionsEndpoint}`)
  }

  // check if optionsEndpoint is available
  useEffect(() => {
    if (field.optionsEndpoint) {
      setLoading(true)
      getList().then(({data: response}) => {
        console.log('data', response)
        setItems(response.data)

        setLoading(false)
      })
    } else {
      setItems(field.options)
      setLoading(false)
    }
  }, [])

  // console.log('InputTextWithLabel data', data)
  const [inputValue, setInputValue] = useState(data && data[field.name])
  const onChange = (e: any) => {
    let objectValue = {} as any
    objectValue[field.name as string] = e.target.value
    setInputValue(e.target.value)
    updateData(objectValue)
  }
  console.log('touched field name', touched[field.name])
  // console.log('errors', errors)
  return (
    <div className={`col-${field.cols ? field.cols : '12'} mb-7 mx-1`} key={field.name}>
      {/*begin::Form Group */}
      <div className='fv-row'>
        {/* begin::Label */}
        <label className='d-flex align-items-center fs-7 fw-bold'>
          <span className='required fs-4'>{field.label ?? field.title}</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Select your app category'
          ></i>
        </label>
        <Tooltip anchorSelect='.my-anchor-element' clickable className='rounded'>
            <div className='fs-7 fw-bold mw-250px'>
              You don't have enough credits to add this feature. Purchase a subscription to add more features {' '}
              <Link
                to={'/company/plans'}
                className='cursor-pointer fw-bold text-warning'
              >
                Add Subscription
              </Link>
            </div>
          </Tooltip>
        <span className='fs-7 text-gray-700'>{field.description}</span>
        {/* end::Label */}
        <div className='me-5 pt-4'>
          {loading ? (
            <Skeleton className='h-50px mb-3' count={3} />
          ) : (
            <>
              <div></div>
              {items.map((option: any) => (
                  <label
                  key={`${option.id}-${data[field.name]?.reduce(
                    (acc: number, item: any) => acc + (item.creditPrice ?? 0),
                    0
                  ) +
                    option.creditPrice}`}
                    className={`d-flex align-items-center justify-content-between mb-6 cursor-pointer ${
                      !data[field.name]?.find((item: any) => item.id === option.id) &&
                      data[field.name]?.reduce(
                        (acc: number, item: any) => acc + (item.creditPrice ?? 0),
                        0
                      ) +
                        option.creditPrice >
                        50
                        ? 'my-anchor-element'
                        : ''
                    }`}
                  >
                    <span className='d-flex align-items-center me-2'>
                      <span className='symbol symbol-50px me-6'>
                        <span className={`symbol-label bg-light-${option.color ?? 'primary'}`}>
                          <KTIcon
                            iconName={option.icon ?? option.iconName ?? 'abstract-24'}
                            className={`fs-2x text-${option.color ?? 'primary'}`}
                          />
                        </span>
                      </span>

                      <span className='d-flex flex-column'>
                        <span className='fw-bolder fs-6'>{option.label ?? option.title}</span>
                        <span className='fs-7 text-gray-700'>{option.description}</span>
                      </span>
                    </span>
                    <span className='d-flex'>
                           <span className='badge badge-light-warning fw-bold fs-5 px-2 py-1 ms-2 me-2'>
                      {option.creditPrice}
                    </span>
                    <span className='form-check form-check-custom form-check-solid'>
                      <Field name={field.name}>
                        {({field, form, meta}: any) => (
                          <input
                            className='form-check-input'
                            {...field}
                            value={option.value ?? option.id}
                            disabled={
                              !data[field.name]?.find((item: any) => item.id === option.id) &&
                              data[field.name]?.reduce(
                                (acc: number, item: any) => acc + (item.creditPrice ?? 0),
                                0
                              ) +
                                option.creditPrice >
                                50
                            }
                            checked={
                              data[field.name] &&
                              (data[field.name]?.find((item: any) => item.id === option.id) ||
                              data[field.name] === option.value)
                            }
                            type='checkbox'
                            onChange={(e: any) => {
                              const newVal = e.target.value
                              let newList = data[field.name] ?? []
                              if (!newList?.find((a: any) => a.id === newVal)) {
                                newList.push(option)
                              } else {
                                newList = newList.filter((item: any) => item.id !== newVal)
                              }
                              console.log('newList', newList)
                              form.setFieldValue(field.name, newList)
                              updateData({[field.name]: newList})
                            }}
                          />
                        )}
                      </Field>
                      {/* badge to display credit cost */}
                    </span>
                    </span>
               
                  </label>
              ))}
            </>
          )}
        </div>
      </div>
      {/*end::Form Group */}
      {!inputValue && hasError && (
        <div className='fv-plugins-message-container'>
          <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
            {field.label} is required
          </div>
        </div>
      )}
    </div>
  )
}

export {CheckboxList}
