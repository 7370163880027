import React, {useEffect, useState} from 'react'
import '../../assets/sass/tiptap.scss'
import {EditorContent, Extension, mergeAttributes, ReactRenderer, useEditor} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import Mention from '@tiptap/extension-mention'
import MentionList from './MentionList'
import tippy, { GetReferenceClientRect } from 'tippy.js'

const MenuBar = ({editor}: any) => {
  if (!editor) {
    return null
  }

  return (
    <>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={`btn btn-outline btn-outline-dashed btn-icon h-35px w-35px me-1 ${
          editor.isActive('bold') ? 'btn-dark' : ''
        }`}
        type='button'
      >
        <i className='fas fa-bold fs-4'></i>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={`btn btn-outline btn-outline-dashed btn-icon h-35px w-35px me-1 ${
          editor.isActive('italic') ? 'is-active' : ''
        }`}
        type='button'
      >
        <i className='fas fa-italic fs-4'></i>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        className={`btn btn-outline btn-outline-dashed btn-icon h-35px w-35px me-1 ${
          editor.isActive('strike') ? 'is-active' : ''
        }`}
        type='button'
      >
        <i className='fas fa-strikethrough fs-4'></i>
      </button>

      <button
        onClick={() => editor.chain().focus().toggleHeading({level: 3}).run()}
        className={`btn btn-outline btn-outline-dashed btn-icon h-35px w-35px me-1  ${
          editor.isActive('heading', {level: 3}) ? 'is-active' : ''
        }`}
        type='button'
      >
        H1
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({level: 5}).run()}
        className={`btn btn-outline btn-outline-dashed btn-icon h-35px w-35px me-1  ${
          editor.isActive('heading', {level: 5}) ? 'is-active' : ''
        }`}
        type='button'
      >
        H2
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={`btn btn-outline btn-outline-dashed btn-icon h-35px w-35px me-1 ${
          editor.isActive('bulletList') ? 'is-active' : ''
        }`}
        type='button'
      >
        <i className='fas fa-list-ul fs-4'></i>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={`btn btn-outline btn-outline-dashed btn-icon h-35px w-35px me-1 ${
          editor.isActive('orderedList') ? 'is-active' : ''
        }`}
        type='button'
      >
        <i className='fas fa-list-ol fs-4'></i>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={`btn btn-outline btn-outline-dashed btn-icon h-35px w-35px me-1 ${
          editor.isActive('codeBlock') ? 'is-active' : ''
        }`}
        type='button'
      >
        <i className='fas fa-code fs-4'></i>
      </button>

      <button
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
        className={`btn btn-outline btn-outline-dashed btn-icon h-35px w-35px me-1 `}
        type='button'
      >
        <i className='fas fa-arrow-rotate-left fs-4'></i>
      </button>
      <button
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
        className={`btn btn-outline btn-outline-dashed btn-icon h-35px w-35px me-1 `}
        type='button'
      >
        <i className='fas fa-arrow-rotate-right fs-4'></i>
      </button>
    </>
  )
}

interface Props {
  value: string
  onChange: Function
  suggestionItems?: any
  onSubmit?: Function
}

export default ({value, onChange, suggestionItems, onSubmit}: Props) => {
  const [content, setContent] = useState<any>(value as any)

  const DisableEnter = Extension.create({
    addKeyboardShortcuts() {
      return {
        Enter: () => {
          if (onSubmit) {
            onSubmit(value)
            return true
          }
          return false
        },
        "Shift-Enter": ({ editor }) => 
          editor.commands.first(({ commands }) => [
             () => commands.newlineInCode(),
             () => commands.splitListItem("listItem"), // This line added
             () => commands.createParagraphNear(),
             () => commands.liftEmptyBlock(),
             () => commands.splitBlock(),
          ])
      };
    },
  });

  useEffect(() => {
    if (onSubmit) {
      editor?.commands.focus()
    }
  }, [])

  useEffect(() => {
    if (content) {
      onChange(content)
    }
  }, [content])

  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: true,
        autolink: true,
        defaultProtocol: 'https',
      }),
      DisableEnter,
      Mention.configure({
        HTMLAttributes: {
          class: 'mention badge badge-light-primary fs-7',
        },
        suggestion:{
          items: suggestionItems,
          render: () => {
            let component: any
            let popup: any
            return {
              onStart: props => {
                component = new ReactRenderer(MentionList, {
                  props,
                  editor: props.editor,
                })
        
                if (!props.clientRect) {
                  return
                }
                popup = tippy('#myButton', {
                  getReferenceClientRect: props.clientRect as GetReferenceClientRect,
                  appendTo: () => document.body,
                  content: component.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: 'manual',
                  placement: 'bottom-start',
                })
              },
        
              onUpdate(props) {
                component.updateProps(props)
        
                if (!props.clientRect) {
                  return
                }
        
                popup[0].setProps({
                  getReferenceClientRect: props.clientRect,
                })
              },
        
              onKeyDown(props) {
                if (props.event.key === 'Escape') {
                  popup[0].hide()
        
                  return true
                }
        
                return component.ref?.onKeyDown(props)
              },
        
              onExit() {
                popup[0].destroy()
                component.destroy()
              },
            }
          },
        },
        renderText({ options, node }) {
          return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`
        },
        // renderHTML({ options, node }) {
        //   console.log('node.attrs', node)
        //   return [
        //     'a',
        //     mergeAttributes({ href: `/profile/${node.attrs.id}` }, options.HTMLAttributes),
        //     `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`,
        //   ]
        // },
      }),
    ],
    content: content,
    onUpdate: ({editor, transaction}) => {
      // console.log('editor', editor.getHTML())
      // console.log('transaction', transaction)
      setContent(editor.getHTML())
    },
  })

  const changeUpdate = (content: any) => {
    console.log('changeUpdate', content)
    onChange(content)
  }

  return (
    <div className='border border-1 p-5 rounded'>
      <EditorContent editor={editor} />
      <MenuBar editor={editor} />
    </div>
  )
}
