import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate, useLocation} from 'react-router-dom'
import {MasterLayout} from '../../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../../pages/dashboard/user/DashboardWrapper'
import {MenuTestPage} from '../../pages/MenuTestPage'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../../_metronic/helpers'
import BuilderPageWrapper from '../../pages/layout-builder/BuilderPageWrapper'
import {useAuth} from '../../modules/auth/core/Auth'
import CompanyUsers from '../../modules/meetings/components/CompanyUsers'
import Meetings from '../../modules/meetings/Meetings'
import MeetingPage from '../../modules/meetings/MeetingPage'

import {Logout} from '../../../web-app-core'
import Companies from '../../modules/companies/Companies'
import Trainings from '../../modules/trainings/Trainings'
import TrainingsPage from '../../modules/trainings/TrainingsPage'
import ActionPlans from '../../modules/actionPlan/ActionPlans'
import ActionPlansPage from '../../modules/actionPlan/ActionPlansPage'
import Polls from '../../modules/polls/Polls'
import PollsPage from '../../modules/polls/PollsPage'
import MeetingAgendaDetails from '../../modules/meetings/tabs/meetingAgenda/MeetingAgendaDetails'
import Evaluations from '../../modules/evaluations/Evaluations'
import EvaluationsPage from '../../modules/evaluations/EvaluationsPage'
import Compliance from '../../modules/compliance/Compliance'
import CompliancePage from '../../modules/compliance/CompliancePage'
import MeetingNotesDetails from '../../modules/meetings/tabs/meetingNotes/MeetingNotesDetails'
import ComplianceChecklistDetails from '../../modules/compliance/tabs/checklist/ComplianceChecklistDetails'
import ActionPlanChecklistDetails from '../../modules/actionPlan/tabs/checklist/ActionPlanChecklistDetails'
import BoardMembers from '../../modules/members/components/BoardMembers'
import SelectCompany from '../../modules/companies/SelectCompany'
import ApprovalsPage from '../../modules/approvals/ApprovalsPage'
import Approvals from '../../modules/approvals/Approvals'
import {ApprovalUserDetails} from '../../modules/approvals/tabs/ApprovalUserDetails'
import {ResponseDetails} from '../../modules/approvals/tabs/ResponseDetails'
import {BoardMemberDetails} from '../../modules/members/components/BoardMemberDetails'
import BoardMembersPage from '../../modules/members/BoardMembersPage'
import CalendarEvents from '../../modules/calendar/CalendarEvents'
import {CalendarEventDetails} from '../../modules/calendar/tabs/CalendarEventDetails'
import CalendarEventsPage from '../../modules/calendar/CalendarEventsPage'
import Documents from '../../modules/documents/DocumentFolders'
import DocumentPage from '../../modules/documents/DocumentPage'
import Notifications from '../../modules/notifications/Notifications'
import NotificationsPage from '../../modules/notifications/NotificationsPage'
import ProfilePage from '../../modules/profile/ProfilePage'
import EvaluationResponsePage from '../../modules/evaluations/EvaluationResponsePage'
import EvaluationRespondents from '../../modules/evaluation-respondents/Evaluations'
import RespondentUserEvaluationUsers from '../../modules/evaluations/RespondentUserEvaluationUsers'
import EvaluationRespondentPage from '../../modules/evaluation-respondents/EvaluationRespondentPage'
import EvaluationResult from '../../modules/evaluations/EvaluationResult'
import EvaluationResultPage from '../../modules/evaluations/EvaluationResultPage'
import ApprovalResponses from '../../modules/approvals/ApprovalResponses'

const UserPrivateRoutes = () => {
  //const {currentUser, auth} = useAuth()

  //console.log('TalentPrivateRoutes currentUser', currentUser)
  //console.log('TalentPrivateRoutes auth', auth)
  const {pathname} = useLocation()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Pages */}
        <Route path='board-members' element={<BoardMembers />} />
        <Route path='board-members/:id*' element={<BoardMembersPage />} />
        <Route path='companies' element={<Companies />} />
        <Route path='select-company/:id*' element={<SelectCompany />} />

        <Route path='companies' element={<Companies />} />

        <Route path='meetings' element={<Meetings />} />
        <Route path='meetings/past' element={<Meetings />} />
        <Route path='meetings/upcoming' element={<Meetings />} />
        <Route path='meetings/canceled' element={<Meetings />} />
        <Route path='meetings' element={<Meetings />} />
        <Route path='meetings/:id*' element={<MeetingPage />} />
        <Route path='meeting-agenda-item/:id*' element={<MeetingAgendaDetails />} />
        <Route path='meeting-note-item/:id*' element={<MeetingNotesDetails />} />

        <Route path='trainings' element={<Trainings />} />
        <Route path='trainings/past' element={<Trainings />} />
        <Route path='trainings/upcoming' element={<Trainings />} />
        <Route path='trainings/canceled' element={<Trainings />} />
        <Route path='trainings/:id*' element={<TrainingsPage />} />
        

        <Route path='action-plans' element={<ActionPlans />} />
        <Route path='action-plans/not-started' element={<ActionPlans />} />
        <Route path='action-plans/ongoing' element={<ActionPlans />} />
        <Route path='action-plans/completed' element={<ActionPlans />} />
        <Route path='action-plans/:id*' element={<ActionPlansPage />} />
        <Route path='action-plan-checklist-item/:id*' element={<ActionPlanChecklistDetails />} />

        <Route path='admin/evaluations' element={<Evaluations />} />
        <Route path='admin/evaluations/:id*' element={<EvaluationsPage />} />
        <Route
          path='admin/evaluations/:id/respondent-users/:evaluationUserId/response/:respondentUserEvaluationUserId*'
          element={<EvaluationResultPage />}
        />

        <Route path='evaluations' element={<EvaluationRespondents />} />
        <Route path='evaluations/:id*' element={<EvaluationRespondentPage />} />
        <Route path='evaluations/evaluation-users/:id' element={<EvaluationRespondentPage />} />
        <Route
          path='evaluations/evaluation-users/:id/response/:evaluationUserId*'
          element={<EvaluationResponsePage />}
        />

        <Route path='approvals' element={<Approvals />} />
        <Route path='approvals/:id*' element={<ApprovalsPage />} />
        <Route path='approval-responses' element={<ApprovalResponses />} />
        <Route path='approval-responses/*' element={<ApprovalResponses />} />
        <Route path='approval-responses/response/:id*' element={<ApprovalsPage />} />

        <Route path='approvals/member/:id' element={<ApprovalUserDetails />} />
        <Route path='approvals/response/:id' element={<ResponseDetails />} />

        <Route path='calendar' element={<CalendarEvents />} />
        <Route path='calendar/:id' element={<CalendarEventsPage />} />

        <Route path='polls' element={<Polls />} />
        <Route path='polls/:id*' element={<PollsPage />} />

        <Route path='compliance' element={<Compliance />} />
        <Route path='compliance/not-started' element={<Compliance />} />
        <Route path='compliance/ongoing' element={<Compliance />} />
        <Route path='compliance/completed' element={<Compliance />} />
        <Route path='compliance/:id*' element={<CompliancePage />} />
        <Route path='compliance-checklist-item/:id*' element={<ComplianceChecklistDetails />} />

        <Route path='document-folders' element={<Documents />} />
        <Route path='document-folders/:id*' element={<DocumentPage />} />

        <Route path='notifications' element={<Notifications />} />
        <Route path='notifications/:id*' element={<NotificationsPage />} />

        <Route path='profile' element={<ProfilePage />} />

        <Route path='logout' element={<Logout />} />

        <Route index element={<Navigate to={'meetings'} />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {UserPrivateRoutes}
