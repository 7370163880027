/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useRef, useState} from 'react'
import axios from 'axios'
import {Modal} from 'react-bootstrap'
import {createPortal} from 'react-dom'
import {useNavigate, Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import {KTSVG} from '../../helpers'
import {Form, Formik} from 'formik'
import {InputDropdownWithLabel} from '../molecules/InputDropdownWithLabel'
import {InputRichTextWithLabel} from '../molecules/InputRichTextWithLabel'
import {InputTextAreaWithLabel} from '../molecules/InputTextAreaWithLabel'
import {InputTextWithLabel} from '../molecules/InputTextWithLabel'
import {DatePickerInputWithLabel} from '../molecules/DatePickerInputWithLabel'
import {DateTimePickerInputWithLabel} from '../molecules/DateTimePickerInputWithLabel'
import {InputMultiselectDropdownWithLabel} from '../molecules/InputMultiselectDropdownWithLabel'
import {TimePickerInputWithLabel} from '../molecules/TimePickerInputWithLabel'
import {InputPasswordWithLabel} from '../molecules/InputPasswordWithLabel'
import {InputPhoneNumberWithLabel} from '../molecules/InputPhoneNumberWithLabel'
import {InputCodeWithLabel} from '../molecules/InputCodeWithLabel'

type Props = {
  formObject: any
  inputData?: any
  errors?: Array<any>
  touched?: any
  submitted?: any
}

const getFieldComponent = (
  field: any,
  data: any,
  updateData: any,
  hasError: any,
  errors: any,
  touched: any
) => {
  let fieldComponent
  switch (field.type) {
    case 'text':
      fieldComponent = (
        <InputTextWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
          touched={touched}
        />
      )
      break
    case 'password':
      fieldComponent = (
        <InputPasswordWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
          touched={touched}
        />
      )
      break
    case 'phone-number':
      fieldComponent = (
        <InputPhoneNumberWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
          touched={touched}
        />
      )
      break
    case 'code':
      fieldComponent = (
        <InputCodeWithLabel
          key={field.name}
          fields={field.fields}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
          touched={touched}
        />
      )
      break
    case 'textarea':
      fieldComponent = (
        <InputTextAreaWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
          touched={touched}
        />
      )
      break
    case 'dropdown':
      fieldComponent = (
        <InputDropdownWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'multiselect-dropdown':
      fieldComponent = (
        <InputMultiselectDropdownWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'richtext':
      fieldComponent = (
        <InputRichTextWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'datepicker':
      fieldComponent = (
        <DatePickerInputWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'datetimepicker':
      fieldComponent = (
        <DateTimePickerInputWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'timepicker':
      fieldComponent = (
        <TimePickerInputWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    default:
      break
  }
  return fieldComponent
}

const AddUpdateItemForm: FC<Props> = ({formObject, inputData, submitted}) => {
  const [loading, setLoading] = useState(false)
  const closeModal = useRef<any>()

  const navigate = useNavigate()

  const [data, setData] = useState<any>({
    ...inputData,
  } as any)
  const [hasError, setHasError] = useState(false)

  const updateData = (fieldsToUpdate: Partial<any>) => {
    console.log('updateData data', data)
    console.log('updateData fieldsToUpdate', fieldsToUpdate)
    const updatedData = {...data, ...fieldsToUpdate}
    setData(updatedData)
  }

  const fieldsToRender = inputData?.id ? formObject.updateFields : formObject.addFields
  const formTitle = inputData?.id ? formObject.updateTitle : formObject.addTitle

  const getValidationSchema = () => {
    let yupObject = {} as any
    let yupValidation = {} as any
    if (inputData?.id) {
      formObject.updateFields.map((field: any) => {
        if (field.validation) {
          switch (field.validation['type']) {
            case 'number':
              yupObject[field.name] = Yup.number().typeError(`${field.label} must be a number`)
              break

            case 'string':
              yupObject[field.name] = Yup.string()
              break

            default:
              if (!field.validation['type']) {
                yupObject[field.name] = Yup.string()
              }
              break
          }

          Object.keys(field.validation).map((item) => {
            switch (item) {
              case 'required':
                yupObject[field.name] = yupObject[field.name].required(
                  `${field.label} is a required field`
                )
                break
              case 'email':
                yupObject[field.name] = yupObject[field.name].email(field.validation['message'])
                break
              case 'min':
                yupObject[field.name] = yupObject[field.name].min(
                  field.validation[item],
                  field.validation['message']
                )
                break
              case 'matches':
                yupObject[field.name] = yupObject[field.name].matches(
                  field.validation[item],
                  field.validation['message']
                )
                break
              case 'oneOf':
                yupObject[field.name] = yupObject[field.name].oneOf(
                  [Yup.ref(field.validation[item])],
                  field.validation['message']
                )
                break
              default:
                break
            }
          })
        }
      })
    } else {
      formObject.addFields?.map((field: any) => {
        if (field.validation) {
          switch (field.validation['type']) {
            case 'number':
              yupObject[field.name] = Yup.number().typeError(`${field.label} must be a number`)
              break

            case 'string':
              yupObject[field.name] = Yup.string()
              break

            default:
              if (!field.validation['type']) {
                yupObject[field.name] = Yup.string()
              }
              break
          }

          Object.keys(field.validation).map((item) => {
            switch (item) {
              case 'required':
                yupObject[field.name] = yupObject[field.name].required(
                  `${field.label} is a required field`
                )
                break
              case 'email':
                yupObject[field.name] = yupObject[field.name].email(field.validation['message'])
                break
              case 'min':
                yupObject[field.name] = yupObject[field.name].min(
                  field.validation[item],
                  field.validation['message']
                )
                break
              case 'matches':
                yupObject[field.name] = yupObject[field.name].matches(
                  field.validation[item],
                  field.validation['message']
                )
                break
              case 'oneOf':
                yupObject[field.name] = yupObject[field.name].oneOf(
                  [Yup.ref(field.validation[item])],
                  field.validation['message']
                )
                break
              default:
                break
            }
          })
        }
      })
    }

    console.log('yupObject', yupObject)
    return Yup.object().shape(yupObject)
  }

  const submitData = async (values: any) => {
    console.log('values to save', values)

    setLoading(true)

    let response: any
    try {
      if (inputData?.id) {
        const objectData = {} as any
        formObject.updateFields.forEach((item: any) => {
          if (item.notAllowed) {
            delete objectData[item.value]
          } else if (item?.type === 'hidden') {
            objectData[item.name] = item.value
          } else {
            objectData[item.name] = values[item.name]
          }
        })

        console.log('objectDataEd', objectData)
        response = await axios({
          method: formObject.updateMethod ? formObject.updateMethod : 'patch',
          url: `${formObject.updateEndpoint}/${inputData?.id}`,
          data: objectData,
        })
      } else {
        const objectData = {} as any
        formObject.addFields.forEach((item: any) => {
          if (item.notAllowed) {
            delete objectData[item.value]
          } else if (item?.type === 'hidden') {
            objectData[item.name] = item.value
          } else {
            objectData[item.name] = values[item.name]
          }
        })

        console.log('objectData', objectData)

        response = await axios.post(formObject.addEndpoint, objectData)
      }
    } catch (error: any) {
      response = error?.response
    } finally {
      setLoading(false)
    }

    console.log('submitData response', response)
    setLoading(false)

    if (response?.data.success) {
      if (submitted) {
        const {data: auth} = response
        submitted(values['emailAddress'], auth, response)
      } else {
        closeModal.current.click()
        Swal.fire({
          text: `Item succesfully ${inputData ? 'updated' : 'added'}`,
          icon: 'success',
          confirmButtonText: 'Okay',
        }).then(() => {})
      }
    } else {
      Swal.fire({
        text: `${response?.data?.error?.message}`,
        icon: 'error',
        confirmButtonText: 'Okay',
      }).then(({isConfirmed, isDismissed}) => {
        if (isConfirmed) {
          if (response?.data?.error?.code === 'UsernameExistsException') {
            navigate(formObject?.userExistUrl)
            return
          } else if (response?.data?.error?.code === 'UserNotConfirmedException') {
            navigate(formObject?.userExistUrl, {state: {email: values['emailAddress']}})
            return
          }
        }
      })
    }
  }

  return (
    <div className=''>
      {formTitle && (
        <div className='card-body'>
          <div className='text-start mb-10'>
            {formTitle && (
              <h1 className='text-dark mb-3 fs-3x' data-kt-translate='sign-in-title'>
                {formTitle}
              </h1>
            )}
            {formObject?.description && (
              <div className='text-gray-800 fw-semibold fs-6' data-kt-translate='general-desc'>
                {formObject?.description}
              </div>
            )}
          </div>
        </div>
      )}

      <div>
        <Formik
          initialValues={
            {
              ...data,
            } as any
          }
          validationSchema={getValidationSchema()}
          onSubmit={(values) => {
            // same shape as initial values
            console.log('values', values)
            submitData(values)
          }}
        >
          {({errors, touched, isValid, isSubmitting}) => (
            <Form placeholder={undefined}>
              <div className='w-100'>
                <div className='row fv-row'>
                  {fieldsToRender?.map((field: any) => {
                    return getFieldComponent(field, data, updateData, hasError, errors, touched)
                  })}
                </div>
              </div>
              {formObject.forgotPasswordUrl && (
                <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold'>
                  <div />
                  <Link
                    to={formObject.forgotPasswordUrl}
                    className='link-primary'
                    data-kt-translate='sign-in-forgot-password'
                    style={{marginLeft: '5px'}}
                  >
                    Forgot Password ?
                  </Link>
                </div>
              )}
              <div
                className={`mt-10 ${
                  formObject?.cancelButtonUrl ? 'd-flex flex-stack' : 'text-center'
                }`}
              >
                <button
                  disabled={loading}
                  className={`btn ${isValid || loading ? 'btn-primary' : 'btn-secondary'}`}
                  type='submit'
                >
                  <span>{formObject?.buttonTitle || 'Submit'}</span>
                  {loading ? (
                    <div className='ms-4 spinner-border spinner-border-sm' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                  ) : null}
                </button>
                {formObject?.cancelButtonUrl && (
                  <Link to={formObject?.cancelButtonUrl}>
                    <button
                      type='button'
                      id='kt_login_password_reset_form_cancel_button'
                      className='btn btn-lg btn-light-primary fw-bold'
                      //   disabled={formik.isSubmitting || !formik.isValid}
                    >
                      Cancel
                    </button>
                  </Link>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default AddUpdateItemForm
