/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Field} from 'formik'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import TipTap from './TipTap'
import CodeEditor from '@uiw/react-textarea-code-editor'
import Editor from 'react-simple-code-editor'
import Prism from 'prismjs'
// import 'prismjs/themes/prism.css'

import 'prismjs/themes/prism-dark.css' // Solarized Light theme
import 'prismjs/components/prism-yaml'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-tsx'
//import { highlight, languages } from 'prismjs';

import loadLanguages from 'prismjs/components/'

// const languagesToImport = ['tsx', 'bash', 'typescript', 'markup', 'css', 'json', 'diff'];
// loadLanguages(languagesToImport);

interface InputComponentProps {
  field: any
  data: any
  updateData: (fieldsToUpdate: Partial<any>) => void
  hasError: boolean
  key: string
  errors: any
}

const CodeEditorWithLabel = ({field, data, updateData, hasError, errors}: InputComponentProps) => {
  const [inputValue, setInputValue] = useState(data && data[field.name])
  const inputOnChange = (e: any) => {
    let objectValue = {} as any
    objectValue[field.name as string] = e

    console.log('objectValue', objectValue)

    setInputValue(e)
    updateData(objectValue)
  }
  //loadLanguages(['yaml']);

  return (
    <div className={`col-${field.cols ? field.cols : '12'} mb-7`} key={field.name}>
      <label
        htmlFor={`input-${field.name}-id`}
        className={`d-flex align-items-center fs-6 fw-bold mb-0 ms-1 ${
          field.validation?.required ? 'required' : ''
        }`}
      >
        <span>{field.label}</span>
        {field.toolTip ? (
          <>
            <ReactTooltip id={`${field.name}-input-tooltip`} place='top'>
              {field.toolTip}
            </ReactTooltip>

            <span
              data-tip
              data-for={`${field.name}-input-tooltip`}
              data-padding='5px 7px'
              // title={a.tooltip}
            >
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Specify your unique app name'
              ></i>
            </span>
          </>
        ) : null}
      </label>
      <Field name={field.name}>
        {({field: formikField, form}: any) => (
          <div>
            {!field.language ? (
              <div>Language not specified in form object</div>
            ) : (
              <Editor
                value={data && data[field.name]}
                className='border rounded-3 min-h-100px bg-dark text-light'
                placeholder={field.placeholder}
                onValueChange={(code) => {
                  let objectValue = {} as any
                  objectValue[field.name as string] = code
                  setInputValue(code)
                  updateData(objectValue)
                  form.setFieldValue(field.name as string, code)
                }}
                highlight={(code) => {
                  if (!code) return ''

                  return Prism.highlight(code, Prism.languages[field.language], field.language)
                }}
                padding={10}
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 12,
                }}
              />
            )}
            {/*           
            <CodeEditor
              value={data && data[field.name]}
              language='js'
              placeholder='Please enter JS code.'
              onChange={(evn) => {
                let objectValue = {} as any
                objectValue[field.name as string] = evn.target.value
                setInputValue(evn.target.value)
                updateData(objectValue)
                form.setFieldValue(field.name as string, evn.target.value)
              }}
              padding={0}
              style={{
                fontSize: 12,
                backgroundColor: '#f5f5f5',
                fontFamily:
                  'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
              }}
            /> */}

            {errors[field.name] && (
              <div className='error error fs-7 fw-semibold ps-1 text-danger'>
                {errors[field.name]}
              </div>
            )}
          </div>
        )}
      </Field>
      {/* {!inputValue && hasError && (
        <div className='fv-plugins-message-container'>
          <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
            {field.label} is required
          </div>
        </div>
      )} */}
    </div>
  )
}

export {CodeEditorWithLabel}
