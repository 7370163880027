import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      {/* <MenuItem title={'Dashboard'} to='/company/dashboard' />
      <MenuItem title={'Products'} to='/company/products' /> */}
      {/* <MenuItem title={"Academy"} to="/company/academy" />
      <MenuItem title="Knowledge Base" to="/knowledge-base" />
      <MenuItem title="Support" to="/support" /> */}
    </>
  )
}
