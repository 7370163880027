import { createContext, useContext } from "react";

let initAuthContextPropsState = {} as any;
// Create a context for the dynamic screen data
const RenderComponentContext = createContext<any>(null);

const useRenderComponentContext = () => {
  return useContext(RenderComponentContext);
};

export { RenderComponentContext, useRenderComponentContext };
