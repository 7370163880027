import {FC} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {Languages} from './Languages'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {useAuth} from '../../../../web-app-core'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const navigate = useNavigate()

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
      data-testid='header-user-menu'
    >
      <div className='menu-item px-5 fs-5'>
        <div className='fw-bolder fs-5'>
          {currentUser?.firstName} {currentUser?.lastName}
        </div>

        <div className='fw-bold text-muted text-hover-primary fs-7'>
          {currentUser?.emailAddress}
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5 fs-5'>
        <Link to='/profile' className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='menu-item px-5 fs-5'>
        <a data-testid='sign-out' onClick={() => navigate('/logout')} className='menu-link'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
