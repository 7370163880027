import {useEffect} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../web-app-core'
import {AuthModel} from '../../../web-app-core/helpers/AuthHelpers'
import axios from 'axios'

const API_URL = import.meta.env.VITE_APP_API_URL

export default function SelectCompany() {
  const navigate = useNavigate()
  const {id} = useParams()
  const {auth, saveAuth} = useAuth()

  const {pathname} = useLocation()
  console.log('pathname', pathname)
  const companyId = ''

  useEffect(() => {
    if (id) {
      const getObject = async () => {
        const response = await axios.get(`${API_URL}/admin/company/${id}`)
        return response.data.data
      }
      getObject().then((data) => {
        console.log('getObject data', data)
        saveAuth({...auth, currentTenant: {id: data.id, name: data.name}} as AuthModel)
        navigate('/meetings')
      })
    }
  }, [id])
  return <></>
}
