import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../web-app-core'
import DynamicScreen from '../../../web-app-core/components/DynamicScreen'

const API_URL = import.meta.env.VITE_APP_API_URL

export default function Polls() {
  const {pathname} = useLocation()
  console.log('pathname', pathname)

  const {auth} = useAuth()
  console.log('currentTenant', auth?.currentTenant?.id)

  const currentTenant = auth?.currentTenant?.id

  const screenData = {
    screen: {
      className: 'card',
      components: [
        {
          type: 'ItemManagementTable',
          data: {
            listEndpoint: `${API_URL}/company/${currentTenant}/poll`,
            listTitle: 'Polls',
            listFields: [
              {
                name: 'title',
                header: 'Title',
                type: 'link',
                path: '/polls',
              },
              // {
              //   name: "description",
              //   header: "Description",
              // },
              {
                name: 'dateCreated',
                header: 'Date Created',
                type: 'date',
              },
              {
                name: 'status',
                header: 'Status',
                type: 'status',
              },
            ],
            addEndpoint: `${API_URL}/company/${currentTenant}/poll`,
            addTitle: 'Add Poll',
            addFields: [
              {
                name: 'title',
                label: 'Title',
                toolTip: '',
                placeholder: '',
                validation: {
                  required: true,
                  type: 'string',
                  minCharacters: 2,
                },
                type: 'text',
              },
              {
                name: 'description',
                label: 'Description',
                toolTip: '',
                placeholder: '',
                validation: {
                  type: 'string',
                  minCharacters: 2,
                },
                type: 'textarea',
              },
            ],
            updateEndpoint: `${API_URL}/poll`,
            updateTitle: 'Edit Poll',
            updateFields: [
              {
                name: 'title',
                label: 'Title',
                toolTip: '',
                placeholder: '',
                validation: {
                  required: true,
                  type: 'string',
                  minCharacters: 2,
                },
                type: 'text',
              },
              {
                name: 'description',
                label: 'Description',
                toolTip: '',
                placeholder: '',
                validation: {
                  type: 'string',
                  minCharacters: 2,
                },
                type: 'textarea',
              },
              {
                name: 'status',
                label: 'Status',
                toolTip: '',
                placeholder: '',
                validation: {
                  required: true,
                  type: 'string',
                },
                type: 'dropdown',
                options: [
                  {id: 'Disabled', title: 'Disabled'},
                  {id: 'Enabled', title: 'Enabled'},
                ],
              },
            ],
          },
        },
      ],
    },
  }

  return <DynamicScreen data={screenData} />
}
