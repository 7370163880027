import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'
import {DetailHeader, useAuth} from '../../../web-app-core'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import ActionPlanChecklist from './tabs/checklist/ActionPlanChecklist'
import DynamicScreen from '../../../web-app-core/components/DynamicScreen'

const API_URL = import.meta.env.VITE_APP_API_URL

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ActionPlansPage = () => {
  const {id} = useParams()
  const {currentUser} = useAuth()
  const companyId = currentUser?.companyId
  const formObject = {
    endpoint: `${API_URL}/action-plan/${id}`,
    dataFields: [
      {
        name: 'startTime',
        label: 'Start Date',
        type: 'datetime',
      },
      {
        name: 'endTime',
        label: 'Completion Date',
        type: 'datetime',
      },
      {
        name: 'category',
        label: 'Category',
      },
    ],
    tabs: [
      {
        title: 'Checklist',
        path: `/action-plans/${id}`,
      },
    ],
  }

  
  const screenData = {
    screen: {
      className: '',
      title: 'Action Plan',
      iconName: 'clipboard',
      detailsEndpoint: `${API_URL}/action-plan/${id}`,
      components: [
        {
          type: 'view',
          className: '',
          style: {
            flexDirection: 'row',
          },
          components: [
            {
              type: 'ItemDetailVertical',
              className: 'me-2 rounded-right-0',
              data: {
                level: 3,
                path: `/`,
                endpoint: `${API_URL}/action-plan/${id}`,
                dataFields: [
                  {name: 'title', label: 'Title'}, 
                  {name: 'description', label: 'Description'},
                  {name: 'dateCreated', label: 'Date Created'},
                ],
                tabs: [
                ],
              }
            },
            {
              type: 'ItemManagementTable',
              className: 'w-100 card px-5',
              data: {
                listEndpoint: `${API_URL}/action-plan/${id}/checklist-item`,
                listTitle: 'Checklist Items',
                listFields: [
                  {
                    name: 'title',
                    header: 'Title',
                    type: 'link',
                    path: '/action-plan-checklist-item',
                  },
                  {
                    name: 'progressPercentage',
                    header: 'Progress (%)',
                  },
                  {
                    name: 'assignedUser.firstName',
                    header: 'Assigned',
                  },
                  {
                    name: 'status',
                    header: 'Status',
                    type: 'status',
                  },
                ],
                addEndpoint: `${API_URL}/action-plan/${id}/checklist-item`,
                addTitle: 'Add Checklist Item',
                addFields: [
                  {
                    name: 'title',
                    label: 'Title',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                      minCharacters: 2,
                    },
                    type: 'text',
                  },
                  {
                    name: 'description',
                    label: 'Description',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      type: 'string',
                      minCharacters: 2,
                    },
                    type: 'textarea',
                  },
                  {
                    name: 'progressPercentage',
                    label: 'Progress (%)',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      type: 'number',
                      minCharacters: 2,
                    },
                    type: 'text',
                  },
                  {
                    name: 'assignedUserId',
                    label: 'Assign',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      type: 'string',
                    },
                    type: 'dropdown',
                    optionsEndpoint: `${API_URL}/company/${companyId}/user?limit=1000`,
                  },
                ],
                updateEndpoint: `${API_URL}/action-plan-checklist-item`,
                updateTitle: 'Edit Checklist Item',
                updateFields: [
                  {
                    name: 'title',
                    label: 'Title',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                      minCharacters: 2,
                    },
                    type: 'text',
                  },
                  {
                    name: 'description',
                    label: 'Description',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      type: 'string',
                      minCharacters: 2,
                    },
                    type: 'textarea',
                  },
                  {
                    name: 'progressPercentage',
                    label: 'Progress (%)',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      type: 'number',
                      minCharacters: 2,
                    },
                    type: 'text',
                  },
                  {
                    name: 'assignedUserId',
                    label: 'Assign',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      type: 'string',
                    },
                    type: 'dropdown',
                    optionsEndpoint: `${API_URL}/company/${companyId}/user?limit=1000`,
                  },
                  {
                    name: 'status',
                    label: 'Status',
                    toolTip: '',
                    placeholder: '',
                    validation: {
                      required: true,
                      type: 'string',
                    },
                    type: 'dropdown',
                    options: [
                      {id: 'Not Started', title: 'Not Started'},
                      {id: 'Ongoing', title: 'Ongoing'},
                      {id: 'Completed', title: 'Completed'},
                    ],
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  }


  return <DynamicScreen data={screenData} />
}

export default ActionPlansPage
