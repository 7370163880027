import {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../web-app-core'
import DynamicScreen from '../../../web-app-core/components/DynamicScreen'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import axios from 'axios'
import {PageTitle} from '../../../_metronic/layout/core'
import ButtonWithAddUpdateModal from '../../../web-app-core/components/organisms/ButtonWithAddUpdateModal'

const API_URL = import.meta.env.VITE_APP_API_URL

export default function CalendarEvents() {
  const {pathname} = useLocation()
  const [items, setItems] = useState([])
  const navigate = useNavigate()
  console.log('pathname', pathname)
  const getData = async () => {
    const response = await axios.get(`${API_URL}/company/${currentTenant}/calendar-event`)
    setItems(response.data.data)
  }

  useEffect(() => {
    getData()
  }, [])

  const {auth} = useAuth()

  const currentTenant = auth?.currentTenant?.id

  const screenData = {
    screen: {
      className: 'card',
      detailsEndpoint: `${API_URL}/company/${currentTenant}/calendar-event`,
      title: 'Calendar',
      iconName: 'calendar',
      actionButtonText: 'Add Event',
      actionButton: {
        addEndpoint: `${API_URL}/company/${currentTenant}/calendar-event`,
        addTitle: 'Add Calendar Event',
        buttonText: 'Add Event',
        addFields: [
          {
            name: 'title',
            label: 'Title',
            toolTip: '',
            placeholder: '',
            validation: {
              required: true,
              type: 'string',
              minCharacters: 2,
            },
            type: 'text',
          },
          {
            name: 'description',
            label: 'Description',
            toolTip: '',
            placeholder: '',
            validation: {
              type: 'string',
            },
            type: 'textarea',
          },
          {
            name: 'startTime',
            label: 'Start',
            toolTip: '',
            placeholder: '',
            validation: {
              required: true,
              type: 'string',
            },
            type: 'datetimepicker',
            cols: '6',
          },
          {
            name: 'endTime',
            label: 'End',
            toolTip: '',
            placeholder: '',
            validation: {
              required: true,
              type: 'string',
            },
            type: 'datetimepicker',
            cols: '6',
          },
          {
            name: 'location',
            label: 'Location',
            toolTip: '',
            placeholder: '',
            validation: {
              type: 'string',
            },
            type: 'text',
          },
        ],
      },
      components: [
        {
          type: 'Calendar',
          className: 'p-5',
          data: {
            detailPath: '/calendar',
            listEndpoint: `${API_URL}/company/${currentTenant}/calendar-event`,
            listTitle: 'Calendar Events',
            listFields: [
              {
                name: 'title',
                header: 'Title',
                type: 'link',
                path: '/calendar',
              },
              {
                name: 'startTime',
                header: 'Start',
                type: 'datetime',
              },
              {
                name: 'endTime',
                header: 'End',
                type: 'datetime',
              },
            ],
            addEndpoint: `${API_URL}/company/${currentTenant}/calendar-event`,
            addTitle: 'Add Calendar Event',
            addFields: [
              {
                name: 'title',
                label: 'Title',
                toolTip: '',
                placeholder: '',
                validation: {
                  required: true,
                  type: 'string',
                  minCharacters: 2,
                },
                type: 'text',
              },
              {
                name: 'description',
                label: 'Description',
                toolTip: '',
                placeholder: '',
                validation: {
                  type: 'string',
                },
                type: 'textarea',
              },
              {
                name: 'startTime',
                label: 'Start',
                toolTip: '',
                placeholder: '',
                validation: {
                  required: true,
                  type: 'string',
                },
                type: 'datetimepicker',
                cols: '6',
              },
              {
                name: 'endTime',
                label: 'End',
                toolTip: '',
                placeholder: '',
                validation: {
                  required: true,
                  type: 'string',
                },
                type: 'datetimepicker',
                cols: '6',
              },
              {
                name: 'location',
                label: 'Location',
                toolTip: '',
                placeholder: '',
                validation: {
                  type: 'string',
                },
                type: 'text',
              },
            ],
            updateEndpoint: `${API_URL}/calendar-event`,
            updateTitle: 'Update Calendar Event',
            updateFields: [
              {
                name: 'title',
                label: 'Title',
                toolTip: '',
                placeholder: '',
                validation: {
                  required: true,
                  type: 'string',
                  minCharacters: 2,
                },
                type: 'text',
              },
              {
                name: 'description',
                label: 'Description',
                toolTip: '',
                placeholder: '',
                validation: {
                  type: 'string',
                },
                type: 'textarea',
              },
              {
                name: 'startTime',
                label: 'Start',
                toolTip: '',
                placeholder: '',
                validation: {
                  required: true,
                  type: 'string',
                },
                type: 'datetimepicker',
                cols: '6',
              },
              {
                name: 'endTime',
                label: 'End',
                toolTip: '',
                placeholder: '',
                validation: {
                  required: true,
                  type: 'string',
                },
                type: 'datetimepicker',
                cols: '6',
              },
              {
                name: 'location',
                label: 'Location',
                toolTip: '',
                placeholder: '',
                validation: {
                  type: 'string',
                },
                type: 'text',
              },
            ],
          },
        },
      ],
    },
  }

  return (
    <>
      <DynamicScreen data={screenData} />
    </>
  )
}
