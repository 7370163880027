import React, {useState, useEffect} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import axios from 'axios'
import {AddUpdateItemModal} from '../../../../web-app-core'
import moment from 'moment'

const API_URL = import.meta.env.VITE_APP_API_URL
const GET_REQUEST = `${API_URL}/approval-request-user-response`

function getRequest(id: string) {
  return axios.get<any>(`${GET_REQUEST}/${id}`)
}

const getStatusCssClassColor = (status: string) => {
  let cssClass
  switch (status) {
    case 'Active':
    case 'success':
    case 'Completed':
      cssClass = 'success'
      break

    case 'Disabled':
    case 'Canceled':
      cssClass = 'danger'
      break

    case 'Declined':
      cssClass = 'danger'
      break

    case 'Pending':
    case 'In Progress':
    case 'PendingApproval':
      cssClass = 'warning'
      break

    case 'Not Started':
      cssClass = 'dark'
      break

    default:
      cssClass = 'dark'
      break
  }
  return cssClass
}

export function ResponseDetails() {
  const {id} = useParams()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [request, setRequest] = useState({} as any)

  const loadData = () => {
    setLoading(true)
    getRequest(id as string).then(({data}) => {
      console.log('response data', data)
      setRequest(data?.data)
    })
  }
  useEffect(() => {
    loadData()
  }, [])

  const formObject: any = {
    title: 'Session',
    description: 'Some description of section',
    addTitle: '',
    addEndpoint: `${GET_REQUEST}`,
    addFields: [],
    updateTitle: '',
    updateEndpoint: ``,
    updateFields: [],
    listTitle: '',
    listEndpoint: `${GET_REQUEST}`,
    listFields: [],
  }

  return (
    <>
      {/* biodata section */}
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Approval Response Overview</h3>
          </div>

          {/* <button
            data-testid='update-modal-btn'
            className='btn btn-primary align-self-center'
            data-bs-toggle='modal'
            data-bs-target='#kt_update_profile'
            onClick={() => {
              setShowModal(true)
            }}
          >
            Update
          </button> */}
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>Board Member</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6 text-gray-800'>
                {request?.user?.firstName + ' ' + request?.user?.lastName}
              </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>Date Responded</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6 text-gray-800'>
                {moment(request?.dateCreated).format('MMM D, YYYY h:mm a')}
              </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>Status</label>

            <div className='col-lg-8 fv-row'>
              <span
                className={`badge badge-light-${getStatusCssClassColor(request?.status)}`}
                data-testid='talent-phone'
              >
                {request?.status}
              </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>Reason</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-semibold text-gray-800 fs-6'>{request?.reason}</span>
            </div>
          </div>
        </div>
      </div>
      {request?.id && (
        <AddUpdateItemModal
          formObject={formObject}
          show={showModal}
          handleClose={() => setShowModal(false)}
          handleSaved={() => {
            setShowModal(false)
            loadData()
          }}
          inputData={request}
        />
      )}
    </>
  )
}
