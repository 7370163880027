import React, {useState, useRef, useEffect} from 'react'
import {StepperComponent} from '../../../_metronic/assets/ts/components'
import AppForm from './AppForm'
import axios from 'axios'
import Swal from 'sweetalert2'

interface Props {
  formObject: any
  stepIndex?: number
  onComplete: () => Promise<void>
}

const FormWizard: React.FC<Props> = ({formObject, stepIndex, onComplete = async () => {}}) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [stepper, setStepper] = useState<StepperComponent | null>(null)
  const [data, setData] = useState<any>({})
  const [detailData, setDetailData] = useState<any>()
  const [hasError, setHasError] = useState(false)
  const [loading, setLoading] = useState(false)

  const loadStepper = () => {
    setStepper(StepperComponent.createInsance(stepperRef.current as HTMLDivElement))
  }

  const prevStep = () => {
    if (!stepper) {
      return
    }

    stepper.goPrev()
  }

  const nextStep = () => {
    setHasError(false)
    if (!stepper) {
      return
    }

    stepper.goNext()
    setLoading(false)
  }

  const callbackHandler = (step: any) => {
    /* 
	callbackHandler: is a function that takes a step object as an argument and calls the callback function if it exists.
	const data = step?.callback.data
	const submitEndpoint = step?.callback.submitEndpoint
	const submitMethod = step?.callback.submitMethod
	retun: void

	An example of a callback step is in src/app/modules/onboarding/company/CompanyOnboardingPage.tsx
	 */

    const data = step?.callback.data
    const submitEndpoint = step?.callback.submitEndpoint
    const submitMethod = step?.callback.submitMethod

    axios
      .patch(submitEndpoint, data, {
        method: submitMethod ?? 'PATCH',
      })
      .then((response) => {
        setData(response.data.data)
        nextStep()
      })
      .catch((error) => {
        console.error('error', error)
        Swal.fire('Error', 'Error creating app', 'error')
      })
  }

  const onSubmit = async (values: any, step: any, index: number) => {
    if (step?.submitEndpoint) {
      try {
        // setData((prevData: any) => {
        //   return {
        //     ...prevData,
        //     [index]: values,
        //   }
        // })
        setLoading(true)
        console.log('add endpoint')
        const dataToSave = {} as any
        step.formFields.forEach((field: any) => {
          if (field.dataType === 'array' && field.payloadFields) {
            // only save the payload fields
            dataToSave[field.name] = values[field.name].map((item: any) => {
              let payload = {} as any
              console.log('field.payloadFields', field.payloadFields)
              Object.keys(field.payloadFields).forEach((key) => {
                payload[key] = item[field.payloadFields[key]]
              })
              return payload
            })
          } else {
            // save the entire object
            dataToSave[field.name] = values[field.name]
          }
        })
        const response = await axios(step.submitEndpoint, {
          data: dataToSave,
          method: step.submitMethod ?? 'PATCH',
        })
        console.log('response', response)
        // setData((prevData: any) => {
        //   return {
        //     ...prevData,
        //     [index]: response.data.data,
        //   }
        // })

        // if step has a callback, call the callback handler
        if (step.callback) {
          callbackHandler(step)
        } else {
          stepper?.goNext()
          setLoading(false)
        }
      } catch (error) {
        console.error('error', error)
        Swal.fire('Error', 'Error creating app', 'error')
      }
    } else {
      setData((prevData: any) => {
        return {
          ...prevData,
          [index]: values,
        }
      })
      if (stepper?.getCurrentStepIndex() === formObject.steps.length) {
        // TODO: replace this with a data reload through a context
        window.location.reload()
        return
      }
      stepper?.goNext()
    }
  }

  useEffect(() => {
    loadStepper()
  }, [])
  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    loadStepper()
    if (firstLoad && stepIndex && stepper) {
      // delay the stepper goto to allow the stepper to load
      stepper?.goto(stepIndex)
      setFirstLoad(false)
    }
  }, [stepper])

  return (
    <div className=''>
      <div className=' px-6'>
        <h2 className='mb-0'>
          {formObject.title}
        </h2>
        <div className='text-gray-400 fw-bold fs-6'>{formObject?.description || ''}</div>
      </div>

      <div className='py-lg-4 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          {/* begin::Aside*/}
          <div className='card py-10 px-10 me-10 d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-350px'>
            {/* begin::Nav*/}
            <div className='stepper-nav '>
              {formObject.steps.map((step: any, index: number) => {
                return (
                  <div
                    key={index}
                    className={`stepper-item ${
                      stepper?.currentStepIndex === index + 1 ? 'current' : ''
                    }`}
                    data-kt-stepper-element='nav'
                  >
                    {/* begin::Wrapper*/}
                    <div className='stepper-wrapper'>
                      {/* begin::Icon*/}
                      <div className='stepper-icon w-40px h-40px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>{index + 1}</span>
                      </div>
                      {/* end::Icon*/}

                      {/* begin::Label*/}
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>{step.title}</h3>

                        <div className='stepper-desc'>{step.description}</div>
                      </div>
                      {/* end::Label*/}
                    </div>
                    {/* end::Wrapper*/}

                    {/* begin::Line*/}
                    <div className='stepper-line h-40px'></div>
                    {/* end::Line*/}
                  </div>
                )
              })}
            </div>
            {/* end::Nav*/}
          </div>
          {/* begin::Aside*/}

          {/*begin::Content */}
          <div className='card flex-row-fluid py-10 px-10 card'>
            {formObject.steps.map((step: any, index: number) => {
              return (
                <div
                  key={index}
                  className={`${stepper?.currentStepIndex === index + 1 ? 'current' : ''}`}
                  data-kt-stepper-element='content'
                >
                  <div className='w-100'>
                    {loading ? (
                      <div className='d-flex justify-content-center'>
                        <div className='border-4 h-50px my-20 spinner-border w-50px'></div>
                      </div>
                    ) : (
                      <AppForm
                        key={`app-form-${index}-${loading}`}
                        formObject={step}
                        inputData={data[index] ?? {}}
                        onSubmit={(values: any) => onSubmit(values, step, index)}
                        onCancel={prevStep}
                        loading={loading}
                        detailData={detailData}
                        setDetailData={setDetailData}
                      />
                    )}
                  </div>
                </div>
              )
            })}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </div>
  )
}

export default FormWizard
