import React, {useState} from 'react'
import {Link, useNavigate, useLocation, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import axios from 'axios'
import Swal from 'sweetalert2'
import AddUpdateItemForm from './AddUpdateItemForm'
import {useAuth} from '../../context/Auth'

interface EmailVerificationProps {
  userType: string
  formObject?: any
}
interface LocationState {
  email: string
}

const EmailVerification = ({userType, formObject: inputFormObject}: EmailVerificationProps) => {
  const [loadResend, setLoadResend] = useState(false)
  const {password, emailAddress, saveAuth, setCurrentUser, setCurrentTenant} = useAuth()

  const {userId} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const {email} = (location.state as LocationState) ?? {}

  if (!userId) {
    navigate('/')
    Swal.fire({
      text: 'Invalid user id',
      icon: 'error',
      confirmButtonText: 'Okay',
    })
  }

  const API_URL = import.meta.env.VITE_APP_API_URL
  const VERIFY_EMAIL_URL = `${
    import.meta.env.VITE_APP_API_URL
  }/${userType}/account/${userId}/confirm-account`
  const RESEND_VERIFY_EMAIL_URL = `${API_URL}/${userType}/account/signup-resend`

  const defaultFormObject = {
    addTitle: 'Email Verification',
    description: `Enter the verification code we sent to ${email ?? 'your email address'}`,
    addEndpoint: VERIFY_EMAIL_URL,
    addFields: [
      // {
      //   name: 'emailAddress',
      //   value: email,
      //   type: 'hidden',
      // },
      {
        name: 'confirmationCode',
        label: 'Confirmation Code',
        placeholder: 'Confirmation Code',
        fields: 6,
        validation: {
          required: true,
          min: 6,
          message: 'Minimum 6 characters',
        },
        type: 'code',
      },
    ],
    resendEndpoint: RESEND_VERIFY_EMAIL_URL,
    redirectUrl: `/${userType}/auth/login`,
    loginUrl: `/${userType}/auth/login`,
    onboardingUrl: `/${userType}/onboarding`,
    dashboardUrl: `/${userType}`,
    userStatus: 'AccountCreated',
  }

  const formObject = inputFormObject ?? defaultFormObject
  const LOGIN_URL = `${import.meta.env.VITE_APP_API_URL}/${userType}/account/login`

  const resendConfirmation = async () => {
    setLoadResend(true)
    await axios
      .post(formObject?.resendEndpoint, {emailAddress: email})
      .then(async (res: any) => {
        toast.success(res.data.message)
      })
      .catch((err) => {
        toast.error('Failed to resend confirmation code')
      })
      .finally(() => setLoadResend(false))
  }

  const submitted = async (_: any, __: any, response: any) => {
    Swal.fire({
      text: `${response?.data?.message}`,
      icon: 'success',
      confirmButtonText: 'Okay',
    })
    try {
      // login user after successful verification
      const loginResponse = await axios.post(LOGIN_URL, {emailAddress, password})
      loginUser(emailAddress, loginResponse.data)
    } catch (error) {
      console.error('Failed to login user', error)
      navigate(formObject.redirectUrl)
    }
  }

  const loginUser = (_: any, auth: any) => {
    const authTokens = {
      accessToken: auth.data.accessToken,
      idToken: auth.data.idToken,
      refreshToken: auth.data.refreshToken,
      accessTokenExpiresAt: auth.data.accessTokenExpiresAt,
      idTokenExpiresAt: auth.data.idTokenExpiresAt,
      user: auth.data.user,
      company: auth.data.company,
    }
    saveAuth(authTokens)
    setCurrentUser(auth.data.user)
    setCurrentTenant(auth.data.company)
    localStorage.setItem('userType', auth.data.user.userType)

    // redirect user to onboarding
    if (
      auth.data.user &&
      (auth.data.user.status === formObject?.userStatus ||
        auth.data.user.status === 'OnboardingStarted')
    ) {
      // redirect to onboarding
      navigate(formObject?.onboardingUrl)
    } else {
      // redirect to dashboard
      navigate(formObject?.dashboardUrl)
    }
  }

  return (
    <>
      <div className='d-flex flex-stack py-2'>
        <div className='me-2'>
          <Link to={formObject?.loginUrl} className='btn btn-icon bg-light rounded-circle'>
            <span className='svg-icon svg-icon-2 svg-icon-gray-800'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M9.60001 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13H9.60001V11Z'
                  fill='currentColor'
                />
                <path
                  opacity='0.3'
                  d='M9.6 20V4L2.3 11.3C1.9 11.7 1.9 12.3 2.3 12.7L9.6 20Z'
                  fill='currentColor'
                />
              </svg>
            </span>
          </Link>
        </div>

        <div className='m-0'>
          <span className='fw-bold fs-5 me-2 text-gray-800' data-kt-translate='two-step-head-desc'>
            Didn&apos;t get the code?
          </span>
          <span
            style={{cursor: loadResend ? 'not-allowed' : 'pointer'}}
            className='link-primary fw-bold fs-5'
            data-kt-translate='two-step-head-resend'
            onClick={() => resendConfirmation()}
          >
            {loadResend ? 'Resending...' : 'Resend'}
            {loadResend && (
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            )}
          </span>
        </div>
      </div>

      <div className='py-20 mx-n1'>
        <AddUpdateItemForm formObject={formObject} submitted={submitted} />
      </div>
    </>
  )
}

export default EmailVerification
