import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../../web-app-core'
import DynamicScreen from '../../../../web-app-core/components/DynamicScreen'

const API_URL = import.meta.env.VITE_APP_API_URL

export default function BoardMembers() {
  const navigate = useNavigate()

  const {pathname} = useLocation()
  console.log('pathname', pathname)

  const {auth} = useAuth()
  console.log('currentTenant', auth?.currentTenant?.id)

  const currentTenant = auth?.currentTenant?.id

  const screenData = {
    screen: {
      className: 'card',
      iconName: 'people',
      title: 'Board Members',
      components: [
        {
          type: 'ItemManagementTable',
          data: {
            listEndpoint: `${API_URL}/company/${currentTenant}/user`,
            listTitle: 'Board Members',
            listFields: [
              {
                name: 'user.name',
                header: 'Name',
                type: 'link',
                path: '/board-members',
              },
              {
                name: 'boardRole',
                header: 'Board Role',
              },
              {
                name: 'userRole',
                header: 'User Role',
              },
              {
                name: 'dateCreated',
                header: 'Date Created',
                type: 'date',
              },
            ],
          },
        },
      ],
    },
  }

  return <DynamicScreen data={screenData} />
}
