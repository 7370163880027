import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'
import {DetailHeader} from '../../../web-app-core'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import DocumentList from './tabs/DocumentList'
import Document from './tabs/Document'

const API_URL = import.meta.env.VITE_APP_API_URL

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DocumentPage = () => {
  const {id} = useParams()
  const formObject = {
    endpoint: `${API_URL}/document/${id}`,
    dataFields: [
      {
        name: 'dateCreated',
        label: 'Date Created',
        type: 'datetime',
      },
    ],
    tabs: [
      {
        title: 'Documents',
        path: `/document-folders/${id}`,
      },
    ],
  }
  return (
    <Routes>
      <Route
        element={
          <>
            <DetailHeader formObject={formObject} id={id as string} />
            <Outlet />
          </>
        }
      >
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Uploaded Documents</PageTitle>
              <DocumentList />
            </>
          }
        />

        <Route
          path={`documents/:id`}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Documents</PageTitle>
              <Document />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default DocumentPage
