/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {ErrorMessage, Field} from 'formik'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import {KTIcon} from '../../../_metronic/helpers'
import axios from 'axios'
import Skeleton from 'react-loading-skeleton'
import {CheckboxList} from '../molecules/CheckboxList'
import Lottie from 'lottie-react'
import loadingAnimation from '../../../_lottie/Loading_Animation.json'
import {useAuth} from '../../../app/modules/auth'

interface InputComponentProps {
  field: any
  data: any
  updateData: (fieldsToUpdate: Partial<any>) => void
  hasError: boolean
  key: string
  errors: any
  touched: any
}

const GroupedCheckboxList = ({
  field,
  data,
  updateData,
  hasError,
  errors,
  touched,
}: InputComponentProps) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [items, setItems] = useState<Array<any>>([])
  const [requestCount, setRequestCount] = useState<number>(0)
  const [totalSelectedFeaturePrice, setTotalSelectedFeaturePrice] = useState<number>(0)
  const getList = async () => {
    try {
      setLoading(true)
      const response = await axios.get<any>(`${field.groupEndpoint}`)
      const filteredItems = response?.data?.data.filter(
        (item: any) => !item.templateFeatureCategoryId
      )

      if (!filteredItems?.length && requestCount < 3) {
        // delat by 20 seconds
        await new Promise((resolve) => setTimeout(resolve, 30000))
        await getList()
        setRequestCount((prev) => prev + 1)
      } else {
        setItems(filteredItems)
        // alert('filteredItems: ' + JSON.stringify(filteredItems))
      }
    } catch (error) {
      console.error('GroupedCheckboxList error', error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  // check if optionsEndpoint is available
  useEffect(() => {
    if (field.groupEndpoint) {
      getList()
    } else {
      setItems(field.options)
      setLoading(false)
    }
  }, [])

  // console.log('InputTextWithLabel data', data)
  const [inputValue, setInputValue] = useState(data && data[field.name])
  const onChange = (e: any) => {
    let objectValue = {} as any
    objectValue[field.name as string] = e.target.value
    setInputValue(e.target.value)
    updateData(objectValue)
  }
  console.log('touched field name', touched[field.name])
  // console.log('errors', errors)
  return (
    <div className={`col-${field.cols ? field.cols : '12'} mb-7 mx-1`} key={field.name}>
      {/*begin::Form Group */}
      {loading ? (
        // <Skeleton className='h-50px mb-3' count={3} />
        <div>
          <div className='mw-200px mx-auto'>
            <Lottie size={12} animationData={loadingAnimation} loop={true} />
          </div>
          <div className='fs-5 fw-bolder mb-20 mt-n20 text-center'>
            Preparing your app features. This might take a minute...
          </div>
        </div>
      ) : (
        <div className='fv-row'>
          {/* begin::Label */}
          <label className='d-flex align-items-center fs-6 fw-bold mb-4'>
            <span className='required'>{field.label}</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Select your app category'
            ></i>
          </label>
          {/* end::Label */}
          <div className='me-5'>
            {items.map((option: any) => (
              <CheckboxList
                key={option.id}
                field={{
                  ...option,
                  totalSelectedFeaturePrice,
                  name: field.name ?? option.id,
                  label: option.title,
                  description: option.description,
                  optionsEndpoint: field.optionsEndpoint.replace('{id}', option.id),
                }}
                data={data}
                updateData={(updatedData) => {
                  console.log('grouped-checklist updatedData', updatedData)
                  updateData(updatedData)
                }}
                hasError={hasError}
                errors={errors}
                touched={touched}
              />
            ))}
          </div>
        </div>
      )}
      {/*end::Form Group */}
      {/* <h2>{JSON.stringify(errors)}-{inputValue}-{hasError}</h2> */}
      {errors[field.name] && (
        <div className='fv-plugins-message-container'>
          <div data-field='appname' data-validator='notEmpty' className='fv-help-block fw-bold'>
            {errors[field.name]}
          </div>
        </div>
      )}
    </div>
  )
}

export {GroupedCheckboxList}
