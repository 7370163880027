/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Field} from 'formik'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import TipTap from './TipTap'

interface InputComponentProps {
  field: any
  data: any
  updateData: (fieldsToUpdate: Partial<any>) => void
  hasError: boolean
  key: string
  errors: any
}

const InputRichTextWithLabel = ({
  field,
  data,
  updateData,
  hasError,
  errors,
}: InputComponentProps) => {
  const [inputValue, setInputValue] = useState(data && data[field.name])
  const inputOnChange = (e: any) => {
    let objectValue = {} as any
    objectValue[field.name as string] = e

    console.log('objectValue', objectValue)

    setInputValue(e)
    updateData(objectValue)
  }

  return (
    <div className={`col-${field.cols ? field.cols : '12'} mb-7`} key={field.name}>
      <label
        htmlFor={`input-${field.name}-id`}
        className={`d-flex align-items-center fs-6 fw-bold mb-0 ms-1 ${
          field.validation?.required ? 'required' : ''
        }`}
      >
        <span>{field.label}</span>
        {field.toolTip ? (
          <>
            <ReactTooltip id={`${field.name}-input-tooltip`} place='top'>
              {field.toolTip}
            </ReactTooltip>

            <span
              data-tip
              data-for={`${field.name}-input-tooltip`}
              data-padding='5px 7px'
              // title={a.tooltip}
            >
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Specify your unique app name'
              ></i>
            </span>
          </>
        ) : null}
      </label>
      <Field name={field.name}>
        {({field, form}: any) => (
          <div>
            <TipTap
              value={data && data[field.name]}
              onChange={(e: any) => {
                let objectValue = {} as any
                objectValue[field.name as string] = e
                setInputValue(e)
                updateData(objectValue)
                form.setFieldValue(field.name as string, e)
              }}
            />

            {errors[field.name] && (
              <div className='error error fs-7 fw-semibold ps-1 text-danger'>
                {errors[field.name]}
              </div>
            )}
          </div>
        )}
      </Field>
      {/* {!inputValue && hasError && (
        <div className='fv-plugins-message-container'>
          <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
            {field.label} is required
          </div>
        </div>
      )} */}
    </div>
  )
}

export {InputRichTextWithLabel}
