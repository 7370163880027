import React, {FC, useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import {Tab, Tabs as TabCompoent, TabList, TabPanel} from 'react-tabs'
import RenderComponent from '../RenderComponent'
import axios from 'axios'
import {useRenderComponentContext} from '../../context/RenderComponentContext'
import {API_URL} from '../../../app/core/_requests'

const Tabs: FC<any> = (props: any) => {
  const {items, screen, childType, itemsEndpoint, components, detailsData, style, className} =
    props.component
  const [loading, setLoading] = useState<any>(false)
  //   const [details, setDetails] = useState<any>(detailsData)
  const [itemsData, setItemsData] = useState<any>(items)
  const {detailsData: details} = useRenderComponentContext()

  const getData = async () => {
    let response: any
    setLoading(true)
    const endpoint = itemsEndpoint?.replace('{id}', props.detailsData?.id)
    try {
      response = await axios({
        method: 'get',
        url: API_URL + endpoint,
      })
    } catch (error: any) {
      console.error('Dynamic screen error', error)
      console.log('Dynamic screen error props', props)
      response = error?.response
      alert('Error: ' + endpoint + error?.message + JSON.stringify(props.detailsData))
    } finally {
      setLoading(false)
    }
    if (screen?.detailsDataMapping) {
      let item: any = {}
      Object.keys(screen.detailsDataMapping).forEach((key) => {
        item[key] = response.data.data[screen.detailsDataMapping[key]]
      })
      setItemsData(item)
    } else {
      setItemsData(response?.data?.data)
    }
  }

  useEffect(() => {
    if (itemsEndpoint) {
      getData()
    }
  }, [itemsEndpoint, detailsData])

  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  return (
    <>
      {/* {JSON.stringify(items)} */}
      <TabCompoent className={''}>
        <div className='card card-flush mb-2'>
          <div className='card-body pt-0 pb-0'>
            <TabList className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'>
              {itemsData?.map((item: any, index: number) => (
                <Tab
                  key={index}
                  className={' fs-6 fw-bold nav-item'}
                  onClick={() => setSelectedTabIndex(index)}
                >
                  <a
                    className={`nav-link ${selectedTabIndex === index ? 'active' : ''}`}
                    href='javascript:void(0)'
                  >
                    {item.title}
                  </a>
                </Tab>
              ))}
            </TabList>
          </div>
        </div>

        {itemsData?.map((item: any, index: number) => {
          return (
            <TabPanel key={index} style={style} className={className}>
              {item.components
                ? item.components.map((child: any, index: number) => (
                    <RenderComponent key={index} component={child} data={item} loading={loading} />
                  ))
                : null}
              {/* <RenderComponent key={index} component={components} data={item} loading={loading} /> */}
            </TabPanel>
          )
        })}
      </TabCompoent>
    </>
  )
}

export default Tabs
