import React, { useEffect, useState } from "react";
import axios from "axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { KTSVG } from "../../../_metronic/helpers";

const ItemCard = ({ field, item, loading }: any) => {
  let colorCode = "";
  switch (item?.status) {
    case "OnboardingStarted":
      colorCode = "badge-light-success";
      break;
    case "Active":
      colorCode = "badge-light-success";
      break;
    case "PendingVerification":
      colorCode = "badge-light-primary";
      break;
    default:
      colorCode = "badge-light-danger";
      break;
  }
  return (
    <>
      {/*begin::Content*/}
      <div className="col-md-6 col-xl-4">
        {/*begin::Card*/}
        <Link
          to={loading ? '' : `${field.link}/${item?.id}`}
          className="card border-hover-primary"
        >
          {/*begin::Card header*/}
          <div className="card-header border-0 pt-9">
            {/*begin::Card Title*/}
            <div className="card-title m-0">
              {/*begin::Avatar*/}
              <div className="symbol symbol-50px w-50px">
                {loading ? (
                  <Skeleton width={50} height={50} />
                ) : (
                  <KTSVG
                    path="media/icons/duotune/layouts/lay001.svg"
                    className="svg-icon svg-icon-3x text-primary svg-icon-muted"
                  />
                  // <img
                  //   src={toAbsoluteUrl('/media/logos/company-avatar.png')}
                  //   alt='image'
                  //   className='p-3'
                  // />
                )}
              </div>
              {/*end::Avatar*/}
            </div>
            {/*end::Car Title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
              {loading ? (
                <Skeleton count={1} width={75} height={20} />
              ) : (
                <span
                  className={`badge fw-bold me-auto ${colorCode} px-4 py-3 fs-7`}
                >
                  {item?.status}
                </span>
              )}
            </div>
            {/*end::Card toolbar*/}
          </div>
          {/*end:: Card header*/}
          {/*begin:: Card body*/}
          <div className="card-body p-9">
            {/*begin::Name*/}
            <div className="fs-3 fw-bold text-dark">
              {loading || !item ? (
                <Skeleton count={1} width={"64%"} />
              ) : (
                item[field?.title]
              )}
            </div>
            {/*end::Name*/}
            {/*begin::Description*/}
            <p className="text-gray-400 fw-semibold fs-6 mt-1 min-h-45px mb-2">
              {loading ? (
                <Skeleton count={3.5} />
              ) : (
                item[field.description]?.substring(0, 100)
              )}
            </p>
            {/*end::Description*/}
            {/*begin::Info*/}
            <div className="d-flex flex-wrap">
              {field?.stats?.map((stat: any, index: number) => (
                <div
                  key={index}
                  className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7"
                >
                  <div className="fs-6 text-gray-800 fw-bold">
                    {loading ? <Skeleton count={1} /> : item[stat.value]}
                  </div>
                  <div className="fw-semibold text-gray-400">
                    {loading ? <Skeleton count={1} /> : stat.label}
                  </div>
                </div>
              ))}
            </div>
            {/*end::Info*/}
            {/* <ReactTooltip id={`${product?.id}-progress-tooltip`} place='top' effect='solid'>
              This product is {productStats?.completedPercentage}% completed
            </ReactTooltip>
            <div
              data-tip
              data-for={`${product?.id}-progress-tooltip`}
              className='h-4px w-100 bg-light mt-5'
            >
              <div
                className='bg-primary rounded h-4px'
                role='progressbar'
                style={{width: `${productStats?.completedPercentage}%`}}
              ></div>
            </div> */}
          </div>
          {/*end:: Card body*/}
        </Link>
        {/*end::Card*/}
      </div>

      {/*end::Content*/}
    </>
  );
};

export default ItemCard;
