// Main component to render the screen based on JSON data

import {useEffect, useState} from 'react'
import {DynamicScreenContext} from '../../web-app-core/context/DynamicScreenContext'
import {convertStyle} from '../../web-app-core/helpers/utils/convertStyle'
import RenderComponent from './RenderComponent'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {PageTitle} from '../../_metronic/layout/core'
import AddUpdateItemModal from './organisms/AddUpdateItemModal'
import ButtonWithAddUpdateModal from './organisms/ButtonWithAddUpdateModal'

const DynamicScreen = ({data}: any) => {
  const {screen} = data
  const {style, components, layout, className} = screen

  const screenStyle = {
    display: 'flex',
    flexDirection: 'column',
    ...convertStyle(style),
    ...convertStyle(layout),
  }

  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [detailsData, setDetailsData] = useState<any>()
  const [refreshKey, setRefreshKey] = useState<number>(0)
  const [refreshDataKey, setRefreshDataKey] = useState<any>({})
  const {id: paramsId} = params

  const getData = async () => {
    let response: any
    setLoading(true)
    if (screen?.detailsEndpoint) {
      const endpoint = screen?.detailsEndpoint.replace('{id}', paramsId)
      try {
        response = await axios({
          method: 'get',
          url: endpoint,
        })
      } catch (error: any) {
        console.error('Dynamic screen error', error)
        response = error?.response
        alert('Error: ' + endpoint + error?.message)
      } finally {
        setLoading(false)
      }

      if (screen?.detailsDataMapping) {
        let item: any = {}
        Object.keys(screen.detailsDataMapping).forEach((key) => {
          item[key] = response.data.data[screen.detailsDataMapping[key]]
        })
        setDetailsData(item)
      } else {
        setDetailsData(response?.data?.data)
        console.log('DynamicScreen response', response?.data?.data)
      }
    } else {
      setRefreshKey(new Date().getTime())
    }
  }

  useEffect(() => {
    if (screen?.detailsEndpoint) {
      getData()
    }
  }, [paramsId])

  useEffect(() => {
    if (screen?.detailsEndpoint && refreshDataKey[detailsData?.id] > refreshKey) {
      getData()
    }
  }, [refreshDataKey])
  return (
    <DynamicScreenContext.Provider value={{detailsData, loading, getData, refreshDataKey, setRefreshDataKey, refreshKey, setRefreshKey}}>
      <PageTitle
        actionButton={() => {
          if (!screen.actionButton) {
            return <></>
          }
          return (
            <>
              <ButtonWithAddUpdateModal
                layoutData={screen.actionButton}
                handleSaved={() => getData()}
              />
            </>
          )
        }}
        iconName={screen.iconName}
        breadcrumbs={[
          {title: 'Dashboard', path: '/', isActive: false},
          {title: '', path: '/meetings', isSeparator: true, isActive: false},
          {title: screen?.title, path: '/', isActive: true},
        ]}
      >
        {screen?.title}
      </PageTitle>
      <div key={refreshKey} className={className} style={screenStyle}>
        {components.map((component: any, index: number) => (
          <RenderComponent key={index} component={component} />
        ))}
      </div>
    </DynamicScreenContext.Provider>
  )
}

export default DynamicScreen
