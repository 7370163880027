import {useEffect, useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../../../web-app-core'
import Iframe from 'react-iframe'
import axios from 'axios'

const API_URL = import.meta.env.VITE_APP_API_URL

export default function MeetingDocument() {
  const navigate = useNavigate()

  const [item, setItem] = useState<any>({})
  const {auth} = useAuth()

  const {pathname} = useLocation()
  const {id} = useParams()
  console.log('pathname', pathname)

  const getObject = async () => {
    const response = await axios.get(`${API_URL}/document/${id}`)
    console.log('response', response)
    setItem(response.data.data)
  }

  useEffect(() => {
    // console.log('location', location)
    getObject()
  }, [])

  return (
    item &&
    item.fileUrl &&
    (['application/pdf', 'pdf'].includes(item.fileType)  ? (
      <div className='text-end'>
        <button
          onClick={() => navigate(`/meetings/${item.parentId}/documents`)}
          className='btn btn-sm btn-default me-3 btn-outline mb-2'
        >
          Back to documents
        </button>
        <a href={item?.fileUrl} target='_blank' className='btn btn-sm btn-primary mb-2'>
          Download
        </a>
        <Iframe
          url={`https://dev--willowy-bonbon-dd15d5.netlify.app/?url=${item.fileUrl}&id=${item.id}&createdById=${auth?.user?.id}`}
          width='100%'
          height='640px'
          id=''
          className=''
          display='block'
          position='relative'
        />
      </div>
    ) : (
      <div className='card card-flush py-5'>
        <div className='card-body py-15 py-lg-20'>
          <h1 className='fw-bolder text-gray-900 mb-5'>Annotation not supported</h1>

          <div className='fs-6 mb-8'>
            <span className='fw-semibold text-gray-500'>
              Only PDF annotation is supported. You can download the document to view it.
            </span>
          </div>

          <div className='mb-11'>
            <button
              onClick={() => navigate(-1)}
              className='btn btn-sm btn-default me-3 btn-outline'
            >
              Back to documents
            </button>
            <a href={item?.fileUrl} target='_blank' className='btn btn-sm btn-primary'>
              Download
            </a>
          </div>
        </div>
      </div>
    ))
  )
}
