import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../web-app-core'
import DynamicScreen from '../../../web-app-core/components/DynamicScreen'

const API_URL = import.meta.env.VITE_APP_API_URL

export default function Approvals() {
  const {pathname} = useLocation()
  console.log('pathname', pathname)

  const {auth} = useAuth()
  console.log('currentTenant', auth?.currentTenant?.id)

  const currentTenant = auth?.currentTenant?.id

  const screenData = {
    screen: {
      className: 'card',
      components: [
        {
          type: 'ItemManagementTable',
          data: {
            listEndpoint: `${API_URL}/company/${currentTenant}/approval-request`,
            listTitle: 'Approvals',
            listFields: [
              {
                name: 'title',
                header: 'Title',
                type: 'link',
                path: '/approvals',
              },
              {
                name: 'status',
                header: 'Status',
                type: 'status',
              },
              {
                name: 'dateCreated',
                header: 'Date Created',
                type: 'date',
              },
            ],
            addEndpoint: `${API_URL}/company/${currentTenant}/approval-request`,
            addTitle: 'Request Approval',
            addFields: [
              {
                name: 'title',
                label: 'Title',
                toolTip: '',
                placeholder: '',
                validation: {
                  required: true,
                  type: 'string',
                  minCharacters: 2,
                },
                type: 'text',
              },
              {
                name: 'description',
                label: 'Description',
                toolTip: '',
                placeholder: '',
                validation: {
                  type: 'string',
                },
                type: 'textarea',
              },
            ],
            updateEndpoint: `${API_URL}/approval-request`,
            updateTitle: 'Update Request',
            updateFields: [
              {
                name: 'title',
                label: 'Title',
                toolTip: '',
                placeholder: '',
                validation: {
                  required: true,
                  type: 'string',
                  minCharacters: 2,
                },
                type: 'text',
              },
              {
                name: 'description',
                label: 'Description',
                toolTip: '',
                placeholder: '',
                validation: {
                  type: 'string',
                },
                type: 'textarea',
              },
            ],
          },
        },
      ],
    },
  }

  return <DynamicScreen data={screenData} />
}
