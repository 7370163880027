import {useEffect, useState} from 'react'
import CardList from './organisms/CardList'
import ManageItem from './organisms/ManageItem'
import axios from 'axios'
import {
  RenderComponentContext,
  useRenderComponentContext,
} from '../../web-app-core/context/RenderComponentContext'
import {useAuth} from '../../web-app-core/context/Auth'
import {useDynamicScreenContext} from '../../web-app-core/context/DynamicScreenContext'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {Tab, TabList, TabPanel} from 'react-tabs'
import Tabs from './organisms/Tabs'
import DetailHeader from './organisms/DetailHeader'
import DetailHeaderVertical from './organisms/DetailHeaderVertical'
import FormWizard from './organisms/FormWizard'
import ItemDetailCard from './organisms/ItemDetailCard'
import GroupListCard from './organisms/GroupListCard'
import ItemListWithStatus from './organisms/ItemListWithStatus'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import moment from 'moment'
import AddUpdateItemModal from './organisms/AddUpdateItemModal'

// Recursive component renderer

// Recursive component renderer
const RenderComponentCore = ({
  component = {},
  data: componentData,
  loading: componentLoading,
}: any) => {
  if (!component) {
    return null
  }

  const {getUser, auth} = useAuth()

  const [showModal, setShowModal] = useState(false)

  // Get details data from closest component context
  const {
    detailsData: renderComponentDetailsData,
    loading: renderComponentLoading,
    getData,
  } = useRenderComponentContext()

  // Get details data from screen context
  const {detailsData: dyanmicScreenDetailsData, loading: dynamicScreenLoading, setRefreshKey} =
    useDynamicScreenContext()

  // State to hold the details data
  const [details, setDetails] = useState<any>(
    componentData ?? renderComponentDetailsData ?? dyanmicScreenDetailsData
  )

  // State to hold the details data
  const [loading, setLoading] = useState<any>(
    componentLoading ?? renderComponentLoading ?? dynamicScreenLoading
  )

  const replaceFieldPlaceHolders = (text: string, detailsData: any): string => {
    if (!detailsData && !text?.includes('auth')) return text
    const dataObject = {...detailsData, auth}

    return text.replace(/{(.*?)}/g, (_, key) => {
      const keys = key.split('.')
      let value = dataObject
      for (const k of keys) {
        value = value?.[k]
        if (value === undefined) {
          return `{${key}}`
        }
      }
      return value
    })
  }

  const replaceFieldPlaceHoldersAsPlaceholder = (text: string, detailsData: any): string => {
    if (!detailsData && !text?.includes('auth')) return text
    const dataObject = {...detailsData, auth}

    return text.replace(/{(.*?)}/g, (_, key) => {
      const keys = key.split('.')
      let value = dataObject
      for (const k of keys) {
        value = value?.[k]
        if (value === undefined) {
          return `{${key}}`
        }
      }
      return `{${value}}`
    })
  }

  // get styles by key from global Styles object
  // const getStyles = (keys: string[] = []) => {
  //   let styles: any = {};
  //   keys.forEach((k: string) => {
  //     styles = { ...styles, ...(Styles as any)[k] };
  //   });
  //   return styles;
  // };
  useEffect(() => {
    if (!(componentData ?? renderComponentDetailsData ?? dyanmicScreenDetailsData)) {
      return
    }
    setDetails(componentData ?? renderComponentDetailsData ?? dyanmicScreenDetailsData)
  }, [componentData, renderComponentDetailsData, dyanmicScreenDetailsData])

  useEffect(() => {
    if (!(componentLoading ?? renderComponentLoading ?? dynamicScreenLoading)) {
      return
    }
    setLoading(componentLoading ?? renderComponentLoading ?? dynamicScreenLoading)
  }, [componentLoading, renderComponentLoading, dynamicScreenLoading])

  // replace placeholders in text with actual values
  // inputObject will contain field values and nested objects and arrays with text fields that contain placeholders
  // dataObject will contain the actual data to replace the placeholders
  const replacePlaceholders = (inputObject: any, dataObject: any): any => {
    if (!inputObject) return ''
    if (typeof inputObject === 'string') {
      if (inputObject.includes('{')) {
        console.log(
          'inputObject,dataObject',
          inputObject,
          dataObject,
          replaceFieldPlaceHolders(inputObject, dataObject)
        )
      }
      console.log('inputObject', inputObject)
      return replaceFieldPlaceHolders(inputObject, dataObject)
    }
    if (Array.isArray(inputObject)) {
      return inputObject.map((item) => replacePlaceholders(item, dataObject))
    }
    if (typeof inputObject === 'object') {
      const outputObject: any = {}
      Object.keys(inputObject).forEach((key) => {
        outputObject[key] = replacePlaceholders(inputObject[key], dataObject)
      })
      return outputObject
    }
    return inputObject
  }

  const navigate = useNavigate()

  const {
    type,
    style,
    className,
    components,
    content,
    data,
    items,
    text,
    action,
    completeAction,
    name,
    size,
    color,
    sourceUri,
    itemComponent,
  } = component
  const reactStyle = style

  // const className = className
  switch (type) {
    case 'view':
      return (
        <div
          key={details?.id}
          className={
            component.colorMapping
              ? replaceFieldPlaceHolders(
                  replaceFieldPlaceHoldersAsPlaceholder(className, details),
                  component.colorMapping
                )
              : className
          }
          style={{display: 'flex', flexDirection: 'column', ...reactStyle}}
        >
          {components &&
            components.map((child: any, index: number) => (
              <RenderComponent key={index} component={child} data={details} loading={loading} />
            ))}
        </div>
      )
    case 'div':
      return (
        <div
          className={
            component.colorMapping
              ? replaceFieldPlaceHolders(
                  replaceFieldPlaceHoldersAsPlaceholder(className, details),
                  component.colorMapping
                )
              : className
          }
          style={{...reactStyle}}
        >
          {components &&
            components.map((child: any, index: number) => (
              <RenderComponent key={index} component={child} data={details} loading={loading} />
            ))}
        </div>
      )
    case 'link':
      return (
        <>
          <Link
            onClick={() => {
              if (component.action && component.action?.handler === 'AddUpdateModal') {
                setShowModal(true)
              } else {
                if (component.path) {
                  navigate(component.path)
                }
              }
            }}
            to={component.path ? replaceFieldPlaceHolders(component.path, details) : ''}
            className={className}
          >
            {text ? replaceFieldPlaceHolders(text, details) : 'View'}
          </Link>
          {component.action?.layoutData && details && (
            <AddUpdateItemModal
              inputData={details}
              formObject={component.action?.layoutData}
              show={showModal}
              handleClose={() => {
                setShowModal(false)
              }}
              handleSaved={() => {
                setShowModal(false)
                setRefreshKey(new Date().getTime())
              }}
            />
          )}
        </>
      )
    // display list of items with add, edit, delete functionality
    case 'ItemManagementTable':
      return (
        <div key={details?.id} className={className}>
          <ManageItem key={details?.id} formObject={replacePlaceholders(data, details)} />
        </div>
      )
    case 'ItemDetailHeader':
      // const test = replacePlaceholders(data, details)
      // console.log('ItemManagementTable test', test)
      // const listEndpoint = data.listEndpoint.replace('{id}', details?.id)

      return (
        <div key={details?.id} className={className}>
          <DetailHeader
            id='test'
            key={details?.id}
            formObject={replacePlaceholders(data, details)}
          />
        </div>
      )
    case 'ItemDetailVertical':
      // const test = replacePlaceholders(data, details)
      // console.log('ItemManagementTable test', test)
      // const listEndpoint = data.listEndpoint.replace('{id}', details?.id)

      return (
        <div key={details?.id} className={className}>
          {details?.id && (
          <DetailHeaderVertical key={details?.id} formObject={replacePlaceholders(data, details)} />
          )}
        </div>
      )
    case 'GroupListCard':
      return (
        <div key={details?.id} className={className}>
          <GroupListCard key={details?.id} formObject={replacePlaceholders(data, details)} />
        </div>
      )
    case 'ItemListWithStatus':
      return (
        <div key={details?.id} className={className}>
          <ItemListWithStatus
            key={details?.id}
            data={details}
            formObject={replacePlaceholders(data, details)}
            renderItem={(data: any) => {
              return (
                <RenderComponent
                key={data.id}
                component={component.renderItem}
                data={data}
                loading={loading}
              />
              )
            }}
          />
        </div>
      )
    case 'Calendar':
      console.log('Calendar details', details)
      return (
        <div key={details?.id} className={className}>
          <FullCalendar
            events={details?.map((item: any) => {
              return {
                id: item.id,
                title: item.title,
                date: item.startTime,
              }
            })}
            plugins={[dayGridPlugin]}
            eventClick={(info) => {
              console.log('info title', info.event.title)
              navigate(`${data.detailPath}/${info.event.id}`)
            }}
            initialView='dayGridMonth'
          />
        </div>
      )
    case 'FormWizard':
      // const test = replacePlaceholders(data, details)
      // console.log('ItemManagementTable test', test)
      // const listEndpoint = data.listEndpoint.replace('{id}', details?.id)

      return (
        <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
          <div className='h-100 w-100 d-flex justify-content-center  pt-20'>
            <FormWizard
              formObject={data}
              onComplete={async () => {
                await getUser()
              }}
            />
          </div>
        </div>
      )
    case 'ItemDetailCard':
      // const test = replacePlaceholders(data, details)
      // console.log('ItemManagementTable test', test)
      // const listEndpoint = data.listEndpoint.replace('{id}', details?.id)

      return (
        <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
          <div className='h-100 w-100 d-flex justify-content-center  pt-20'>
            <ItemDetailCard formObject={data} />
          </div>
        </div>
      )

    // display list of items with add, edit, delete functionality and drag and drop functionality
    case 'ItemManagementWithDragDropTable':
      return <ManageItem formObject={data} />

    // display list of items in card format
    case 'CardList':
      return <CardList formObject={data} />

    case 'Tabs':
      return (
        <div className='w-100'>
          {/* <h3>Tata -{JSON.stringify(details)}</h3> */}
          <Tabs
            items={items}
            itemsEndpoint={component.itemsEndpoint}
            detailsData={details}
            component={component}
          />
        </div>
      )
    case 'text':
      return (
        <span className={className} style={reactStyle}>
          {/* <h2>ds{JSON.stringify(renderComponentDetailsData)}</h2> */}
          {replaceFieldPlaceHolders(text, details)}
        </span>
      )
    case 'date':
      return (
        <span className={className} style={reactStyle}>
          {/* <h2>ds{JSON.stringify(renderComponentDetailsData)}</h2> */}
          {/* {replaceFieldPlaceHolders(text, details)} */}
          {moment(new Date(replaceFieldPlaceHolders(text, details))).format(
            component.format ?? 'MMM DD, YYYY'
          )}
        </span>
      )
    case 'button':
      return (
        <button style={reactStyle} onClick={() => eval(action)}>
          {text}
        </button>
      )
    default:
      return null
  }
}

// export default RenderComponent
const RenderComponent = (props: any) => {
  const {component, detailsEndpoint, detailsDataMapping, data} = props
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [detailsData, setDetailsData] = useState<any>(data)
  const [refreshKey, setRefreshKey] = useState<number>(0)
  const {id: paramsId} = params
  const {refreshDataKey, setRefreshDataKey} = useDynamicScreenContext()

  // useEffect(() => {
  //   if (data) {
  //     // Alert.alert("Dynamic screen data 0", JSON.stringify(data));
  //     setDetailsData(data);
  //   }
  // }, [data]);
  const getData = async () => {
    let response: any
    setLoading(true)
    const endpoint = detailsEndpoint.replace('{id}', paramsId)
    try {
      response = await axios({
        method: 'get',
        url: endpoint,
      })
    } catch (error: any) {
      console.error('Dynamic screen error', error)
      response = error?.response
      alert('Error: ' + endpoint + ' : ' + error.message)
    } finally {
      setLoading(false)
    }
    if (detailsDataMapping) {
      let item: any = {}
      Object.keys(detailsDataMapping).forEach((key) => {
        item[key] = response.data.data[detailsDataMapping[key]]
      })
      setDetailsData({...response.data.data, ...item})
    } else {
      setDetailsData(response?.data?.data)
    }
  }

  useEffect(() => {
    if (detailsEndpoint) {
      getData()
    } else {
      if (data) {
        setDetailsData(data)
      }
    }
  }, [data])

  useEffect(() => {
    console.log('refreshDataKey detailsEndpoint', detailsEndpoint)
    // console.log('refreshDataKey (refreshDataKey[data.id] > refreshKey), detailsEndpoint, data', (refreshDataKey && refreshDataKey[data?.id] > refreshKey), detailsEndpoint, data)
    if (data && detailsEndpoint && refreshDataKey && (refreshDataKey[data.id] > refreshKey)) {
      console.log('refreshDataKey true', refreshDataKey)

      getData()
      setRefreshKey(new Date().getTime())
    }
  }, [refreshDataKey])

  const refreshData = (id: string) => {
    if (data.id === id) {
      getData()
    }
  }

  return (
    <RenderComponentContext.Provider value={{detailsData, loading, getData}}>
      <RenderComponentCore {...props} />
    </RenderComponentContext.Provider>
  )
}

export default RenderComponent
