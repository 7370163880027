import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'
import {DetailHeader} from '../../../web-app-core'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Trainings from './Evaluations'
import EvaluationQuestions from './EvaluationQuestions'
import AppFormWizard from '../../../web-app-core/components/organisms/AppFormWizard'
import EvaluationResponse from './EvaluationResponse'
import EvaluationUsers from './EvaluationUsers'
import EvaluationRespondents from './EvaluationRespondents'
import {useEffect, useState} from 'react'
import axios from 'axios'

const API_URL = import.meta.env.VITE_APP_API_URL

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const EvaluationResponsePage = () => {
  const {id, evaluationUserId} = useParams()
  const params = useParams()
  const BASE_URL = `${import.meta.env.VITE_APP_API_URL}`

  console.log('EvaluationResponsePage params', params, params.evaluationUserId)

  // make API call to get evaluation details
  const [evaluation, setEvaluation] = useState<any>(null)
  useEffect(() => {
    const getEvaluation = async () => {
      const response = await axios.get(`${BASE_URL}/respondent-user/${id}/evaluation-user`)
      const data = response.data
      setEvaluation(data.data[0])
      console.log('getEvaluation data', data)
    }
    getEvaluation()
  }, [])

  const formObject = {
    endpoint: `${API_URL}/respondent-user-evaluation-user/${encodeURIComponent(
      params.evaluationUserId as string
    )}`,
    dataFields: [
      {
        name: 'firstName',
        label: 'First Name',
      },
      {
        name: 'lastName',
        label: 'Last Name',
      },
      {
        name: 'dateUpdated',
        label: 'Date Updated',
        type: 'date',
      },
    ],
    tabs: [],
  }
  return (
    evaluation && (
      <Routes>
        <Route
          element={
            <>
              <DetailHeader formObject={formObject} id={id as string} />
              <Outlet />
            </>
          }
        >
          <Route
            index
            element={
              <>
                <PageTitle breadcrumbs={profileBreadCrumbs}>Evaluation Response</PageTitle>
                <EvaluationResponse />
              </>
            }
          />

          {/* <Route
          path='questions'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Evaluation Questions</PageTitle>
              <EvaluationQuestions />
            </>
          }
        /> */}

          <Route
            path={`users`}
            element={
              <>
                <PageTitle breadcrumbs={profileBreadCrumbs}>Agenda</PageTitle>
                <EvaluationUsers />
              </>
            }
          />

          <Route
            path={`respondents`}
            element={
              <>
                <PageTitle breadcrumbs={profileBreadCrumbs}>Agenda</PageTitle>
                <EvaluationRespondents />
              </>
            }
          />

          {/* 
        <Route element={<Navigate to='/crafted/pages/profile/overview' />} /> */}
        </Route>
      </Routes>
    )
  )
}

export default EvaluationResponsePage
