
// Helper function to convert JSON styles to React style object
export const convertStyle = (style: any) => {
  if (!style) return {}
  const convertedStyle = {...style}
  Object.keys(convertedStyle).forEach((key) => {
    // Convert CSS properties like paddingVertical to React style properties (paddingTop, paddingBottom)
    if (key.includes('Vertical')) {
      const base = key.replace('Vertical', '')
      convertedStyle[`${base}Top`] = convertedStyle[key]
      convertedStyle[`${base}Bottom`] = convertedStyle[key]
      delete convertedStyle[key]
    } else if (key.includes('Horizontal')) {
      const base = key.replace('Horizontal', '')
      convertedStyle[`${base}Left`] = convertedStyle[key]
      convertedStyle[`${base}Right`] = convertedStyle[key]
      delete convertedStyle[key]
    }
  })
  return convertedStyle
}
