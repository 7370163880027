import {useEffect} from 'react'
import React from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {ManageItem, TableList} from '../../../../web-app-core'
import {ChatInner} from '../../../../_metronic/partials'

const API_URL = import.meta.env.VITE_APP_API_URL

export default function MeetingMessaging() {
  const navigate = useNavigate()

  const {pathname} = useLocation()
  const {id} = useParams()
  console.log('pathname', pathname)
  console.log('useParams id', id)
  const companyId = 'a5cf3b89-7524-4dc1-96d0-be6722cff25e'

  const formObject = {
    listEndpoint: `${API_URL}/meeting/${id}/agenda-item`,
    listTitle: 'Meeting Agenda',
    listFields: [
      {
        name: 'title',
        header: 'Title',
        type: 'link',
        path: '/meetings',
      },
      {
        name: 'description',
        header: 'Description',
      },
      {
        name: 'status',
        header: 'Status',
        type: 'tag',
      },
    ],
    addEndpoint: `${API_URL}/meeting/${id}/agenda-item`,
    addTitle: 'Add Agenda Item',
    addFields: [
      {
        name: 'title',
        label: 'Title',
        toolTip: '',
        placeholder: '',
        validation: {
          minCharacters: 2,
        },
        type: 'text',
      },
      {
        name: 'description',
        label: 'Description',
        toolTip: '',
        placeholder: '',
        validation: {
          minCharacters: 2,
        },
        type: 'text',
      },
    ],
    updateEndpoint: `${API_URL}/meeting-agenda-item`,
    updateTitle: 'Edit Company',
    updateFields: [
      {
        name: 'title',
        label: 'Title',
        toolTip: '',
        placeholder: '',
        validation: {
          minCharacters: 2,
        },
        type: 'text',
      },
      {
        name: 'description',
        label: 'Description',
        toolTip: '',
        placeholder: '',
        validation: {
          minCharacters: 2,
        },
        type: 'text',
      },
    ],
  }

  return <div className='card'>{id && <ChatInner channelId={id as string} />}</div>
}
