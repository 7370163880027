import {ManageItem, useAuth} from '../../../web-app-core'
import {useParams} from 'react-router-dom'

const BASE_URL = `${import.meta.env.VITE_APP_API_URL}`

const RespondentUserEvaluationUsers = () => {
  const {currentUser} = useAuth()
  const companyId = currentUser?.companyId

  const {id} = useParams()

  const formObject = {
    listEndpoint: `${BASE_URL}/respondent-user/${id}/evaluation-user`,
    listTitle: 'Members to Evaluate',

    listFields: [
      {
        name: 'user.firstName',
        header: 'First Name',
        type: 'link',
        path: 'response',
      },
      {
        name: 'user.lastName',
        header: 'Last Name',
      },
      {
        name: 'status',
        header: 'Status',
        type: 'status',
      },
    ],
  }

  return (
    <div className='card card-flush my-5'>
      <ManageItem formObject={formObject} />
    </div>
  )
}

export default RespondentUserEvaluationUsers
